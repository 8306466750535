import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { clientReviewKycLivenessReq } from '../../../../../api/client/kycliveness';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationReviewKycLiveness = ({
  reviewKycLivenessOpen,
  setReviewKycLivenessOpen,
  userId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const reviewKycLivenessUrlHandler = async (id, data) => {
    setLoading(true);
    let status = await clientReviewKycLivenessReq(id, false);
    setLoading(false);
    console.log(status);
    if (status === 'success') {
      setReviewKycLivenessOpen(false);
      refreshData();
    }
  };

  const AskKycLivenessURLInitialValues = {};
  const AskKycLivenessURLSchema = Yup.object().shape({});

  return (
    <Modal isOpen={reviewKycLivenessOpen} toggle={setReviewKycLivenessOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={AskKycLivenessURLInitialValues}
        validationSchema={AskKycLivenessURLSchema}
        onSubmit={(values) => {
          console.log('Demander la Review auprès de Treezor');
          reviewKycLivenessUrlHandler(userId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setReviewKycLivenessOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Confirmez-vous que vous voulez demander la Review de ce client ?
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button
                className="cancel" btnType="outline"
                onClick={() => setReviewKycLivenessOpen(false)}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationReviewKycLiveness;
