import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { createTransferReq } from '../../../../api/transfers/transfers';
import { clientWalletListReq } from '../../../../api/client/wallet';
import IconLoading from '../../../../components/loading/loading.jsx';
import Dropdown from '../../../../components/dropdown';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ConfirmationCreateTransfer = ({
  createTransferOpen,
  setCreateTransferOpen,
  userId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];

  useEffect(() => {
    //for wallet dropdown
    if (createTransferOpen) {
      clientWalletListReq(userId).then((res) => {
        /*Render and set data of the list*/
        res.map((item) =>
          walletOptions.push({ label: item.walletId + ' - ' + item.name, value: item.walletId }),
        );
        setWalletListDrp(walletOptions);
      });
    }
  }, [createTransferOpen]);

  const createTransferHandler = async (userId, data) => {
    setLoading(true);
    data['user_id'] = userId;
    data['walletId'] = data.walletId?.value;
    data['beneficiaryWalletId'] = data.beneficiaryWalletId?.value;
    let status = await createTransferReq(data);
    setLoading(false);
    if (status === 'success') {
      setCreateTransferOpen(false);
      refreshData();
    }
  };

  const CreateTransferInitialValues = {};
  const CreateTransferSchema = Yup.object().shape({
    walletId: Yup.object().required('Requis'),
    beneficiaryWalletId: Yup.object().required('Requis'),
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={createTransferOpen} toggle={setCreateTransferOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={CreateTransferInitialValues}
        validationSchema={CreateTransferSchema}
        onSubmit={(values) => {
          createTransferHandler(userId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setCreateTransferOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Saisir les informations du transfert entre wallet
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="walletId"
                label="Wallet source"
                placeholder="Wallet source *"
                options={walletListDrp}
                component={Dropdown}
              />
              <Field
                name="beneficiaryWalletId"
                label="Wallet bénéficiaire"
                placeholder="Wallet bénéficiaire *"
                options={walletListDrp}
                component={Dropdown}
              />
              <Field name="label" label="Motif" placeholder="Motif *" component={InputFormik} />
              <Field
                name="amount"
                label="Montant du transfert (€)"
                placeholder="Montant du transfert en €"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setCreateTransferOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationCreateTransfer;
