import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
// Requests
import { detailClientReq } from '../../../../api/client/detailClient.jsx';
import { isicCardListReq, isicCardDetailReq } from '../../../../api/client/isic/isiccards';

// UI
import Head from '../../../../components/head';
import ClientDetailV2Header from '../detail/client_header.jsx';
import ClientDetailFilterTabs from '../detail/client_filter_tabs.jsx';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import BlocWithTitle from '../../../../components/blocWithTitle/index.jsx';
import IsicCard from '../../../../components/isic/isic_card';
import Logo from '../../../../components/logo';

import { ReactComponent as IconDelete } from '../../../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconRefresh } from '../../../../assets/icons/component/ic_refresh.svg';
import IconLoading from '../../../../components/loading/loading.jsx';

// Modals
import ConfirmationImportIsicCard from './modals/confirmationImportIsicCard';
import ConfirmationDeleteIsicCard from './modals/confirmationDeleteIsicCard';
import ConfirmationUploadIsicCardFiles from './modals/confirmationUploadIsicCardFiles';

// Renderer
import { tableIsicCardsHeader, renderIsicCardsTable } from './isic_cards.utils';

const ClientDetailV2IsicCards = () => {
  const { userId } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isicCardTableData, setIsicCardTableData] = useState([]);
  const [allIsicCards, setAllIsicCards] = useState([]);
  const [allIsicCardStatus, setAllIsicCardStatus] = useState('loading');
  const [selectedIsicCard, setSelectedIsicCard] = useState();

  const [refreshIsicCardLoading, setRefreshIsicCardLoading] = useState(false);

  const [importIsicCardOpen, setImportIsicCardOpen] = useState(false);
  const [deleteIsicCardOpen, setDeleteIsicCardOpen] = useState(false);
  const [uploadIsicCardFilesOpen, setUploadIsicCardFilesOpen] = useState(false);

  const [selectedID, setSelectedID] = useState();
  const [selectedTypeItem, setSelectedTypeItem] = useState();

  const refreshIsicCardList = () => {
    setAllIsicCardStatus('loading');
    isicCardListReq(userId)
      .then((res) => {
        setAllIsicCards(res);
        setIsicCardTableData(renderIsicCardsTable(res));
      })
      .finally(() => {
        setSelectedID(null);
        setSelectedIsicCard(null);
        setAllIsicCardStatus('done');
      });
  };

  useEffect(() => {
    setLoading(true);
    detailClientReq(userId).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    refreshIsicCardList();
  }, []); // manque 'refreshIsicCardList'

  useEffect(() => {
    const isicCard = allIsicCards.find((x) => x.cardid === selectedID);
    setSelectedIsicCard(isicCard);
    setSelectedTypeItem('isic_card');
  }, [selectedID]); // manque 'allIsicCard'

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${userId}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Liste Cartes ISIC
        </div>
      </Fragment>
    );
  }

  const refreshIsicCardDetailInfos = async (isicCardId) => {
    setRefreshIsicCardLoading(true);
    isicCardDetailReq(userId, isicCardId)
      .then((res) => {
        refreshIsicCardList();
      })
      .finally(() => {
        setRefreshIsicCardLoading(false);
      });
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>      
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-information-grid">
        <div className="o-content">
          <div className="o-content__filters">
            <ClientDetailFilterTabs clientId={userId} />
          </div>
          <div className="o-content__detailclient">
            <div className="">
              <Button btnType="outline" onClick={() => setImportIsicCardOpen(true)}>
                <IconEdit/>
                Import ISIC Number
              </Button>
            </div>
            <div className="o-content__detailclient__row">
              <div className="o-content__detailclient__row__column col-9">
              <Table
                columns={tableIsicCardsHeader}
                tableData={isicCardTableData}
                status={allIsicCardStatus}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                contain
              />
              </div>
              <div className="o-content__detailclient__row__column col-3">
              <BlocWithTitle>
              {selectedID && selectedTypeItem === 'isic_card' && selectedIsicCard && (
                <div>
                  <IsicCard userId={userId} isicCard={selectedIsicCard} />
                  <div>
                    <div className="mt-3">
                      <b>Informations Cartes</b>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div className="mt-2">
                        <div>
                          <div>
                            <b>Card ID / ISIC Number</b>
                          </div>
                          <div>
                            {selectedIsicCard?.cardid} /{' '}
                            {selectedIsicCard?.isic_card_values?.isic_number}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Statut</b>
                          </div>
                          <div>{selectedIsicCard?.status}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>&nbsp;</b>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                      <div className=" mt-2 ml-4">
                        <div>
                          <div>
                            <b>Etablissement</b>
                          </div>
                          <div>
                            {selectedIsicCard.school
                              ? selectedIsicCard.school.name
                              : selectedIsicCard.isic_user_values?.etablissement}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Expiration date</b>
                          </div>
                          <div>{selectedIsicCard?.isic_card_values?.isic_stop_validity}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>&nbsp;</b>
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <b>Actions</b>
                      <hr />
                      <Button
                        btnType="outline"
                        onClick={() => refreshIsicCardDetailInfos(selectedIsicCard?.cardid)}
                      >
                        {refreshIsicCardLoading ? (
                          <IconLoading />
                        ) : (
                          <IconRefresh/>
                        )}
                        Rafraichir la carte
                      </Button>
                      <div>
                        <b>&nbsp;</b>
                      </div>
                      <Button btnType="outline" onClick={() => setDeleteIsicCardOpen(true)}>
                        <IconDelete/>Supprimer la carte
                      </Button>
                      <div>
                        <b>&nbsp;</b>
                      </div>
                      {selectedIsicCard?.status === 'PENDING' ||
                      selectedIsicCard?.status === 'TO_ORDER' ? (
                        <Button btnType="outline" onClick={() => setUploadIsicCardFilesOpen(true)}>
                          <IconEdit/>
                          Upload files
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!selectedTypeItem && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationImportIsicCard
        importIsicCardOpen={importIsicCardOpen}
        setImportIsicCardOpen={setImportIsicCardOpen}
        userId={userId}
        refreshData={refreshIsicCardList}
      />
      {selectedIsicCard?.cardid ? (
        <ConfirmationDeleteIsicCard
          deleteIsicCardOpen={deleteIsicCardOpen}
          setDeleteIsicCardOpen={setDeleteIsicCardOpen}
          userId={userId}
          isicCard={selectedIsicCard}
          refreshData={refreshIsicCardList}
        />
      ) : (
        ''
      )}
      {selectedIsicCard?.cardid ? (
        <ConfirmationUploadIsicCardFiles
          uploadIsicCardFilesOpen={uploadIsicCardFilesOpen}
          setUploadIsicCardFilesOpen={setUploadIsicCardFilesOpen}
          userId={userId}
          isicCard={selectedIsicCard}
          refreshData={refreshIsicCardList}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default ClientDetailV2IsicCards;
