import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { uploadUserDocumentReq } from '../../../../api/client/submitKYC';
import { documentTypesToSubmitReq } from '../../../../api/client/getDocumentTypes';
import IconLoading from '../../../../components/loading/loading.jsx';
import { ReactComponent as IconDelete } from '../../../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconCheck } from '../../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../../components/dropdown';

const ConfirmationSubmitKYCDocs = ({ submitKycOpen, setSubmitKycOpen, userId, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseText, setResponseText] = useState('');
  const [selectedRecto, setSelectedRecto] = useState(null);
  const [selectedVerso, setSelectedVerso] = useState(null);
  const { t } = useTranslation();
  const submitKYCHandler = async () => {
    setLoadingPost(true);
    const res = await uploadUserDocumentReq(
      userId,
      selectedDocumentType?.documentTypeId,
      selectedRecto,
      selectedVerso,
    );
    setResponseText(res);

    setLoadingPost(false);
    close();
  };

  const ShowResponseText = () => (
    <div style={{ textAlign: 'center', margin: '20px auto 30px auto' }}>
      {responseText} <br />
      {responseText === 'success' ? (
        <IconCheck/>
      ) : (
        <IconClose/>
      )}
    </div>
  );

  const close = () => {
    setSubmitKycOpen(false);
    refreshData();
  };

  const getDocumentTypesHandler = async () => {
    setLoading(true);
    const types = await documentTypesToSubmitReq();
    console.log(types);
    if (types) {
      let optionTypes = types.map((t) => ({
        label: t.name,
        value: t.name,
        documentTypeId: t.documentTypeId,
      }));
      console.log(types);
      setDocumentTypes(optionTypes);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDocumentTypesHandler();
  }, []);

  const displaySelectedRecto = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <IconDelete
          style={{ marginLeft: '6px' }}
          onClick={() => setSelectedRecto(null)}
        />
      </div>
    );
  };

  const displaySelectedVerso = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <IconDelete
          style={{ marginLeft: '6px' }}
          onClick={() => setSelectedVerso(null)}
        />
      </div>
    );
  };

  return (
    <Modal isOpen={submitKycOpen} toggle={setSubmitKycOpen}>
      <div className="o-modal-info">
        <IconClose className='icon-cancel' onClick={close}/>
        <div className="o-modal-info__title-text o-title">Soumettre un nouveau document KYC</div>
        <Formik
          initialValues={documentTypes.length ? documentTypes[0] : {}}
          enableReinitialize={true}
        >
          {() => (
            <Form>
              <div className="o-modal-info__fields">
                <Field
                  name="documenttype"
                  as="select"
                  component={Dropdown}
                  options={documentTypes}
                  label="Type de document"
                  style={{ zIndex: 1 }}
                  onChange={(e) => {
                    setSelectedDocumentType(e);
                  }}
                />
                <div>
                  <a style={{ color: '#00DB9B', textDecoration: 'underlined' }}>
                    {selectedRecto?.name ? displaySelectedRecto(selectedRecto.name) : '+ Add Recto'}
                  </a>
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => setSelectedRecto(e.target.files[0])}
                  />
                </div>
                <div>
                  <a style={{ color: '#00DB9B', textDecoration: 'underlined' }}>
                    {selectedVerso?.name ? displaySelectedVerso(selectedVerso.name) : '+ Add Verso'}
                  </a>
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => setSelectedVerso(e.target.files[0])}
                  />
                </div>

                {loadingPost ? (
                  <IconLoading size={40}/>
                ) : (
                  <ShowResponseText />
                )}
              </div>
              <div className="o-btn-group">
                <Button
                  onClick={submitKYCHandler}
                  disabled={selectedRecto ? false : true}
                  type="submit"
                >
                  {loading ? (
                    <div className="d-flex mx-auto">
                      <IconLoading />
                    </div>
                  ) : (
                    'Ajouter'
                  )}
                </Button>
                <Button className="cancel" btnType="outline" onClick={close}>
                  {t('global:cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ConfirmationSubmitKYCDocs;
