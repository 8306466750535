import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from '@mui/material';
// Requests
import {
  detailClientReq,
  clientNotesReq,
} from '../../../../../api/client/detailClient.jsx';
import { searchPayinAlertPageReq } from '../../../../../api/monitoring/monitoring.jsx';

// UI
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import PreviewNoteItem from '../preview/preview_note_item.jsx';
import InfoItem from '../../../../../components/infoItem/index.jsx';
import Head from '../../../../../components/head/index.jsx';
import TablePage from '../../../../../components/tablepage/index.jsx';
import Button from '../../../../../components/button/index.jsx';

// import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconInfo } from '../../../../../assets/icons/component/ic_info.svg';
import { ReactComponent as IconComment } from '../../../../../assets/icons/component/ic_comment.svg';
import TooltipComponent from '../../../../../components/tooltip/tooltip.jsx';

// Modals
import ConfirmationUpdateTrustLevel from '../../modals/confirmationUpdateTrustLevel.jsx';
import ConfirmationAddNote from '../../modals/confirmationAddNote.jsx';
import ConfirmationUpdateAlertStatus from '../../../supervision/modals/confirmationUpdateAlertStatus.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

function ClientDetailV2Monitoring() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);

  const [userNotes, setUserNotes] = useState([]);
  
  const [allAlerts, setAllAlerts] = useState([]);
  const [dataStatus, setDataStatus] = useState('loading');
  const [tableData, setTableData] = useState([]);
  const [pageMetaData, setPageMetaData] = useState({});
  const [tablePageMetaData, setTablePageMetaData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  // Modals
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [updateTrustLevelOpen, setUpdateTrustLevelOpen] = useState(false);
  const [updateAlertStatusOpen, setUpdateAlertStatusOpen] = useState(false);
  
  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

    searchAlertListPage( 0, 10);
  }, []);

  useEffect(() => {
    const alert = allAlerts.find((x) => x.alertfraudid === selectedID);
    setSelectedItem(alert);
    if(selectedID && alert) {
      setUpdateAlertStatusOpen(true);
    }
  }, [selectedID]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const columnsAlerts = [
    'ID',
    'Date',
    'Descriptif',
    'Statut',
    'Montant',
    'Commentaires',
  ];
  
  const renderTableAlerts = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item.alertfraudid,
        alertfraudid: item.alertfraudid,
        payindate : moment(item.payin.payinDate).format('DD/MM/YYYY HH:mm'),
        description: item.description,
        status: item.status ? item.status : '',
        amount: item.payin.amount + " €",
        comments: (item?.comment) ? (
          <TooltipComponent title={<div className='o-tooltip'>
            {item?.comment?.split(' # ').map((com) => (
            <p>{com}</p>
          ))}</div>}><IconComment/></TooltipComponent>
        ) : (''),
        
      }),
    );
    return data;
  };

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    clientNotesReq(id).then((res) => setUserNotes(res));

    // var data = {
    //   user_id: id,
    // };
    // searchPayinAlertPageReq(data, null, 10)
    //   .then((res) => setUserAlerts(res.content))
    //   .finally(() => setLoading(false));
  };
  
  const searchAlertListPage = (pageNo, pageSize) => {
    setLoading(true);
    setDataStatus('loading');
    var data = {
      user_id: id,
    };
    pageNo = pageNo != null ? pageNo : pageMetaData.pageNumber;
    pageSize = pageSize != null ? pageSize : pageMetaData.pageSize;
    searchPayinAlertPageReq(data, pageNo, pageSize)
      .then((res) => {
        setAllAlerts(res.content);
        setTableData(renderTableAlerts(res.content));
        setPageMetaData(res.pageable);
        setTablePageMetaData({ count: res.totalElements });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  const refreshAlertListPage = (pageNo, pageSize) => {
    setSelectedItem(null);
    searchAlertListPage(pageNo, pageSize);
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-8">
              <div className="o-content__detailclient__title">
                <b>Surveillance</b>
              </div>
              <div className="o-content__detailclient__row">
                <InfoItem className="trustlevel" title='Niveau de confiance' value={detailData?.trust_level?.trust_level_id + ' - ' + detailData?.trust_level?.description + ' (Alerte > ' + detailData?.trust_level?.alert_payin_limit + ' €)'} align="right"/>
                <Button btnType="outline" onClick={() => setUpdateTrustLevelOpen(true)}>
                    <IconEdit/>Modifier
                </Button>
              </div>
              <div className="o-content__detailclient__title">
                <b>Alertes</b>
              </div>
              <TablePage
                columns={columnsAlerts}
                tableData={tableData}
                pageMetaData={tablePageMetaData}
                loadTableData={refreshAlertListPage}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                contain
              />
            </div>
            <div className="o-content__detailclient__row__column col-4">
              <div className="o-content__detailclient__title">
                <b>Commentaires</b>
                <TooltipComponent placement="bottom-end" title={
                              <div className='o-tooltip'>
                              <p>S’agissant des zones de commentaires sur les clients, leur but est de permettre d’avoir une trace écrite des échanges avec les clients, notamment pour effectuer un suivi de leurs demandes. Il ne faut donc y inscrire que les commentaires qui sont véritablement utiles et nécessaires à cette fin.</p>
                              <a href="https://drive.google.com/file/d/1p6l8H2piIVIyFDxvehGWhBsoQqgVLOm-/view?usp=sharing" target="_blank">En savoir plus</a>
                              </div>
                            }>
                  <IconInfo/>
                </TooltipComponent>                
              </div>
              {/* Publier un commentaire */}
              <div>
                <Button btnType="outline" onClick={() => setAddNoteOpen(true)}>
                    <IconEdit/>Ajouter un commentaire
                </Button>
              </div>
              <div>
              <BlocWithTitle>
              {userNotes?.map((note) => (
                <PreviewNoteItem note={note} className=" mt-2"/>
              ))}
              </BlocWithTitle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationUpdateTrustLevel
        updateTrustLevelOpen={updateTrustLevelOpen}
        setUpdateTrustLevelOpen={setUpdateTrustLevelOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationAddNote
        addNoteOpen={addNoteOpen}
        setAddNoteOpen={setAddNoteOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationUpdateAlertStatus
        item={selectedItem}
        updateAlertStatusModal={updateAlertStatusOpen}
        setUpdateAlertStatusModal={setUpdateAlertStatusOpen}
        refreshData={refreshAlertListPage}
      />
    </div>
  );
}

export default ClientDetailV2Monitoring;
