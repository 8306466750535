import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../components/input';
import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Head from '../../../components/head';
import TablePage from '../../../components/tablepage';
import { columns, renderTable } from '../client/client.utils';
import { searchClientPageReq } from '../../../api/client/searchClient';
import IconLoading from '../../../components/loading/loading.jsx';
import {ReactComponent as IconSearch} from '../../../assets/icons/component/ic_search.svg';
import { ReactComponent as IlluSearch } from '../../../assets/icons/illu_search.svg'; // Import de l'image par défaut avant la recherche.
import { ReactComponent as IconNoResults } from '../../../assets/icons/no-results.svg'; // Import de l'image en cas de recherche sans résultats.
import { localStorageKeys } from '../../../api/constants.js';
import '../../../layouts/dashboard.scss';

const ClientSearchByIds = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('done');
  const [pageMetaData, setPageMetaData] = useState({});
  const [userPageMetaData, setUserPageMetaData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [showTableau, setShowTableau] = useState(false); // Etat d'affichage du tableau.
  const [defaultLoad, setDefaultLoad] = useState(true); // Etat d'affichage de l'icone par défaut.
  const [noResults, setNoResults] = useState(false); // Etat d'affichage de l'icone sans résultats.
  const [selectedStatus, setSelectedStatus] = useState('');

  const searchUserHandler = async (values) => {
    setLoading(true);
    setDataStatus('loading');
    setFormValues(values);
    try {
      const res = await searchClientPageReq(values);
      setPageMetaData(res.pageable);
      setUserPageMetaData({ count: res.totalElements });
      const tableData = renderTable(res.content);
      setTableData(tableData);

      //Si il y a un résultat.
      if (tableData.length > 0) {
        setShowTableau(true);
        setDefaultLoad(false);
        setNoResults(false);
      }
      //Si il n'y a pas de résultats.
      else {
        setShowTableau(false);
        setDefaultLoad(false);
        setNoResults(true);
      }
    } catch (error) {
      console.log(error);
      setShowTableau(false);
      setDefaultLoad(false);
      setNoResults(true);
    } finally {
      setLoading(false);
      setDataStatus('done');
    }
  };

  const FilterByStatus = (choosenStatus) => {
    // FONCTION QUI PERMET DE TRIER LES CLIENTS PAR STATUT
    setLoading(true);
    setDataStatus('loading');
    setSelectedStatus(choosenStatus);
    let pageNo = pageMetaData.pageNumber;
    let pageSize = pageMetaData.pageSize;
    searchClientPageReq(formValues, choosenStatus, pageNo, pageSize)
      .then((res) => {
        let renderResult = JSON.stringify(res.content);
        localStorage.setItem(localStorageKeys.clients_searched + choosenStatus, renderResult);
        setTableData(renderTable(res.content));
        setPageMetaData(res.pageable);
        setUserPageMetaData({ count: res.totalElements });
      })
      .catch((error) => {
        console.error('Erreur lors de la recherche des clients:', error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  const SortBy = (sortBy, isDescending) => {
    // FONCTION QUI PERMET DE TRIER LES CLIENTS PAR FILTRE (NOM, PRENOM, ETC.)
    setLoading(true);
    setDataStatus('loading');
    let pageNo = pageMetaData.pageNumber;
    let pageSize = pageMetaData.pageSize;
    const newSortDirection = isDescending ? 'DESC' : 'ASC';

    searchClientPageReq(formValues, selectedStatus, pageNo, pageSize, sortBy, newSortDirection)
      .then((res) => {
        let renderResult = JSON.stringify(res.content);
        localStorage.setItem(localStorageKeys.clients_searched, renderResult);
        setTableData(renderTable(res.content));
        setPageMetaData(res.pageable);
        setUserPageMetaData({ count: res.totalElements });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  const refreshData = (pageNo, pageSize) => {
    searchUserHandler(formValues, pageNo, pageSize);
  };

  useEffect(() => {
    let isRead;

    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    if (permContext) {
      /*Get data of the list*/
    }
  }, [permContext]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  const SearchUserInitialValues = {
    wallet_id: '',
    trz_wallet_id: '',
    search_text: '',
  };

  const SearchUserSchema = Yup.object().shape({
    //search_text: Yup.string().required('Requis'),
  });

  return (
    <div>
      <div className="o-head_search_client">
        {/* <ButtonBack to="/dashboard/client" className="o-btn o-btn-back"/> */}
        <NavLink to="/dashboard/client">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 11H9L12.29 7.71C12.3837 7.61704 12.4581 7.50644 12.5089 7.38458C12.5597 7.26272 12.5858 7.13201 12.5858 7C12.5858 6.86799 12.5597 6.73728 12.5089 6.61542C12.4581 6.49356 12.3837 6.38296 12.29 6.29C12.1026 6.10375 11.8492 5.99921 11.585 5.99921C11.3208 5.99921 11.0674 6.10375 10.88 6.29L6.59 10.59C6.21441 10.9633 6.00223 11.4704 6 12C6.00487 12.5261 6.21684 13.0291 6.59 13.4L10.88 17.7C10.9732 17.7926 11.0838 17.8659 11.2054 17.9157C11.3269 17.9656 11.4571 17.991 11.5885 17.9906C11.7199 17.9901 11.8499 17.9638 11.9712 17.913C12.0924 17.8623 12.2024 17.7882 12.295 17.695C12.3876 17.6018 12.4609 17.4912 12.5107 17.3696C12.5606 17.2481 12.586 17.1179 12.5856 16.9865C12.5851 16.8551 12.5588 16.7251 12.508 16.6038C12.4573 16.4826 12.3832 16.3726 12.29 16.28L9 13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
              fill="var(--onSurface)"
            />
          </svg>
        </NavLink>
        <Head headTitle="Recherche d'un client" isAdd={false} disabledSearch={true} />
      </div>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={SearchUserInitialValues}
          validationSchema={SearchUserSchema}
          onSubmit={(values) => {
            console.log('Search client submit');
            searchUserHandler(values);
          }}
        >
          <Form className="form-inline">
            <div className="form-group">
              <Field
                name="search_text"
                className="input-client-search"
                label="Prénom, Nom, Email, Mobile, User ID, Trz User ID"
                placeholder="Prénom, Nom, Email, Mobile, User ID, Trz User ID"
                component={InputFormik}
              />
              <Field
                name="wallet_id"
                label="Wallet ID"
                placeholder="Wallet ID"
                component={InputFormik}
              />
              <Field
                name="trz_wallet_id"
                label="Trz Wallet ID"
                placeholder="Trz Wallet ID"
                component={InputFormik}
              />
              <Button
                className="button-search"
                type="submit"
                icon={loading ? <IconLoading /> : <IconSearch />}
              >
                {' '}
                Rechercher
              </Button>
            </div>
          </Form>
        </Formik>
        {defaultLoad && (
          <div className="no-results-client-search">
            <IlluSearch />
            <p>Les résultats de votre recherche apparaîtront ici.</p>
          </div>
        )}
        {noResults && !defaultLoad && (
          <div className="no-results-client-search">
            <IconNoResults className="no-results-icon" />
            <p className="mt-3">Aucun résultat ne correspond à votre recherche.</p>
          </div>
        )}
        {showTableau && (
          <>
            <div className="o-content">
              <p className="results-number">
                {userPageMetaData.count} {userPageMetaData.count > 0 ? 'Résultats' : 'Résultat'}
              </p>
              <TablePage
                columns={columns}
                tableData={tableData}
                pageMetaData={userPageMetaData}
                loadTableData={refreshData}
                FilterByStatus={FilterByStatus}
                SortBy={SortBy}
                status={dataStatus}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''} // suppression de 'Clients' initialement affiché dans le header du tableau
                contain
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClientSearchByIds;
