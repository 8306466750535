import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';

import "./preview_alert_item.scss";

import InfoItem from "../../../../../components/infoItem";

import useFormattedAmount from "../../../../../hooks/useFormattedAmount";

/**
 * Renders an info item component with customizable title and value.
 * @param {Object} props - The component props.
 * @param {any} alert - The alert
 * @returns {JSX.Element} The rendered info item component.
 */
const PreviewAlertItem = React.forwardRef(
    (
        { alert },
        ref
    ) => {
    
    
    return (
        <div className={'previewAlertItem__container ' + alert.status.toLowerCase()}>
            <div className="previewAlertItem__container__title">
                <div>{alert.description}</div>
                <div>{moment(alert.created).format('DD/MM/YYYY HH:mm')}</div>
            </div>
            <div className="previewAlertItem__container__value">
                <div className="">{useFormattedAmount(alert.payin?.amount)}</div>
            </div>
        </div>
    );
}
);

PreviewAlertItem.propTypes = {
    wallet: PropTypes.any.isRequired,
};

export default PreviewAlertItem;
