import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {  detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { clientSepaSddrRejectedReq } from '../../../../../api/client/beneficiary/beneficiary.jsx';
// Renderer
import { tableSddrRejectedHeader, renderSddrRejectedTable } from './sepasddrrejected.utils.jsx';

// UI
import '../detailclient.scss';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import Head from '../../../../../components/head/index.jsx';
import Table from '../../../../../components/table/index.jsx';


import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2SddrError() {
  const { id } = useParams();
  const { t } = useTranslation();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    clientSepaSddrRejectedReq(id)
        .then((res) => {
          setTableData(renderSddrRejectedTable(res, t));
        })
        .finally(() => setDataStatus('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Mandats SDDR</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-12">
            <Table
              columns={tableSddrRejectedHeader}
              tableData={searchInput ? filteredData : tableData}
              status={dataStatus}
              setShowModal={setShowModal}
              setCurrentItem={setCurrentItem}
              itemPerPage={10}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2SddrError;
