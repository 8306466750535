import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { GroupSchema, GroupInitialValues } from '../group.utils';
import { getGroupDetailReq } from '../../../../api/group/groups';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ShowGroup = ({ showModal, setShowModal, selectedID }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [permissionDrp, setPermissionDrp] = useState();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      let options = [];
      getGroupDetailReq(selectedID)
        .then((res) => {
          res.permissions &&
            res.permissions.map((item) => options.push({ value: item.code, label: item.name }));
          setInitialValue({
            name: res.name,
            description: res.description,
            level: res.level,
          });
          setPermissionDrp(options);
        })
        .finally(() => {
          setStatus('done');
        });
    }
  }, [selectedID]);

  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : GroupInitialValues}
        validationSchema={GroupSchema}
      >
        {() => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setShowModal(false);}}/>
              <div className="o-modal-info__title-text o-title text-center">
                {t('group:detail_group')}
              </div>
              {status === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <>
                  <div className="o-modal-info__fields">
                    <Field
                      name="name"
                      label={t('group:name')}
                      placeholder={t('group:name_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="description"
                      label={t('group:description')}
                      placeholder={t('group:description_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="level"
                      label={t('group:level')}
                      placeholder={t('group:level_placeholder') + ' *'}
                      component={InputFormik}
                      type="number"
                      onlyRead
                    />
                    <Field
                      name="permission"
                      label={t('group:permission') + ' *'}
                      placeholder={t('group:permission_placeholder') + ' *'}
                      options={permissionDrp}
                      value={permissionDrp}
                      component={Dropdown}
                      isMulti
                      onlyRead
                    />
                  </div>
                  <Button
                    className="cancel" btnType="outline"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowGroup;
