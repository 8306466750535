import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';
import { ReactComponent as IconCheck } from '../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconDelete } from '../../../assets/icons/component/ic_delete.svg';

export const AssignedPackageFeatureInitialValues = {
  name: '',
  type: '',
  value: '',
  activated: '',
  included: '',
  order: '',
  featureType: '',
  description: '',
};
export const AssignedPackageFeatureSchema = Yup.object().shape({
  name: Yup.string().max(255, '255 car. max').required('Required'),
  type: Yup.object().required('Required'),
  value: Yup.number().integer(),
  activated: Yup.object().required('Required'),
  included: Yup.object().required('Required'),
  order: Yup.number().integer().required('Required'),
  featureType: Yup.object().required('Required'),
  description: Yup.string().max(255, '255 car. max').required('Required'),
});
export const columnsAssignedTable = [
  'Domain',
  'Ordre',
  'Nom',
  'Type',
  'Type de fonctionnalité',
  'Valeur',
  'Description',
  'Activé',
  'Inclus',
  'Modif.',
  'Supp.',
];

export const renderAssignedTable = (
  res,
  setAddModal,
  setDeleteModal,
  setSelectedID,
  isEdit,
  isDelete,
) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.featureId,
      searchData: item,
      featureDomain: item.featureDomain,
      order: item.order,
      name: item.name,
      type: item.type,
      featureType: item.featureType,
      value: item.value,
      description: item.description,
      activated: item.activated ? (
        <IconCheck className='o-table-icon'/>
      ) : (
        <IconDelete/>
      ),
      included: item.included ? (
        <IconCheck/>
      ) : (
        <IconDelete/>
      ),
      modif: isEdit ? (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedID(item.featureId);
            setAddModal(true);
          }}
        />
      ) : (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setSelectedID(item.featureId);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
