import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Requests
import {  detailClientReq} from '../../../../../api/client/detailClient.jsx';
import { getTopupCardsReq } from '../../../../../api/client/topupcards/topupcards.jsx';

// Renderer
import { tableTopupCardsHeader, renderTopupCardsTable } from './topupcards.utils.jsx';

// UI
import '../detailclient.scss';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

import Head from '../../../../../components/head/index.jsx';
import Logo from '../../../../../components/logo/index.jsx';
import Table from '../../../../../components/table/index.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2TopupCards() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [statusTopupCardsTable, setStatusTopupCardsTable] = useState('loading');
  const [topupCardsTableData, setTopupCardsTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [allTopupCards, setAllTopupCards] = useState([]);
  const [selectedTopupCards, setSelectedTopupCards] = useState();
  const [selectedItem, setSelectedItem] = useState();
  
  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, [permContext]);

  useEffect(() => {
    const topupCards = allTopupCards.find((x) => x.topupcardpaymentid === selectedID);
    setSelectedTopupCards(topupCards);
    setSelectedItem('topupCard');
  }, [selectedID]);

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
    
    if (permContext?.market) {
      getTopupCardsReq(permContext?.market, id)
        .then((res) => {
          setLoading(false);
          setAllTopupCards(res);
          setTopupCardsTableData(renderTopupCardsTable(res));
        })
        .finally(() => setStatusTopupCardsTable('done'));
    }
  };

  const renderSelectedTopupCardsActions = (topupCards) => {
    return (
      <div className="o-information-detail__actions">
        &nbsp;
      </div>
    );
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Toppup Carte</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-10">
            <Table
            columns={tableTopupCardsHeader}
            tableData={topupCardsTableData}
            status={statusTopupCardsTable}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => ''}
            itemPerPage={10}
            contain
          />
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <BlocWithTitle title="">
              {selectedID && selectedItem === 'topupCard' && (
                <div>
                  <div>
                    <div className="mt-5">
                      <b>Informations Topup Carte</b>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div className="mt-2">
                        <div>
                          <div>
                            <b>TopupCards ID</b>
                          </div>
                          <div>{selectedTopupCards?.topupcardpaymentid}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Transaction Ref</b>
                          </div>
                          <div>{selectedTopupCards?.transaction_ref}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Carte de paiement</b>
                          </div>
                          <div>{selectedTopupCards?.payment_product}</div>
                        </div>
                      </div>
                      <div className=" mt-2 ml-4">
                        <div className="mt-2">
                          <div>
                            <b>Date d'opération</b>
                          </div>
                          <div>{moment(selectedTopupCards?.operation_date).format('DD/MM/YYYY HH:mm')}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Order Id</b>
                          </div>
                          <div>{selectedTopupCards?.orderid}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Statut</b>
                          </div>
                          <div>{selectedTopupCards?.state}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Titulaire de Carte</b>
                          </div>
                          <div>{selectedTopupCards?.card_holder}</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <b>Actions</b>
                      <hr />
                      {renderSelectedTopupCardsActions(selectedTopupCards)}
                    </div>
                  </div>
                </div>
              )}
              {!selectedID && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2TopupCards;
