import React, { useState } from 'react';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { isicCardImportReq } from '../../../../../api/client/isic/isiccards';
import IconLoading from '../../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationImportIsicCard = ({
  importIsicCardOpen,
  setImportIsicCardOpen,
  userId,
  refreshData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const importHandler = async (id, data) => {
    setLoading(true);
    let status = await isicCardImportReq(id, data);
    setLoading(false);
    setImportIsicCardOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const ImportIsicCardInitialValues = () => {
    return {
      isic_card_number: '',
    };
  };
  const ImportIsicCardSchema = Yup.object().shape({
    isic_card_number: Yup.string()
      .min(14)
      .matches('^[a-zA-Z]{1}\\d{3}\\d{9}[a-zA-Z]{1}$')
      .required('Requis'),
  });

  return (
    <Modal isOpen={importIsicCardOpen} toggle={setImportIsicCardOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={ImportIsicCardInitialValues()}
        validationSchema={ImportIsicCardSchema}
        onSubmit={(values) => {
          importHandler(userId, values).then((res) => {
            if (res === 'success') {
              setImportIsicCardOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setImportIsicCardOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Ajout d'un commentaire sur le client
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="isic_card_number"
                components="textarea"
                label="ISIC Card Number"
                placeholder="S01234...789B *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setImportIsicCardOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationImportIsicCard;
