import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientAclRole } from '../../../../api/client/updateClient';
import { aclRolesReq } from '../../../../api/config/config';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg'

const ConfirmationUpdateAclRole = ({
  updateAclRoleOpen,
  setUpdateAclRoleOpen,
  userData,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [aclRoleData, setAclRoleData] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const { t } = useTranslation();
  const updateAclRoleHandler = async (id, values) => {
    setLoading(true);
    let reqParams = { acl_role: values.acl_role?.value };
    let status = await updateClientAclRole(id, reqParams);
    setLoading(false);
    setUpdateAclRoleOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  useEffect(() => {
    /*Get trustlevel list*/
    aclRolesReq().then((res) => {
      let aclRoleValues = [];
      res.map((item) => aclRoleValues.push({ value: item, label: item }));
      setAclRoleData(aclRoleValues);
    });
  }, []);

  const UpdateAclRoleInitialValues = (data) => {
    return {
      acl_role: data ? { value: data.acl_role, label: data.acl_role } : {},
    };
  };
  const UpdateAclRoleSchema = Yup.object().shape({});

  return (
    <Modal isOpen={updateAclRoleOpen} toggle={setUpdateAclRoleOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateAclRoleInitialValues(userData)}
        validationSchema={UpdateAclRoleSchema}
        onSubmit={(values) => {
          updateAclRoleHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdateAclRoleOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setUpdateAclRoleOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">Modification du rôle du client</div>
            <div className="o-modal-info__fields">
              <Field
                name="acl_role"
                label=""
                placeholder="Rôle du client"
                component={Dropdown}
                options={aclRoleData}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setUpdateAclRoleOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateAclRole;
