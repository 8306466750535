import React from 'react';
import Tooltip from "@mui/material/Tooltip";

import './tooltip.scss';

const TooltipComponent = ({ children, ...props }) => {
  return (
      <Tooltip
          {...props}
          arrow
          componentsProps={{
              tooltip: {
                  sx: {
                    bgcolor: "var(--button)", // Fond rouge
                    color: "var(--onButton)", // Texte blanc
                    fontSize: "1em",
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: "1.125rem",

                    padding: "0.5rem",
                    borderRadius: "0.25rem",

                    ".MuiTooltip-arrow": {
                        color: "var(--button)",
                    },
                  },
              },
          }}
      >
          {children}
      </Tooltip>
  );
};

export default TooltipComponent;
