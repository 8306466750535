import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutDashboard from './layouts/dashboard';
import DashboardHome from './modules/dashboard/home';
import NoMatch from './modules/404/index';
import Login from './modules/login/index';
import Permission from './modules/dashboard/permissions/index';
import Group from './modules/dashboard/group/index';
import User from './modules/dashboard/user/index';
import Document from './modules/dashboard/documenttype/index';
import CodePromo from './modules/dashboard/codepromo/index';
import InvitationCode from './modules/dashboard/admin/invitationcode/index';
import PackageFeature from './modules/dashboard/packageFeature/index';
import PackageType from './modules/dashboard/packageType/index';
import PackageTypeDetail from './modules/dashboard/packageType/detail';
import WaitingList from './modules/dashboard/admin/waitinglist/waitinglist'
import Client from './modules/dashboard/client/index';
import { ToastContainer } from 'react-toastify';
//import './assets/notification.css';
import 'react-toastify/dist/ReactToastify.css';
import PermissionsContext from './contexts/permissionContext';

import ClientSearchByIds from './modules/dashboard/client_search/index';
import ClientSearchByCodePromo from './modules/dashboard/client_by_code_promo/index';
import ClientSearchByStatus from './modules/dashboard/client_status/index';

import SupervisionCenter from './modules/dashboard/supervision/supervision'
import PayinAlerts from './modules/dashboard/supervision/payinalerts'
import SepasSddrRejected from './modules/dashboard/supervision/sepasddrrejected'
import ClientFraudSupected from './modules/dashboard/supervision/client_fraud_suspected';
import ClientBillingsToRecover from './modules/dashboard/supervision/client_to_recover/index';
import ClientWalletOverdraft from './modules/dashboard/supervision/client_overdraft/index';
import BusinessReports from './modules/dashboard/reports/reports'
import AdministrationCenter from './modules/dashboard/admin/admin'
import StatusCenter from './modules/dashboard/status/status'

import ClientWalletBalanceHistory from './modules/dashboard/client/wallet/balanceHistory';
import ClientWalletLineDetail from './modules/dashboard/client/walletline/walletline';

// Détail client V2
import ClientDetailV2Preview from './modules/dashboard/client/detail/preview';
import ClientDetailV2Informations from './modules/dashboard/client/detail/informations';
import ClientDetailV2Membership from './modules/dashboard/client/detail/membership/membership';
import ClientDetailV2Cards from './modules/dashboard/client/detail/cards';
import ClientDetailV2CardDetail from './modules/dashboard/client/detail/card/card_detail';
import ClientDetailV2IsicCards from './modules/dashboard/client/isic/isic_cards';
import ClientDetailV2Communication from './modules/dashboard/client/detail/communication';
import ClientDetailV2Monitoring from './modules/dashboard/client/detail/security/monitoring';
import ClientDetailV2SCAWallet from './modules/dashboard/client/detail/security/scawallet';
import ClientDetailV2History from './modules/dashboard/client/detail/security/history';
import ClientDetailV2KycKyb from './modules/dashboard/client/detail/security/kyc_kyb';
import ClientDetailV2Documents from './modules/dashboard/client/detail/security/documents';
import ClientDetailV2Billing from './modules/dashboard/client/detail/membership/billing';
import ClientDetailV2Wallets from './modules/dashboard/client/detail/wallets/wallets';
import ClientDetailV2Statement from './modules/dashboard/client/detail/wallets/statements';
import ClientDetailV2Bridge from './modules/dashboard/client/detail/operation/bridge';
import ClientDetailV2ScheduledTransfer from './modules/dashboard/client/detail/operation/scheduledtransfer';
import ClientDetailV2Beneficiary from './modules/dashboard/client/detail/operation/beneficiary';
import ClientDetailV2SddrMandat from './modules/dashboard/client/detail/operation/sddrmandat';
import ClientDetailV2SddrError from './modules/dashboard/client/detail/operation/sepasddrrejected';
import ClientDetailV2TopupCards from './modules/dashboard/client/detail/operation/topupcards';
import ClientDetailV2Member from './modules/dashboard/client/detail/members';
import ClientV2WalletDetail from './modules/dashboard/client/detail/wallets/wallet_detail';

/*
Allows to select the layout for the pages
It provides convenience to write conditions according to layout type
*/
export const withLayout = (Layout, Component) => {
  return (
    <Layout>
      <Component />
    </Layout>
  );
};

const App = () => {
  const [permContext, setPermContext] = useState();
  const permissionsValue = { permContext, setPermContext };
  return (
    <PermissionsContext.Provider value={permissionsValue}>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route exact path="/dashboard" element={withLayout(LayoutDashboard, DashboardHome)}/>
            <Route exact path="/dashboard/client" element={withLayout(LayoutDashboard, Client)}/>

            {/* Recherches clients */}
            <Route exact path="/dashboard/client_search" element={withLayout(LayoutDashboard, ClientSearchByIds)}/>
            <Route exact path="/dashboard/client_by_code_promo" element={withLayout(LayoutDashboard, ClientSearchByCodePromo)}/>
            <Route exact path="/dashboard/clientbystatus/:status" element={withLayout(LayoutDashboard, ClientSearchByStatus)}/>

            {/* Detail client V2 */}
            <Route exact path="/dashboard/client/:id" element={withLayout(LayoutDashboard, ClientDetailV2Preview)}/>
            <Route exact path="/dashboard/client/:id/informations" element={withLayout(LayoutDashboard, ClientDetailV2Informations)}/>
            <Route exact path="/dashboard/client/:id/members" element={withLayout(LayoutDashboard, ClientDetailV2Member)}/>
            {/* Security */}
            <Route exact path="/dashboard/client/:id/security/monitoring" element={withLayout(LayoutDashboard, ClientDetailV2Monitoring)}/>
            <Route exact path="/dashboard/client/:id/security/kyc_kyb" element={withLayout(LayoutDashboard, ClientDetailV2KycKyb)}/>
            <Route exact path="/dashboard/client/:id/security/history" element={withLayout(LayoutDashboard, ClientDetailV2History)}/>
            <Route exact path="/dashboard/client/:id/security/sca" element={withLayout(LayoutDashboard, ClientDetailV2SCAWallet)}/>
            <Route exact path="/dashboard/client/:id/security/documents" element={withLayout(LayoutDashboard, ClientDetailV2Documents)}/>
            {/* Membership */}
            <Route exact path="/dashboard/client/:id/membership/membership" element={withLayout(LayoutDashboard, ClientDetailV2Membership)}/>
            <Route exact path="/dashboard/client/:id/membership/billing" element={withLayout(LayoutDashboard, ClientDetailV2Billing)}/>
            {/* Wallet */}
            <Route exact path="/dashboard/client/:id/wallets/list" element={withLayout(LayoutDashboard, ClientDetailV2Wallets)}/>
            <Route exact path="/dashboard/client/:id/wallets/:walletId" element={withLayout(LayoutDashboard, ClientV2WalletDetail)}/>
            <Route exact path="/dashboard/client/:userId/wallets/:walletId/balance/history" element={withLayout(LayoutDashboard, ClientWalletBalanceHistory)}/>
            <Route exact path="/dashboard/client/:userId/wallets/:walletId/line/:walletlineId" element={withLayout(LayoutDashboard, ClientWalletLineDetail)}/>
            <Route exact path="/dashboard/client/:id/wallets/statements" element={withLayout(LayoutDashboard, ClientDetailV2Statement)}/>
            {/* Opérations */}
            <Route exact path="/dashboard/client/:id/operation/beneficiary" element={withLayout(LayoutDashboard, ClientDetailV2Beneficiary)}/>
            <Route exact path="/dashboard/client/:id/operation/sddrmandat" element={withLayout(LayoutDashboard, ClientDetailV2SddrMandat)}/>
            <Route exact path="/dashboard/client/:id/operation/sddrerror" element={withLayout(LayoutDashboard, ClientDetailV2SddrError)}/>
            <Route exact path="/dashboard/client/:id/operation/bridge" element={withLayout(LayoutDashboard, ClientDetailV2Bridge)}/>
            <Route exact path="/dashboard/client/:id/operation/topupcards" element={withLayout(LayoutDashboard, ClientDetailV2TopupCards)}/>
            <Route exact path="/dashboard/client/:id/operation/scheduledtransfer" element={withLayout(LayoutDashboard, ClientDetailV2ScheduledTransfer)}/>
            {/* Cards */}
            <Route exact path="/dashboard/client/:id/cards/list" element={withLayout(LayoutDashboard, ClientDetailV2Cards)}/>
            <Route exact path="/dashboard/client/:userId/cards/isic" element={withLayout(LayoutDashboard, ClientDetailV2IsicCards)}/>
            <Route exact path="/dashboard/client/:userId/cards/:cardId" element={withLayout(LayoutDashboard, ClientDetailV2CardDetail)}/>
            {/* Communication */}
            <Route exact path="/dashboard/client/:id/communication" element={withLayout(LayoutDashboard, ClientDetailV2Communication)}/>

            {/* Reports */}
            <Route exact path="/dashboard/reports/reports" element={withLayout(LayoutDashboard, BusinessReports)}/>
            <Route exact path="/dashboard/admin/permissions" element={withLayout(LayoutDashboard, Permission)}/>
            <Route exact path="/dashboard/admin/groups" element={withLayout(LayoutDashboard, Group)}/>
            <Route exact path="/dashboard/admin/users" element={withLayout(LayoutDashboard, User)}/>
            <Route exact path="/dashboard/admin/documents" element={withLayout(LayoutDashboard, Document)}/>
            <Route exact path="/dashboard/admin/codepromos" element={withLayout(LayoutDashboard, CodePromo)}/>
            <Route exact path="/dashboard/admin/invitationcodes" element={withLayout(LayoutDashboard, InvitationCode)}/>
            <Route exact path="/dashboard/admin/package-features" element={withLayout(LayoutDashboard, PackageFeature)}/>
            <Route exact path="/dashboard/admin/package-types" element={withLayout(LayoutDashboard, PackageType)}/>
            <Route exact path="/dashboard/admin/package-types/:id" element={withLayout(LayoutDashboard, PackageTypeDetail)}/>
            <Route exact path="/dashboard/admin/waitinglist" element={withLayout(LayoutDashboard, WaitingList)}/>
            
            {/* Administration */}
            <Route exact path="/dashboard/admin" element={withLayout(LayoutDashboard, AdministrationCenter)}/>

            {/* Supervision */}
            <Route exact path="/dashboard/supervision" element={withLayout(LayoutDashboard, SupervisionCenter)}/>
            <Route exact path="/dashboard/supervision/client_fraud_suspected" element={withLayout(LayoutDashboard, ClientFraudSupected)}/>
            <Route exact path="/dashboard/supervision/payin_alerts" element={withLayout(LayoutDashboard, PayinAlerts)}/>
            <Route exact path="/dashboard/supervision/sddr_rejected" element={withLayout(LayoutDashboard, SepasSddrRejected)}/>
            <Route exact path="/dashboard/supervision/client_to_recover" element={withLayout(LayoutDashboard, ClientBillingsToRecover)}/>
            <Route exact path="/dashboard/supervision/client_overdraft" element={withLayout(LayoutDashboard, ClientWalletOverdraft)}/>
            
            {/* Status des services */}
            <Route exact path="/dashboard/status" element={withLayout(LayoutDashboard, StatusCenter)}/>
            
            {/* Login */}
            <Route exact path="/" element={<Login />}/>
            <Route element={<NoMatch />}/>
          </Routes>
        </BrowserRouter>
    </PermissionsContext.Provider>
  );
};

export default App;
