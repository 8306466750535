import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { updateMembershipLimits } from '../../../../../api/client/clientmembership';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationUpdatePayoutLimits = ({
  userID,
  membership,
  updatePayoutLimitsModal,
  setUpdatePayoutLimitsModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const updatePayoutLimitsHandler = async (data) => {
    setLoading(true);
    console.log(
      'membership.limit_payout_amount_monthly : ' + membership.limit_payout_amount_monthly,
    );
    let status = await updateMembershipLimits(userID, data);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setUpdatePayoutLimitsModal(false);
  };

  const UpdatePayoutLimitsInitialValues = {
    limit_payout_amount_daily:
      membership?.limit_payout_amount_daily != null ? membership.limit_payout_amount_daily : '2000',
    limit_payout_amount_monthly:
      membership?.limit_payout_amount_monthly != null
        ? membership.limit_payout_amount_monthly
        : '5000',
  };
  const UpdatePayoutLimitsSchema = Yup.object().shape({
    limit_payout_amount_daily: Yup.number().required('Requis').min(0).integer(),
    limit_payout_amount_monthly: Yup.number().required('Requis').min(0).integer(),
  });

  return (
    <Modal isOpen={updatePayoutLimitsModal} toggle={setUpdatePayoutLimitsModal}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdatePayoutLimitsInitialValues}
        validationSchema={UpdatePayoutLimitsSchema}
        onSubmit={(values) => {
          console.log('Update payout limits' + values);
          updatePayoutLimitsHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={close}/>
            <div className="o-modal-info__title-text o-title">
              Modification des plafonds de virement externe{' '}
            </div>

            <div className="o-modal-info__fields">
              <Field
                name="limit_payout_amount_daily"
                label="Limite Virement externe / Jour *"
                placeholder="Limite Virement externe / Jour *"
                component={InputFormik}
              />
              <Field
                name="limit_payout_amount_monthly"
                label="Limite Virement externe / Mois *"
                placeholder="Limite Virement externe / Mois *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdatePayoutLimits;
