import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
// Requests
import { detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { clientDocumentReq } from '../../../../../api/client/document.jsx';

// UI
import "../detailclient.scss";
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import Head from '../../../../../components/head/index.jsx';
import Table from '../../../../../components/table/index.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';
import Button from '../../../../../components/button/index.jsx';

import { ReactComponent as IconDoc } from '../../../../../assets/icons/component/ic_doc.svg';

import ShowDocument from '../../modals/showDocument.jsx';

// Modals
import ConfirmationSubmitKYCDocs from '../../modals/confirmationSubmitKycDocs.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2Documents() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [submitKycDocOpen, setSubmitKycDocOpen] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  const columnsDocumentTable = ['Document ID','Trz Document ID','Création','Mise à jour', 'Statut', 'Type de document','Niveau', 'Nom'];
  const renderDocumentTable = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item,
        id: item.documentId,
        trzId: item.trzDocumentId,
        createdDate: item.created ? moment(item.created).format('DD/MM/YYYY HH:mm') : '-',
        updatedDate: item.updated ? moment(item.updated).format('DD/MM/YYYY HH:mm') : '-',
        status: item.documentStatus,
        type: item.type?.name,
        level: item.type?.level,
        name: item.fileName,
      }),
    );
    return data;
  };

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails client
        </div>
      </Fragment>
    );
  }

  function renderUploadDocumentButton() {
    return (
      <div className="ml-auto">
        <Button btnType="outline" className="ml-4" onClick={() => setSubmitKycDocOpen(true)}>
          <IconDoc/>Ajout Document
        </Button>
      </div>
    );
  }
  
  const refreshData = () => {
    setLoading(true);
    detailClientReq(id, false).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    setStatus('loading');
    clientDocumentReq(id)
      .then((res) => {
        setTableData(renderDocumentTable(res));
      })
      .finally(() => setStatus('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <div><b>Documents</b></div>
            <div>{renderUploadDocumentButton()}</div>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column">
            <Table
              showModal={showModal}
              setShowModal={setShowModal}
              columns={columnsDocumentTable}
              setSelectedID={setSelectedID}
              tableData={tableData}
              status={status}
              itemPerPage={10}
            />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ShowDocument
          userId={id}
          document={selectedID}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <ConfirmationSubmitKYCDocs
        submitKycOpen={submitKycDocOpen}
        setSubmitKycOpen={setSubmitKycDocOpen}
        userId={id}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetailV2Documents;
