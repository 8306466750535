import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { updateClientEmail } from '../../../../api/client/updateClient';
import IconLoading from '../../../../components/loading/loading.jsx';
import { ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ConfirmationUpdateEmail = ({
  updateEmailOpen,
  setUpdateEmailOpen,
  userData,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const updateHandler = async (id, confirmName) => {
    setLoading(true);
    let status = await updateClientEmail(id, confirmName);
    setLoading(false);
    setUpdateEmailOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const UpdateEmailInitialValues = (data) => {
    return {
      oldEmail: data ? data.email : '',
      newEmail: '',
      confirmEmail: '',
    };
  };
  const UpdateEmailSchema = Yup.object().shape({
    oldEmail: Yup.string().email('Adresse email non valide').required('Requis'),
    newEmail: Yup.string().email('Adresse email non valide').required('Requis'),
    confirmEmail: Yup.string()
      .email('Adresse email non valide')
      .oneOf([Yup.ref('newEmail'), null], 'Les adresses email doivent être identiques')
      .required('Confirmation requise'),
  });

  return (
    <Modal isOpen={updateEmailOpen} toggle={setUpdateEmailOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateEmailInitialValues(userData)}
        validationSchema={UpdateEmailSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          updateHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdateEmailOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setUpdateEmailOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Pour confirmer la modification de l'adresse email
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="oldEmail"
                label="Ancien email"
                placeholder="Ancien email"
                component={InputFormik}
              />
              <Field
                name="newEmail"
                label="Nouvel email"
                placeholder="Nouvel email"
                component={InputFormik}
              />
              <Field
                name="confirmEmail"
                label="Confirmation du nouvel email"
                placeholder="Confirmation du nouvel email"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button className="confirm-infos" type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateEmail;
