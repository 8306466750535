import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientBlacklistRemoveMandateReq } from '../../../../../api/client/beneficiary/beneficiary';
import IconLoading from '../../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg'


const ConfirmationRemoveBlacklistMandate = ({
  removeBlacklistMandateOpen,
  setRemoveBlacklistMandateOpen,
  userId,
  beneficiaryId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const removeBlacklistMandateHandler = async (id, beneficiaryId, data) => {
    setLoading(true);
    let status = await clientBlacklistRemoveMandateReq(id, beneficiaryId, data);
    setLoading(false);
    setRemoveBlacklistMandateOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const BlacklistMandateInitialValues = {};
  const BlacklistMandateSchema = Yup.object().shape({
    mandate: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={removeBlacklistMandateOpen} toggle={setRemoveBlacklistMandateOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={BlacklistMandateInitialValues}
        validationSchema={BlacklistMandateSchema}
        onSubmit={(values) => {
          console.log('Blacklist beneficiary mandat : ' + values);
          removeBlacklistMandateHandler(userId, beneficiaryId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setRemoveBlacklistMandateOpen(false);}}/>              
            <div className="o-modal-info__title-text o-title">
              Pour confirmer le rétablissement du mandat, veuillez saisir la référence du mandat à
              rétablir
            </div>
            <div className="o-modal-info__fields">
              <Field name="mandate" label="Mandat" placeholder="Mandat *" component={InputFormik} />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button
                className="cancel" btnType="outline"
                onClick={() => setRemoveBlacklistMandateOpen(false)}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationRemoveBlacklistMandate;
