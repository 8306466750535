import React, { useState } from 'react';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';

import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { uploadCardFilesReq } from '../../../../../api/client/isic/isiccards';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationUploadIsicCardFiles = ({
  uploadIsicCardFilesOpen,
  setUploadIsicCardFilesOpen,
  userId,
  isicCard,
  refreshData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedProof, setSelectedProof] = useState(null);

  const submitUploadISicCardFiles = async () => {
    setLoading(true);
    let status = await uploadCardFilesReq(userId, isicCard.cardid, selectedPhoto, selectedProof);
    setLoading(false);
    setUploadIsicCardFilesOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const displaySelectedPhoto = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <IconDelete />
      </div>
    );
  };

  const displaySelectedProof = (name) => {
    return (
      <div className="file-container">
        <span>{name}</span>
        <IconDelete />
      </div>
    );
  };

  const UploadIsicCardFilesInitialValues = () => {
    return {};
  };
  const UploadIsicCardFilesSchema = Yup.object().shape({});

  return (
    <Modal isOpen={uploadIsicCardFilesOpen} toggle={setUploadIsicCardFilesOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UploadIsicCardFilesInitialValues()}
        validationSchema={UploadIsicCardFilesSchema}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setUploadIsicCardFilesOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Upload des fichiers photo et/ou preuve de scolarité
            </div>
            <div
              style={{
                textAlign: 'center',
                marginTop: '45px',
                marginBottom: '15px',
                position: 'relative',
              }}
            >
              <a style={{ color: '#00DB9B', textDecoration: 'underlined' }}>
                {selectedPhoto?.name ? displaySelectedPhoto(selectedPhoto.name) : '+ Add Photo'}
              </a>
              <input
                name="photo"
                type="file"
                className="custom-file-input"
                onChange={(e) => setSelectedPhoto(e.target.files[0])}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                marginTop: '45px',
                marginBottom: '45px',
                position: 'relative',
              }}
            >
              <a style={{ color: '#00DB9B', textDecoration: 'underlined' }}>
                {selectedProof?.name ? displaySelectedProof(selectedProof.name) : '+ Add Proof'}
              </a>
              <input
                name="proof"
                type="file"
                className="custom-file-input"
                onChange={(e) => setSelectedProof(e.target.files[0])}
              />
            </div>
            <div className="o-btn-group">
              <Button onClick={submitUploadISicCardFiles} type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel"
                btnType="outline"
                onClick={() => setUploadIsicCardFilesOpen(false)}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUploadIsicCardFiles;
