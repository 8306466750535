import { useMemo } from "react";

function useFormattedAmount( amount, locale = "fr-FR", currency = "EUR" ) {
    const formattedAmount = useMemo(() => {
        // Vérifie si le montant est un nombre ou égal à 0
        if (typeof amount !== "number" || isNaN(amount)) {
            // console.warn(`Invalid amount: ${amount}. Expected a number.`);
            return undefined;
        }

        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency,
        }).format(amount);
    }, [amount, locale, currency]);

    return formattedAmount;
}

export default useFormattedAmount;
