import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { UserSchema, UserInitialValues } from '../user.utils';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../components/loading/loading.jsx';
import { detailUserReq } from '../../../../api/user/users';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ShowUser = ({ showModal, setShowModal, selectedID }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [groupDrp, setGroupDrp] = useState();
  const [permissionDrp, setPermissionDrp] = useState();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      detailUserReq(selectedID)
        .then((res) => {
          let permissionOptions = [];
          res.permissions &&
            res.permissions.map((selectedPermission) =>
              permissionOptions.push({
                value: selectedPermission.code,
                label: selectedPermission.name,
              }),
            );
          setPermissionDrp(permissionOptions);
          setInitialValue({
            email: res.email,
            group: res.group && { value: res.group.id, label: res.group.name },
            permissions: permissionOptions,
          });
          res.group && setGroupDrp([{ value: res.group.id, label: res.group.name }]);
        })
        .finally(() => setStatus('done'));
    }
  }, [selectedID]);

  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : UserInitialValues}
        validationSchema={UserSchema}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setShowModal(false);}}/>
              <div className="o-modal-info__title-text">
                <div className='o-title'>{t('user:detail_user')}</div>
              </div>
              {status === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <Fragment>
                  <div className="o-modal-info__fields">
                    <Field
                      name="email"
                      label={t('user:email')}
                      placeholder={t('user:email_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="group"
                      label={t('user:group')}
                      placeholder={t('user:group_placeholder') + ' *'}
                      options={groupDrp}
                      component={Dropdown}
                      onlyRead
                    />
                    {permissionDrp.length > 0 && (
                      <Field
                        name="permissions"
                        label="Permissions"
                        placeholder="Permissions"
                        options={permissionDrp}
                        component={Dropdown}
                        onlyRead
                        isMulti
                      />
                    )}
                  </div>
                  <Button
                    className="cancel" btnType="outline"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowUser;
