import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
// Request
import {
  detailClientReq,
} from '../../../../api/client/detailClient.jsx';
import { historyClientEventNotificationReq } from '../../../../api/client/historyClient.jsx';

// UI
import "./detailclient.scss";
import Table from '../../../../components/table/index.jsx';
import ClientDetailFilterTabs from './client_filter_tabs.jsx';
import Head from '../../../../components/head/index.jsx';
import PermissionsContext from '../../../../contexts/permissionContext.js';

import ClientDetailV2Header from './client_header.jsx';

function ClientDetailV2Communication() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableHistoryData, setTableHistoryData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading');

  const { permContext } = useContext(PermissionsContext);

  const columnsNotifEventTable = [
    'Date','Code','Type',
  ];
  const renderNotifEventTable = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item,
        date: moment(item.created).format('DD/MM/YYYY HH:mm'),
        code: item.code,
        type: item.type,
      }),
    );
    return data;
  };

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={status ? '/dashboard/clientbystatus/' + status : '/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
    historyClientEventNotificationReq(id)
      .then((res) => {
        setTableHistoryData(renderNotifEventTable(res));
      })
      .finally(() => setStatus('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column">
            <Table
              showModal={showModal}
              setShowModal={setShowModal}
              columns={columnsNotifEventTable}
              tableData={tableHistoryData}
              status={status}
              textLeft
              ContainHeader={() => ''}
              contain
              itemPerPage={25}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2Communication;
