import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { deleteInvitationCodeReq } from '../../../../../api/invitationcode/invitationcode';
import {ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg'

const DeletePermission = ({ deleteModal, setDeleteModal, currentItem, refreshData }) => {
  const { t } = useTranslation();

  const deleteReq = () => {
    deleteInvitationCodeReq(currentItem.invitation_code).then((res) => {
      if (res === 'success') {
        refreshData();
        setDeleteModal(false);
      }
    });
  };

  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
        <IconClose className='icon-cancel' onClick={() => { setDeleteModal(false);}}/>                    
        <div className="o-modal-info__title-text">
          <div className="o-title">Suppression de code d'invitation</div>
          <div
            className="o-modal-info__title-text__text"
            dangerouslySetInnerHTML={{
              __html: "Voulez-vous vraiment supprimer ce code d'invitation ?",
            }}
          />
        </div>
        <div className="o-btn-group">
          <Button type="submit" onClick={deleteReq}>
            {t('global:confirm')}
          </Button>
          <Button
            className="cancel"
            btnType="outline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePermission;
