import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { createFeesToCollectClient } from '../../../../../api/client/billing';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationCreateFutureFees = ({
  createFutureFeesOpen,
  setCreateFutureFeesOpen,
  userId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const createFutureFeesHandler = async (userId, data) => {
    setLoading(true);
    console.log(data);
    data['userId'] = userId;
    let status = await createFeesToCollectClient(userId, data);
    setLoading(false);
    if (status === 'success') {
      setCreateFutureFeesOpen(false);
      refreshData();
    }
  };

  const CollectFeesInitialValues = {
    label: 'Frais',
    amount: '',
  };
  const CollectFeesSchema = Yup.object().shape({
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={createFutureFeesOpen} toggle={setCreateFutureFeesOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={CollectFeesInitialValues}
        validationSchema={CollectFeesSchema}
        onSubmit={(values) => {
          createFutureFeesHandler(userId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setCreateFutureFeesOpen(false);}}/>
            <div className="o-modal-info__title-text">
              <div className="o-title">Saisir les informations de l'élément de facturation</div>
              <div className="o-modal-info__title-text__text">
                ATTENTION : La facturation sera effectuée le soir ou dès que le wallet sera
                suffisamment alimenté
              </div>
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="label"
                label="Libellé des frais"
                placeholder="Libellé des frais *"
                component={InputFormik}
              />
              <Field
                name="amount"
                label="Montant des frais (€)"
                placeholder="Montant des frais en €"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button
                className="cancel" btnType="outline"
                onClick={() => setCreateFutureFeesOpen(false)}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationCreateFutureFees;
