import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Head from '../../../../components/head/index.jsx';
import IconLoading from '../../../../components/loading/loading.jsx';
import { ReactComponent as IconDelete } from '../../../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconEdit } from '../../../../assets/icons/component/ic_edit.svg';
import Button from '../../../../components/button/index.jsx';

// Requêtes
import { detailClientReq } from '../../../../api/client/detailClient.jsx';
import { getClientGodchildrenReq } from '../../../../api/client/getClient.jsx';

// Modals
import ConfirmationUpdateInfos from './../modals/confirmationUpdateInfos';
import ConfirmationUpdateLegalInfos from './../modals/confirmationUpdateLegalInfos';
import ConfirmationUpdatePhone from './../modals/confirmationUpdatePhone';
import ConfirmationUpdateEmail from './../modals/confirmationUpdateEmail';
import ConfirmationUpdateCodePromo from './../modals/confirmationUpdateCodePromo';
import ConfirmationDeleteCodePromo from './../modals/confirmationDeleteCodePromo';
import ConfirmationUpdateTrustLevel from './../modals/confirmationUpdateTrustLevel';
import ConfirmationUpdateAclRole from './../modals/confirmationUpdateAclRole';
import PermissionsContext from '../../../../contexts/permissionContext.js';

// UI
import './detailclient.scss';
import ClientDetailFilterTabs from './client_filter_tabs';
import ClientDetailV2Header from './client_header.jsx';
import BlocWithTitle from '../../../../components/blocWithTitle/index.jsx';
import InfoItem from '../../../../components/infoItem/index.jsx';
import Table from '../../../../components/table/index.jsx';

export default function ClientDetailV2Informations() {
  const [detailData, setDetailData] = useState();
  const [updateInfosOpen, setUpdateInfosOpen] = useState(false);
  const [updateLegalInfosOpen, setUpdateLegalInfosOpen] = useState(false);
  const [updateEmailOpen, setUpdateEmailOpen] = useState(false);
  const [updatePhoneOpen, setUpdatePhoneOpen] = useState(false);
  const [updateCodePromo, setUpdateCodePromo] = useState(false);
  const [deleteCodePromo, setDeleteCodePromo] = useState(false);
  const [updateTrustLevel, setUpdateTrustLevel] = useState(false);
  const [updateAclRole, setUpdateAclRole] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [statusGodchildrenTable, setStatusGodchildrenTable] = useState('loading');
  const [godchildrenTableData, setGodchildrenTableData] = useState([]);
  const [allGodchildren, setAllGodchildren] = useState([]);
  const [selectedGodchildren, setSelectedGodchildren] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const { permContext } = useContext(PermissionsContext);

  const [status, setStatus] = useState('loading');
  const { id } = useParams();

  const tableGodchildrenHeader = ['ID', 'Nom', 'Statut', 'Validation'];

  const renderGodchildrenTable = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item.userId,
        userId: item.userId,
        name: item.firstname + ' ' + item.lastname,
        status: item.userStatus,
        validation_date: item.validation_date ? moment(item.validation_date).format('DD/MM/YYYY HH:mm') : '',
      }),
    );
    return data;
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails client
        </div>
      </Fragment>
    );
  }
  useEffect(() => {
    refreshData();
  }, [id]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  const refreshData = () => {
    setLoading(true);
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        setLoading(false);
      })
      .finally(() => {
        setStatus('done');
        setLoading(false);
      });
    
    getClientGodchildrenReq(id)
      .then((res) => {
        setLoading(false);
        setAllGodchildren(res);
        setGodchildrenTableData(renderGodchildrenTable(res));
      })
      .finally(() => setStatusGodchildrenTable('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>

      {status === 'loading' ? (
        <div className="d-flex mx-auto mt-4">
          <IconLoading size={40} />
        </div>
      ) : (
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Informations client</b>
          </div>
          <div className="o-content__detailclient__row">
            {/* Colonne 1 */}
            <div className="o-content__detailclient__row__column col-3">
              {permContext.market === 'pro' && detailData?.company_type && detailData?.legal_infos && (
              <BlocWithTitle title="ENTREPRISE">
                <InfoItem title="Nom de société" value={detailData?.legal_infos?.legal_name} />
                <InfoItem title='SIREN/SIRET' value={detailData?.legal_infos?.legal_registration_number}/>
                <InfoItem title='N° TVA intraco.' value={detailData?.legal_infos?.legal_tva_number}/>
                <InfoItem title="Date d'immatriculation" value={detailData?.legal_infos?.legal_registration_date}/>
                <InfoItem title='Forme juridique' value={detailData?.legal_infos?.legal_form
                        ? detailData?.legal_infos?.legal_form?.code_insee_level_3 +
                          ' - ' +
                          detailData?.legal_infos?.legal_form?.name_insee_level_3
                        : '-'}/>
                <InfoItem title='Capital social' value={detailData?.legal_infos?.legal_share_capital
                        ? detailData?.legal_infos?.legal_share_capital + ' €'
                        : '- €'}/>
                <InfoItem title="Secteur d'activité" value={detailData?.legal_infos?.legal_sector}/>
                <InfoItem title='CA annuel' value={detailData?.legal_infos?.legal_annual_turn_over}/>
                <InfoItem title='Résultat net annuel' value={detailData?.legal_infos?.legal_annual_net_income}/>
                <InfoItem title='Nombre employés' value={detailData?.legal_infos?.legal_number_of_employee}/>
              </BlocWithTitle>
              )}

              <BlocWithTitle title="IDENTITÉ">
                <InfoItem title='Titre' value={detailData?.title}/>
                <InfoItem title='Prénom' value={detailData?.firstname}/>
                <InfoItem title='Nom' value={detailData?.lastname}/>
                <InfoItem title='Date de naissance' value={detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'}/>
                <InfoItem title='Ville de Naissance' value={detailData?.placeOfBirth}/>
                <InfoItem title='Pays de Naissance' value={detailData?.birthCountry}/>
                <InfoItem title='Nationalité' value={detailData?.nationality}/>
                <InfoItem title='Autre Nationalité' value={detailData?.nationalityOther}/>
              </BlocWithTitle>
            </div>
            {/* Colonne 2 */}
            <div className="o-content__detailclient__row__column col-4">
              <BlocWithTitle title="COORDONNÉES">                
                <InfoItem title='E-mail' value={detailData?.email}/>
                <InfoItem title='Téléphone mobile' value={detailData?.mobile}/>
              </BlocWithTitle>
              <BlocWithTitle title="ADRESSE">
                <InfoItem title='Adresse 1' value={detailData?.address1}/>
                <InfoItem title='Adresse 2' value={detailData?.address2}/>
                <InfoItem title='Code Postal' value={detailData?.postcode}/>
                <InfoItem title='Ville' value={detailData?.city}/>
                <InfoItem title='État' value={detailData?.state}/>
                <InfoItem title='Pays' value={detailData?.country}/>
                <InfoItem title='Nom du Pays' value={detailData?.countryName}/>
              </BlocWithTitle>
              <BlocWithTitle title="INFORMATIONS">
                <InfoItem title='Occupation' value={detailData?.occupation}/>
                <InfoItem title='Revenus' value={detailData?.incomeRange}/>
                <InfoItem title='Patrimoine financier' value={detailData?.personalAssets}/>
              </BlocWithTitle>
              <BlocWithTitle title="LEGAL">
                <InfoItem title='Activités hors EU' value={detailData?.activity_outside_eu != null ? (detailData?.activity_outside_eu ? 'Oui' : 'Non') : '-'}/>
                <InfoItem title='Sanctions Economiques' value={detailData?.economic_sanctions != null ? (detailData?.economic_sanctions ? 'Oui' : 'Non') : '-'}/>
                <InfoItem title='Sanctions du pays résident' value={detailData?.resident_countries_sanctions != null ? (detailData?.resident_countries_sanctions ? 'Oui' : 'Non') : '-'}/>
                <InfoItem title='Sanctions en cours' value={detailData?.involved_sanctions != null ? (detailData?.involved_sanctions ? 'Oui' : 'Non') : '-'}/>
              </BlocWithTitle>
              <BlocWithTitle title="IMPOSITION">
                <InfoItem title='US Person ?' value={detailData?.specifiedUSPerson ? 'Oui' : 'Non'}/>
                <InfoItem title='Numéro d’imposition' value={detailData?.taxNumber}/>
                <InfoItem title='Pays d’imposition' value={detailData?.taxResidence}/>
              </BlocWithTitle>
            </div>
            {/* Colonne 3 */}
            <div className="o-content__detailclient__row__column col-3">
              <BlocWithTitle title="DATES">
                <InfoItem title='Date de création' value={detailData?.created ? moment(detailData?.created).format('DD/MM/YYYY HH:mm') : '-'}/>
                <InfoItem title='Dernière mise à jour' value={detailData?.updated ? moment(detailData?.updated).format('DD/MM/YYYY HH:mm') : '-'}/>
                <InfoItem title='Date de validation' value={detailData?.validation_date ? moment(detailData?.validation_date).format('DD/MM/YYYY HH:mm') : '-'}/>
              </BlocWithTitle>
              <BlocWithTitle title="USER">
                <InfoItem title='User ID' value={detailData?.userId}/>
                <InfoItem title='Treezor User ID' value={detailData?.trzUserId}/>
                <InfoItem title='User Status' value={detailData?.userStatus}/>
                <InfoItem title='User Type ID' value={detailData?.userTypeId}/>
                <InfoItem title='Rôle' value={detailData?.acl_role}/>
              </BlocWithTitle>
              <BlocWithTitle title="CODES">
                <div className="infoItem__container">
                  <div className="infoItem__title">Code Promo</div>
                  <div className="infoItem__value">
                    {detailData?.code_promo ? (
                      <>
                        {detailData?.code_promo}
                        <Button
                          btnType="none"
                          action="delete"
                          onClick={() => setDeleteCodePromo(true)}
                        >
                          <IconDelete />
                        </Button>
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                <InfoItem title='Code Invitation' value={detailData?.invitation_code}/>
                <InfoItem title='Code parrain' value={detailData?.code_parrain}/>
                <InfoItem title='Code parent' value={detailData?.code_parent}/>                
              </BlocWithTitle>
              {permContext.market === 'pro' && (
                <BlocWithTitle title="PARENT">
                  <InfoItem title='Parent Id' value={detailData?.parent?.userId}/>
                  <InfoItem title='Parent' value={detailData?.parent?.legal_infos?.legal_name}/>
                  <InfoItem title='Parrain UserId' value={detailData?.parrain_user_id}/>
                </BlocWithTitle>
              )}
            </div>
            {/* Actions */}
            <div className="o-content__detailclient__row__column col-2">
              <div className="o-information-detail__actions">
                <Button btnType="outline" onClick={() => setUpdateInfosOpen(true)}>
                  <IconEdit/>
                  Modifier les infos.
                </Button>
                <Button btnType="outline" onClick={() => setUpdatePhoneOpen(true)}>
                  <IconEdit/>
                  Modifier numéro téléphone
                </Button>
                <Button btnType="outline" onClick={() => setUpdateEmailOpen(true)}>
                  <IconEdit/>
                  Modifier email
                </Button>
                <Button btnType="outline" onClick={() => setUpdateCodePromo(true)}>
                  <IconEdit/>
                  Modifier Code Promo
                </Button>
                <Button btnType="outline" onClick={() => setUpdateTrustLevel(true)}>
                  <IconEdit/>
                  Niveau de confiance
                </Button>
                {permContext.market === 'pro' && (
                  <Button btnType="outline" onClick={() => setUpdateAclRole(true)}>
                    <IconEdit/>
                    Rôle du client
                  </Button>
                )}
                {permContext.market === 'pro' && detailData?.company_type && (
                  <Button btnType="outline" onClick={() => setUpdateLegalInfosOpen(true)}>
                    <IconEdit/>
                    Informations d'Entreprise
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="my-3">
            <b>Filleuls</b>
          </div>
          <div className="o-content__detailclient__row">
            <Table  
              columns={tableGodchildrenHeader}
              tableData={godchildrenTableData}
              status={statusGodchildrenTable}
              setShowModal={setShowModal}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}
              itemPerPage={10}
              contain
            />
          </div>
          <ConfirmationUpdateInfos
            updateInfosOpen={updateInfosOpen}
            setUpdateInfosOpen={setUpdateInfosOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateLegalInfos
            updateLegalInfosOpen={updateLegalInfosOpen}
            setUpdateLegalInfosOpen={setUpdateLegalInfosOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdatePhone
            updatePhoneOpen={updatePhoneOpen}
            setUpdatePhoneOpen={setUpdatePhoneOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateEmail
            updateEmailOpen={updateEmailOpen}
            setUpdateEmailOpen={setUpdateEmailOpen}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateCodePromo
            updateCodePromoOpen={updateCodePromo}
            setUpdateCodePromoOpen={setUpdateCodePromo}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationDeleteCodePromo
            deleteCodePromoOpen={deleteCodePromo}
            setDeleteCodePromoOpen={setDeleteCodePromo}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateTrustLevel
            updateTrustLevelOpen={updateTrustLevel}
            setUpdateTrustLevelOpen={setUpdateTrustLevel}
            userData={detailData}
            refreshData={refreshData}
          />
          <ConfirmationUpdateAclRole
            updateAclRoleOpen={updateAclRole}
            setUpdateAclRoleOpen={setUpdateAclRole}
            userData={detailData}
            refreshData={refreshData}
          />
        </div>
      )}
      </div>
    </div>
  );
}
