import React, { useEffect, useState, useRef } from 'react';
import { useWindowDimensions } from '../../utils/windowDimension';
import '../table/table.scss';
import { Pagination } from '../pagination';
import IconLoading from '../../../src/components/loading/loading.jsx';
import SwitchButton from '../../../src/components/switch/index.jsx';
import { ReactComponent as IconFilter } from '../../assets/icons/_SmallIcons/ic_filter.svg';
import { ReactComponent as IconFiltered } from '../../assets/icons/_SmallIcons/ic_filtered.svg';

const TablePage = ({
  contain,
  columns,
  tableData,
  pageMetaData,
  loadTableData,
  status,
  selectedStatus,
  setShowModal,
  setCurrentItem,
  setSelectedID,
  ContainHeader,
  textLeft,
  itemPerPage,
  SortBy,
  FilterByStatus,
  FilterByPayinReceived,
  ...props
}) => {
  const [perPage, setPerPage] = useState(itemPerPage ? itemPerPage : 100);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(itemPerPage ? itemPerPage : 100);
  const [data, setData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [mobileData, setMobileData] = useState([]);
  const isMobile = useWindowDimensions().width < 650;
  const isTablet = useWindowDimensions().width < 900;
  const [selectedFilter, setSelectedFilter] = useState(null); 

  useEffect(() => {
    loadTableData([startIndex - 1] / perPage, perPage);
  }, [startIndex, perPage, selectedStatus]);

  useEffect(() => {
    setTableCount(pageMetaData.count);
  }, [pageMetaData]);

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          {columns.map((item, index) => (
            <th key={index}>
              <div className={`th-text-icon ${selectedFilter === item && 'selected'}`}>
                {item}
                {item === 'Création' && SortBy && (
                  <SwitchButton
                    onSort={(isDescending) => {
                      SortBy('created', isDescending);
                      setSelectedFilter('Création');
                    }}
                    clicked={selectedFilter === 'Création'}
                    item={item}
                  />
                )}
                {item === 'Mise à jour' && SortBy && (
                  <SwitchButton
                    onSort={(isDescending) => {
                      SortBy('updated', isDescending);
                      setSelectedFilter('Mise à jour');
                    }}
                    clicked={selectedFilter === 'Mise à jour'}
                    item={item}
                  />
                )}
                <div>
                  {selectedStatus !== 'validated' &&
                    selectedStatus !== 'canceled' &&
                    selectedStatus !== 'new' &&
                    item === 'Statut' && FilterByStatus && <StateDropdown />}
                </div>
                <div>{item === 'Virement reçu' && <ReceiverPayinFilter />}</div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  function StateDropdown() {
    const status = [
      'ONBOARDING',
      'ONBOARDING_FINALIZED',
      'ONBOARDING_WAITING_PAYIN',
      'KYC_REVIEWING',
      'KYC_REVIEW_ASK_MORE',
      'KYC_REVIEW_REFUSED',
    ];
    const [isDropdownDisplayed, setIsDropdownDisplayed] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const dropdownRef = useRef(null);

    const handleFilterByStatus = () => {
      if (selectedStatus && FilterByStatus) {
        FilterByStatus(selectedStatus, true);
      }
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownDisplayed(false);
          handleFilterByStatus();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [selectedStatus]);

    return (
      <fieldset className="state-dropdown">
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsDropdownDisplayed((prevState) => !prevState);
          }}
        >
          {selectedStatus ? <IconFiltered /> : <IconFilter />}
        </button>
        {isDropdownDisplayed && (
          <div ref={dropdownRef} className="panel" onClick={(e) => e.stopPropagation()}>
            {status.map((stat) => (
              <fieldset key={stat}>
                <input
                  onChange={(e) => {
                    setSelectedStatus(e.target.checked ? stat : null);
                  }}
                  checked={selectedStatus === stat}
                  id={`input-${stat}`}
                  type="checkbox"
                  className="checkbox"
                />
                <label htmlFor={`input-${stat}`}>{stat}</label>
              </fieldset>
            ))}
          </div>
        )}
      </fieldset>
    );
  }

  function ReceiverPayinFilter() {
    const [receiverPayinFiltered, setReceiverPayinFiltered] = useState(false);

    const handleSortByReceiverPayin = () => {
      FilterByPayinReceived();
      setReceiverPayinFiltered((prev) => !prev);
    };

    return (
      <button className="receiver-payin-button" onClick={handleSortByReceiverPayin}>
        {receiverPayinFiltered ? <IconFiltered /> : <IconFilter />}
      </button>
    );
  }

  useEffect(() => {
    const data = tableData.map((row, index) => {
      let rowData = [];
      for (const key in row) {
        if (key !== 'key' && key !== 'searchData') {
          rowData.push({
            val: row[key],
          });
        }
      }
      return (
        <tr
          key={index}
          onClick={() => {
            setCurrentItem && setCurrentItem(row);
            setSelectedID && setSelectedID(row.key);
            setShowModal(true);
          }}
        >
          {rowData.map((data, index) => (
            <td key={index}>{data.val}</td>
          ))}
        </tr>
      );
    });
    setData(data);
    //Mobile view data of the table
    const mobileData = tableData.map((row, index) => {
      let rowData = [];
      let i = 0;
      for (const key in row) {
        if (key !== 'key' && key !== 'searchData') {
          rowData.push({
            column: columns[i],
            val: row[key],
          });
          i++;
        }
      }
      return (
        <div
          className="o-table-mobile__items"
          key={index}
          onClick={() => {
            setCurrentItem && setCurrentItem(row);
            setSelectedID && setSelectedID(row.key);
            setShowModal(true);
          }}
        >
          {rowData.map((data, index) => (
            <div key={index} className="o-table-mobile__item">
              <div className="o-table-mobile__column">{data.column}</div>
              <div className="o-table-mobile__element">{data.val}</div>
            </div>
          ))}
        </div>
      );
    });
    setMobileData(mobileData);
  }, [tableData, endIndex, columns, setShowModal]); 

  return (
    <div className={!isMobile && contain ? 'o-table-container' : ''}>
      {!contain && <div className="o-table-line" />}
      {/*Condition for desktop and mobile view of the table */}
      {isMobile ? (
        status !== 'loading' ? (
          <div className="o-table-mobile">
            {mobileData.length > 0 ? (
              mobileData
            ) : (
              <div className="text-center my-5">Pas de données</div>
            )}
          </div>
        ) : (
          <div className="d-flex mx-auto mt-4 div-loading">
            <IconLoading size={40} />
          </div>
        )
      ) : (
        <div
          className={`o-table ${props.notClickableRow ? 'cursor-default' : ''} ${
            contain ? 'o-table-contain' : ''
          }  ${textLeft ? 'o-table__left' : ''}`}
        >
          {contain && <ContainHeader />}
          <table>
            {renderTableHeader()}
            <tbody className="mx-auto">{status !== 'loading' && data}</tbody>
          </table>
          {status === 'loading' && (
            <div className="d-flex mx-auto my-4 div-loading">
              <IconLoading size={40} />
            </div>
          )}
          {status !== 'loading' && data.length <= 0 && (
            <div className="text-center my-5">Pas de données</div>
          )}
        </div>
      )}
      <Pagination
        perPage={perPage}
        setPerPage={setPerPage}
        count={tableCount}
        startIndex={startIndex}
        endIndex={endIndex}
        setStartIndex={setStartIndex}
        setEndIndex={setEndIndex}
        sticky={!contain && !isTablet}
      />
    </div>
  );
};

export default TablePage;
