import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { clientBeneficiaryAddReq } from '../../../../../api/client/beneficiary/beneficiary';
import IconLoading from '../../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg'

const ConfirmationAddBeneficiary = ({
  addBeneficiaryOpen,
  setAddBeneficiaryOpen,
  userId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const addHandler = async (id, data) => {
    setLoading(true);
    let status = await clientBeneficiaryAddReq(id, data);
    setLoading(false);
    console.log(status);
    if (status === 'success') {
      setAddBeneficiaryOpen(false);
      refreshData();
    }
  };

  const AddBeneficiaryInitialValues = {};
  const AddBeneficiarySchema = Yup.object().shape({
    name: Yup.string().required('Requis'),
    iban: Yup.string().required('Requis'),
    bic: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={addBeneficiaryOpen} toggle={setAddBeneficiaryOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={AddBeneficiaryInitialValues}
        validationSchema={AddBeneficiarySchema}
        onSubmit={(values) => {
          console.log("Ajout d'un bénéficiaire");
          addHandler(userId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setAddBeneficiaryOpen(false);}}/>              
            <div className="o-modal-info__title-text o-title">
              Pour confirmer l'ajout du beneificiaire, veuillez saisir en majuscule le nom du client
            </div>
            <div className="o-modal-info__fields">
              <Field name="name" label="Nom" placeholder="Nom *" component={InputFormik} />
              <Field name="iban" label="IBAN" placeholder="IBAN *" component={InputFormik} />
              <Field name="bic" label="BIC" placeholder="BIC *" component={InputFormik} />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setAddBeneficiaryOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationAddBeneficiary;
