import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

/**
 * Renders an info item component with customizable title and value.
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the info item.
 * @param {any} props.value - The value of the info
 * @param {string} props.position - The position of the info item.
 * @returns {JSX.Element} The rendered info item component.
 */

export default function InfoItem({ 
    title, 
    value, 
    defaultValue = '-', 
    position = 'start', align = 'left',
    children, ...props}) {
    return (
        <div
            className={`infoItem__container ${position === "end" ? "end" : ""} ${props?.className}`}
        >
            { title && (
                <div className="infoItem__title">{title}</div>            
            )}
            <div className={`infoItem__value ${align === "right" ? "right" : ""}`}>
                {children ? (
                    <>{children}</>
                ) : (
                    <>{value ? value : (defaultValue ? defaultValue : '-')}</>
                )}
            </div>
        </div>
    );
}

InfoItem.propTypes = {
    title: PropTypes.string.isRequired,
    position: PropTypes.oneOf(["start", "end"]),
    align: PropTypes.oneOf(["left", "right"]),
};
