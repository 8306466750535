import React from 'react';

export function IconPermission(props) {
  return (
    <div className="o-sidebar-icon">
      <svg width={16} height={17} viewBox="0 0 12 17" {...props}>
        <path
          fill="currentColor"
          d="M11.034 2.1h-2.91a1.736 1.736 0 00.02-.263 1.839 1.839 0 00-3.678 0 1.736 1.736 0 00.02.263h-2.91A1.577 1.577 0 000 3.678v11.559a1.577 1.577 0 001.576 1.576h9.457a1.577 1.577 0 001.576-1.576V3.678A1.577 1.577 0 0011.034 2.1zM6.3 1.051a.788.788 0 11-.788.788.786.786 0 01.788-.788zm5.254 14.186a.527.527 0 01-.525.525H1.576a.527.527 0 01-.525-.525V3.678a.527.527 0 01.525-.525h1.576v.657a.4.4 0 00.394.394h5.517a.4.4 0 00.394-.394v-.658h1.576a.527.527 0 01.525.525zM9.625 7.474a.4.4 0 00-.558 0L5.2 11.306 3.547 9.645a.394.394 0 00-.558 0l-.279.279a.394.394 0 000 .558L4.922 12.7a.394.394 0 00.558 0l4.427-4.389a.4.4 0 000-.558l-.286-.279z"
        />
      </svg>
    </div>
  );
}
export function IconGroup(props) {
  return (
    <div className="o-sidebar-icon">
      <svg width={16} height={11} viewBox="0 0 16 11" {...props}>
        <path
          fill="currentColor"
          d="M13.57 4.789a2 2 0 10-2-2 2 2 0 002 2zm0-3.193a1.2 1.2 0 11-1.2 1.2 1.2 1.2 0 011.2-1.196zM7.982 5.588a2.794 2.794 0 10-2.794-2.794 2.792 2.792 0 002.794 2.794zm0-4.789a2 2 0 11-2 2 2 2 0 012-1.999zm6.087 4.789h-1a1.8 1.8 0 00-1.025.322 3.381 3.381 0 01.634.559.992.992 0 01.392-.082h1a1.153 1.153 0 011.1 1.2.4.4 0 10.8 0 1.95 1.95 0 00-1.901-1.999zm-11.674-.8a2 2 0 10-2-2 2 2 0 002 2.001zm0-3.193a1.2 1.2 0 11-1.2 1.2 1.2 1.2 0 011.2-1.195zm7.586 4.49c-.833 0-1.04.3-2 .3s-1.165-.3-2-.3a2.749 2.749 0 00-2.3 1.17 2.858 2.858 0 00-.489 1.6v1.118a1.2 1.2 0 001.2 1.2h7.184a1.2 1.2 0 001.2-1.2V8.86a2.858 2.858 0 00-.489-1.6A2.749 2.749 0 009.98 6.087zm1.993 3.891a.4.4 0 01-.4.4H4.39a.4.4 0 01-.4-.4V8.86a2.072 2.072 0 01.353-1.16 1.952 1.952 0 011.639-.818c.683 0 .928.3 2 .3s1.317-.3 2-.3a1.952 1.952 0 011.639.818 2.072 2.072 0 01.352 1.157v1.118zM3.919 5.909a1.817 1.817 0 00-1.025-.322h-1a1.95 1.95 0 00-1.9 2 .4.4 0 10.8 0 1.153 1.153 0 011.1-1.2h1a.992.992 0 01.392.082 3.461 3.461 0 01.633-.56z"
        />
      </svg>
    </div>
  );
}
export function IconUser(props) {
  return (
    <div className="o-sidebar-icon">
      <svg width={16} height={13} viewBox="0 0 11 13" {...props}>
        <path
          fill="currentColor"
          d="M8.241 7.569c-.754 0-1.117.42-2.355.42s-1.6-.42-2.355-.42A3.533 3.533 0 000 11.1v1.093a1.262 1.262 0 001.261 1.261h9.251a1.262 1.262 0 001.261-1.261V11.1a3.533 3.533 0 00-3.532-3.531zm2.691 4.625a.422.422 0 01-.42.42H1.261a.422.422 0 01-.42-.42V11.1a2.693 2.693 0 012.691-2.69 12.551 12.551 0 002.355.42 12.6 12.6 0 002.355-.42 2.693 2.693 0 012.69 2.69zM5.887 6.728a3.364 3.364 0 10-3.364-3.364 3.364 3.364 0 003.364 3.364zm0-5.887a2.523 2.523 0 11-2.523 2.523A2.527 2.527 0 015.887.841z"
        />
      </svg>
    </div>
  );
}
export function IconDocument(props) {
  return (
    <div className="o-sidebar-icon">
      <svg width={16} height={15} viewBox="0 0 11.303 15" {...props}>
        <path
          fill="currentColor"
          d="M10.888 2.885L8.419.415a1.413 1.413 0 00-1-.415H1.413A1.418 1.418 0 000 1.416v12.245a1.413 1.413 0 001.413 1.413H9.89a1.413 1.413 0 001.41-1.413V3.886a1.42 1.42 0 00-.412-1.001zm-.665.668a.463.463 0 01.124.218H7.536V.96a.463.463 0 01.218.124zM9.89 14.132H1.413a.472.472 0 01-.471-.471V1.416a.472.472 0 01.471-.471h5.181v3.061a.7.7 0 00.706.706h3.061v8.948a.472.472 0 01-.471.472zM8.477 6.95v.235a.354.354 0 01-.353.353H3.179a.354.354 0 01-.353-.353V6.95a.354.354 0 01.353-.35h4.945a.354.354 0 01.353.35zm0 1.884v.235a.354.354 0 01-.353.353H3.179a.354.354 0 01-.353-.353v-.235a.354.354 0 01.353-.353h4.945a.354.354 0 01.353.353zm0 1.884v.235a.354.354 0 01-.353.353H3.179a.354.354 0 01-.353-.353v-.235a.354.354 0 01.353-.353h4.945a.354.354 0 01.353.353z"
        />
      </svg>
    </div>
  );
}
export function IconPackage(props) {
  return (
    <div className="o-sidebar-icon">
      <svg width={16} height={13} {...props}>
        <path
          fill="currentColor"
          d="M13.732 0H1.421A1.421 1.421 0 000 1.421v1.894a.475.475 0 00.474.474h.473v8.05a1.421 1.421 0 001.421 1.421h10.417a1.421 1.421 0 001.421-1.421v-8.05h.474a.475.475 0 00.474-.474V1.421A1.421 1.421 0 0013.732 0zm-.474 11.838a.475.475 0 01-.474.474H2.368a.475.475 0 01-.474-.474v-8.05h11.365zm.947-9H.947V1.421a.475.475 0 01.474-.474h12.311a.475.475 0 01.474.474zM6.037 6.629h3.078a.356.356 0 00.355-.355v-.237a.356.356 0 00-.355-.355H6.037a.356.356 0 00-.355.355v.237a.356.356 0 00.355.355z"
        />
      </svg>
    </div>
  );
}
export function IconTypes(props) {
  return (
    <div className="o-sidebar-icon">
      <svg width={13} height={17} viewBox="0 0 13 17" {...props}>
        <path
          d="M9.142 7.836H5.485a.262.262 0 00-.261.261v.523a.262.262 0 00.261.261h3.657A.262.262 0 009.4 8.62V8.1a.262.262 0 00-.258-.264zm0 3.135H5.485a.262.262 0 00-.261.261v.522a.262.262 0 00.261.261h3.657a.262.262 0 00.261-.261v-.522a.262.262 0 00-.261-.261zm-5.485-3.4a.784.784 0 10.784.784.782.782 0 00-.784-.78zm0 3.135a.784.784 0 10.784.784.782.782 0 00-.784-.78zm7.314-8.62H8.078a1.726 1.726 0 00.02-.261 1.828 1.828 0 00-3.657 0 1.726 1.726 0 00.02.261H1.567A1.568 1.568 0 000 3.657V15.15a1.568 1.568 0 001.567 1.567h9.4a1.568 1.568 0 001.567-1.567V3.657a1.568 1.568 0 00-1.563-1.567zm-4.7-1.045a.784.784 0 11-.784.784.782.782 0 01.782-.78zm5.222 14.109a.524.524 0 01-.522.522h-9.4a.524.524 0 01-.522-.522V3.657a.524.524 0 01.522-.522h1.564v.653a.393.393 0 00.392.392h5.485a.393.393 0 00.388-.392v-.653h1.567a.524.524 0 01.522.522z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
}
export function IconMeter(props) {
  return (
    <div className="o-sidebar-icon">
    <svg width="25" height="25" viewBox="0 0 40 40" fill="none" {...props}>
    <path d="M25.653 16.3472C26.1157 16.8099 26.1157 17.5602 25.653 18.0229L21.7904 21.8854C22.1084 22.4717 22.2894 23.1429 22.2894 23.8553C22.2894 26.1408 20.43 28 18.1447 28C15.8592 28 14 26.1408 14 23.8553C14 21.57 15.8592 19.7106 18.1447 19.7106C18.8573 19.7106 19.5283 19.8915 20.1145 20.2096L23.9771 16.347C24.44 15.8843 25.1901 15.8843 25.653 16.3472ZM19.9194 23.8555C19.9194 22.877 19.1233 22.0808 18.1447 22.0808C17.1662 22.0808 16.37 22.877 16.37 23.8555C16.37 24.8341 17.1662 25.6302 18.1447 25.6302C19.1233 25.6302 19.9194 24.8341 19.9194 23.8555Z" fill="#00DB9B"/>
    <path fill="currentColor" d="M36 22.9161C36 26.4096 34.8828 29.7257 32.7693 32.5065C32.3523 33.0554 31.5681 33.1618 31.0183 32.748C30.4678 32.3335 30.3591 31.5533 30.7754 31.0054C32.5579 28.6605 33.5 25.8631 33.5 22.9161C33.5 15.4904 27.4573 9.48803 20 9.48803C12.5376 9.48803 6.5 15.495 6.5 22.9161C6.5 25.8631 7.44214 28.6605 9.22437 31.0054C9.64087 31.5533 9.53223 32.3335 8.98169 32.748C8.43091 33.1625 7.64722 33.0544 7.23047 32.5065C5.11719 29.7257 4 26.4096 4 22.9161C4 14.1147 11.1611 7 20 7C28.8438 7 36 14.1196 36 22.9161Z"/>
    </svg>
    </div>
  );
}
export function IconChartBars(props) {
  return (
    <div className="o-sidebar-icon">
    <svg width={25} height={25} viewBox="0 0 40 40" fill="none" {...props}>
    <path fill="currentColor" d="M9 9C8.44772 9 8 9.44772 8 10V29C8 29.5523 8.44772 30 9 30H11H13C13.5523 30 14 29.5523 14 29V10C14 9.44772 13.5523 9 13 9H9ZM11 30C10.4477 30 10 29.5523 10 29V12C10 11.4477 10.4477 11 11 11C11.5523 11 12 11.4477 12 12V29C12 29.5523 11.5523 30 11 30Z"/>
    <path fill="currentColor" d="M18 16C17.4477 16 17 16.4477 17 17V29C17 29.5523 17.4477 30 18 30H20H22C22.5523 30 23 29.5523 23 29V17C23 16.4477 22.5523 16 22 16H18ZM20 30C19.4477 30 19 29.5523 19 29V19C19 18.4477 19.4477 18 20 18C20.5523 18 21 18.4477 21 19V29C21 29.5523 20.5523 30 20 30Z"/>
    <path fill="#00DB9B" d="M26 22C26 21.4477 26.4477 21 27 21H31C31.5523 21 32 21.4477 32 22V29C32 29.5523 31.5523 30 31 30H27C26.4477 30 26 29.5523 26 29V22Z"/>
    <path fill="currentColor" d="M5 30C5 29.4477 5.44772 29 6 29H34C34.5523 29 35 29.4477 35 30C35 30.5523 34.5523 31 34 31H6C5.44772 31 5 30.5523 5 30Z"/>
    </svg>
    </div>
  );
}

