import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Request
import { handleRib, generateRibReq } from '../../../../../api/client/wallet/rib';
import { clientWalletReq, clientWalletTransactionPageReq } from '../../../../../api/client/wallet';
import { walletLineDetailReq } from '../../../../../api/client/walletline/walletline';

// UI
import "../detailclient.scss";
import Head from '../../../../../components/head';
import TablePage from '../../../../../components/tablepage';
import Button from '../../../../../components/button';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';
import InfoItem from '../../../../../components/infoItem/index.jsx';
import TransactionCard from '../../../../../components/transaction';
import Logo from '../../../../../components/logo';

import { ArrowRight } from '../../../../../assets/icons/icons-arrow';
import { ReactComponent as IconDoc } from '../../../../../assets/icons/component/ic_doc.svg';
import IconLoading from '../../../../../components/loading/loading.jsx';

import useFormattedAmount from '../../../../../hooks/useFormattedAmount.js';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

const ClientV2WalletDetail = () => {
  const { id, walletId } = useParams();
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ribLoading, setRibLoading] = useState(false);
  const [generateRibLoading, setGenerateRibLoading] = useState(false);

  const [allTransactions, setAllTransactions] = useState([]);
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [transactionTableStatus, setTransactionTableStatus] = useState('loading');
  const [transactionPageMetaData, setTransactionPageMetaData] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState();
  const [selectedTransaction, setSelectedTransaction] = useState();

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const tableTransactionsHeader = ['Date de l’opération', 'Libellé', 'Type','Transaction Type','Statut', 'Montant Total', 'Arrondi', 'Transaction'];
  const renderTransactionsTable = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item.walletLineId,
        date: moment(item.operation_date).format('DD/MM/YYYY'),
        label: item.label,
        type: item.type + (item.object?.mcc_code === '6011' ? '(ATM)' : ''),
        objectType: item.objectType,
        status: item.status,
        amount: (item.type === 'DEBIT' ? '-' : '') + item.amount + ' €', // Corrected error : Expected '===' and instead saw '=='
        roundAmount: '-' + (item.roundAmount ? item.roundAmount : '0.0') + ' €', 
        transactionAmount: (item.type === 'DEBIT' ? '-' : '') + (item.transactionAmount ? item.transactionAmount : item.amount) + ' €', // MODIF : Expected '===' and instead saw '=='
      }),
    );
    return data;
  };

  const refreshTransactionList = (pageNo, pageSize) => {
    setTransactionTableStatus('loading');
    clientWalletTransactionPageReq(id, walletId, pageNo, pageSize)
      .then((res) => {
        setAllTransactions(res.content);
        setTransactionTableData(renderTransactionsTable(res.content));
        setTransactionPageMetaData({ count: res.totalElements });
      })
      .finally(() => {
        setTransactionTableStatus('done');
        setSelectedTransactionId(null);
        setSelectedTransaction(null);
        setSelectedID(null);
        setSelectedItem(null);
      });
  };

  useEffect(() => {
    clientWalletReq(id, walletId).then((res) => {
      setTableData(res);
    });
  }, []);

  useEffect(() => {
    const transaction = allTransactions.find((x) => x.walletLineId === selectedTransactionId);
    // On récupère les informations de la transaction
    if (selectedTransactionId) {
      walletLineDetailReq(selectedTransactionId).then((res) => {
        setSelectedTransaction(res);
        setSelectedItem('transaction');
      });
    }
  }, [selectedTransactionId]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}/wallets/list`}>Liste Wallets&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails wallet
        </div>
      </Fragment>
    );
  }

  const downloadRib = async (walletId) => {
    if (walletId) {
      setRibLoading(true);
      const res = await handleRib(walletId);
      setRibLoading(false);
    }
  };

  const generateRib = async (walletId) => {
    if (walletId) {
      setGenerateRibLoading(true);
      const res = await generateRibReq(walletId);
      setGenerateRibLoading(false);
    }
  };

  return (
    <div>
      <Head headTitle={headTitle()} isAdd={false} disabledSearch />
      <div className="o-content__detailclient">
        <div className="o-content__detailclient__row">
          <div className="o-content__detailclient__row__column col-5">
            <div className="o-information--block">
              <InfoItem title='Wallet ID | TrzWalletId'>{tableData?.walletId} | {tableData?.trzWalletId}</InfoItem>
              <InfoItem title='Type' value={tableData?.type}/>
              <InfoItem title='Nom' value={tableData?.name}/>
              <InfoItem title='Alias' value={tableData?.alias}/>
              <InfoItem title='IBAN' value={tableData?.iban}/>
              <InfoItem title='BIC' value={tableData?.bic}/>
            </div>
          </div>
          <div className="o-content__detailclient__row__column col-5">
            <div className="o-information--block">
              <InfoItem title='Solde' value={useFormattedAmount(tableData?.solde)}/>
              <InfoItem title='Authorized balance' value={useFormattedAmount(tableData?.authorized_balance)}/>
              <InfoItem title='Activé' value={tableData?.bic}/>
              <InfoItem title='Statut' value={tableData?.bic}/>
              <InfoItem title='Devise' value={tableData?.bic}/>
            </div>
            <div className="d-flex">
                <b>
                  Historique de balance
                  <a
                    href={`/dashboard/client/${id}/wallets/${walletId}/balance/history`}
                    className="pl-2"
                  >
                    Voir détail <ArrowRight />
                  </a>
                </b>
              </div>
          </div>
          <div className="o-content__detailclient__row__column col-2">
            <div className="o-information-detail__actions">
              <b>&nbsp;</b>
              <Button btnType="outline" onClick={() => downloadRib(tableData?.walletId)}>
                {ribLoading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  <IconDoc/>
                )}
                Télécharger le RIB
              </Button>
              <b>&nbsp;</b>
              <Button btnType="outline" onClick={() => generateRib(tableData?.walletId)}>
                {' '}
                {generateRibLoading ? (
                  <IconLoading />
                ) : (
                  <IconDoc/>
                )}
                Regénérer le RIB
              </Button>
            </div>
          </div>
        </div>
        <div className='o-content__detailclient__row mt-1'>
          <div className='o-content__detailclient__row__column col-9'>
              <TablePage
                  columns={tableTransactionsHeader}
                  tableData={transactionTableData}
                  pageMetaData={transactionPageMetaData}
                  loadTableData={refreshTransactionList}
                  status={transactionTableStatus}
                  setShowModal={setShowModal}
                  setSelectedID={setSelectedTransactionId}
                  ContainHeader={() => ''}
                  itemPerPage={10}
                  contain
                />
          </div>
          <div className='o-content__detailclient__row__column col-3'>
            {selectedItem === 'transaction' && selectedTransactionId && selectedTransaction && (
              <BlocWithTitle title=''>
                <TransactionCard
                  transaction={selectedTransaction}
                  userId={id}
                  refreshData={() => {}}
                />
              </BlocWithTitle>
            )}
            {!selectedID && <Logo className="o-viewer-logo" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientV2WalletDetail;
