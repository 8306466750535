import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Styles
import './detailclient.scss'
// Requêtes
import {
  detailClientReq,
} from '../../../../api/client/detailClient.jsx';

// UI
import ClientDetailFilterTabs from './client_filter_tabs.jsx';
import {ReactComponent as IconTrashError } from '../../../../assets/icons/component/ic_delete_error.svg'
import {ReactComponent as IconBanError } from '../../../../assets/icons/component/ic_ban_error.svg'
import {ReactComponent as IconCheck } from '../../../../assets/icons/component/ic_check.svg'

import Head from '../../../../components/head/index.jsx';
import Button from '../../../../components/button/index.jsx';
import ClientDetailV2Header from './client_header.jsx';

// Context
import PermissionsContext from '../../../../contexts/permissionContext.js';

// Modals
import ConfirmationLock from '../modals/confirmationLock.jsx';
import ConfirmationDeleteInfo from '../modals/confirmationDelete.jsx';

import PreviewInfos from './preview/preview_infos.jsx';
import PreviewMonitoring from './preview/preview_monitoring.jsx';
import PreviewWallet from './preview/preview_wallet.jsx';
import PreviewKycKyb from './preview/preview_kyc_kyb.jsx';

function ClientDetailV2() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [deleteInfoOpen, setDeleteInfoOpen] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');
  const requestSuffix = 'status=' + status;

  useEffect(() => {
    refreshData(false);
  }, []); // manque 'id' & 'refreshData'

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${id}/wallet/${selectedID}`;
  }, [showModal]); // manque 'id' & 'refreshID'

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={status ? '/dashboard/clientbystatus/' + status : '/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client          
        </div>
      </Fragment>
    );
  }

  function rightAction(){
    return (
      <Fragment>
          {permContext?.clientUpdate ? (
          <>
            {permContext?.clientDelete && (
              <Button
                btnType="outline"
                className="warning"
                onClick={() => setDeleteInfoOpen(true)}
              >
                <IconTrashError/>
                Suppr. infos client
              </Button>
            )}
            {detailData?.blocked ? (
              <>
                {permContext?.clientUpdate && (
                  <Button btnType="outline" onClick={() => setLockModal(true)}>
                    <IconCheck />
                    Débloquer
                  </Button>
                )}
              </>
            ) : (
              <>
                {permContext?.clientUpdate && (
                  <Button btnType="outline" className="warning" onClick={() => setLockModal(true)}>
                    <IconBanError />
                    Bloquer
                  </Button>
                )}
              </>
            )}
          </>
          ) : (
          <div>&nbsp;</div>
          )}
          <ConfirmationLock
            lockModal={lockModal}
            setLockModal={setLockModal}
            userId={detailData?.userId}
            blocked={detailData?.blocked}
            toggleBlockedData={toggleBlockedData}
          />
          <ConfirmationDeleteInfo
            deleteInfoOpen={deleteInfoOpen}
            setDeleteInfoOpen={setDeleteInfoOpen}
            userId={detailData?.userId}
            refreshData={refreshData}
          />
      </Fragment>
    );
  }

  const toggleBlockedData = () => {
    setDetailData({
      ...detailData,
      blocked: !detailData.blocked,
    });
  };

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
    })
    .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData} rightFragment={rightAction()}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className='o-content__detailclient'>
          <div className='o-content__detailclient__row'>
          <div className='o-content__detailclient__row__column col-9'>
            <PreviewInfos detailData={detailData} />
            <PreviewMonitoring detailData={detailData} />
          </div>
          <div className='o-content__detailclient__row__column col-3'>
            { detailData?.userStatus != 'VALIDATED' && (
              <PreviewKycKyb detailData={detailData}/>
            )}
            <PreviewWallet detailData={detailData} />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2;
