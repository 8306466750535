import React, { useState, useContext, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import PermissionsContext from '../../../../../contexts/permissionContext.js';

// UI
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';
import InfoItem from '../../../../../components/infoItem/index.jsx';
import Button from '../../../../../components/button/index.jsx';
import { ReactComponent as IconEye } from '../../../../../assets/icons/component/ic_see.svg';

function PreviewKycKyb({ detailData,  ...props}) {

  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);
  
  useEffect(() => {
    refreshData(detailData);
  }, [detailData]); 

  const refreshData = (detailData) => {
    setLoading(true);

  };

  return (
    <BlocWithTitle>
      <InfoItem title='Status' value={detailData?.userStatus} />
      <InfoItem title='KYC Level' value={detailData?.kycLevel} />
      <InfoItem title='KYC Review' value={detailData?.kycReview} />
      <InfoItem title='KYC Review Comment' value={detailData?.kycReviewComment} />
      <InfoItem title='Freezed' value={detailData?.isFreezed ? 'Oui' : 'Non'}/>
      <Button onClick={() => window.location.href = '/dashboard/client/' + detailData?.userId + '/security/kyc_kyb'}>
        <IconEye/>Voir le détail</Button>
    </BlocWithTitle>
  );
}

export default PreviewKycKyb;
