import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { clientAskUrlKycLivenessWithMailReq } from '../../../../../api/client/kycliveness';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationAskKycLivenessWithMail = ({
  askKycLivenessWithMailOpen,
  setAskKycLivenessWithMailOpen,
  userId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState();
  const { t } = useTranslation();
  const askKycLivenessUrlHandler = async (id) => {
    setLoading(true);
    let responseData = await clientAskUrlKycLivenessWithMailReq(id);
    setLoading(false);
    console.log(responseData);
    if (responseData) {
      refreshData();
      setDetailData(responseData);
    }
  };

  const AskKycLivenessWithMailInitialValues = {};
  const AskKycLivenessWithMailSchema = Yup.object().shape({});

  return (
    <Modal isOpen={askKycLivenessWithMailOpen} toggle={setAskKycLivenessWithMailOpen}>
      {detailData ? (
        <div className="o-modal-info">
          <div className="o-modal-info__title-text">
            <div className="o-title">URL KYC Liveness</div>
            <div className="o-modal-info__title-text__text">
              Un mail contenant l'URL de KYCLiveness a été envoyé !
            </div>
          </div>
          <Button
            btnType="outline"
            onClick={() => {
              setDetailData(null);
              setAskKycLivenessWithMailOpen(false);
            }}
          >
            Fermer
          </Button>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={AskKycLivenessWithMailInitialValues}
          validationSchema={AskKycLivenessWithMailSchema}
          onSubmit={(values) => {
            console.log("Récupération d'une URL de KycLiveness avec envoi d'un mail");
            askKycLivenessUrlHandler(userId);
          }}
        >
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setAskKycLivenessWithMailOpen(false);}}/>
              <div className="o-modal-info__title-text o-title">
                Confirmez-vous que vous voulez envoyer un mail avec une nouvelle URL de KYCLiveness
              </div>
              <div className="o-btn-group">
                <Button type="submit">
                  {loading ? (
                    <div className="d-flex mx-auto">
                      <IconLoading />
                    </div>
                  ) : (
                    t('global:confirm')
                  )}
                </Button>
                <Button
                  className="cancel" btnType="outline"
                  onClick={() => setAskKycLivenessWithMailOpen(false)}
                >
                  {t('global:cancel')}
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};

export default ConfirmationAskKycLivenessWithMail;
