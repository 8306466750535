import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

/**
 * Banner component
 * @param {Object} props - Component props
 * @param {String} props.text - Banner text
 * @param {ReactNode} props.children - The content of the bloc.
 * @returns {JSX.Element}
 * **/

export default function BlocWithTitle({
    title,
    children,
    ...props
}) {
    return (
        <div className={'blocWithTitle__container ' + (props.className ? props.className : '')}>
            {title && (
                <div className="blocWithTitle__container__title">
                    <span className="blocWithTitle__container__title_text">{title}</span>
                </div>
            ) }
            {children}
        </div>
    );
}

BlocWithTitle.propTypes = {
    // title: PropTypes.string.isRequired,
};
