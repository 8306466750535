import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { PackageFeatureSchema, PackageFeatureInitialValues } from '../packageFeature.utils';
import { addPackageFeatureReq } from '../../../../api/packageFeature/addPackageFeature';
import { detailPackageFeatureReq } from '../../../../api/packageFeature/detailPackageFeature';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';


const AddPackageFeature = ({ addModal, setAddModal, selectedID, config }) => {
  const { t } = useTranslation();
  const [dataStatus, setDataStatus] = useState('done');
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    if (selectedID) {
      setDataStatus('loading');
      detailPackageFeatureReq(selectedID)
        .then((res) => {
          setCurrentItem({
            name: res.name,
            type: config.featureTypes.filter((e) => e.value === res.type)[0],
            domain: config.featureDomains.filter((e) => e.value === res.domain)[0],
          });
        })
        .finally(() => setDataStatus('done'));
    }
  }, [selectedID, config]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? currentItem : PackageFeatureInitialValues}
        validationSchema={PackageFeatureSchema}
        onSubmit={(values) => {
          addPackageFeatureReq(
            selectedID
              ? {
                  featureId: selectedID,
                  name: values.name,
                  type: values.type.value,
                  domain: values.domain.value,
                }
              : {
                  name: values.name,
                  type: values.type.value,
                  domain: values.domain.value,
                },
          ).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard/admin/package-features';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setAddModal(false);}}/>
              <div className="o-modal-info__title-text o-title text-center">
                {selectedID ? t('feature:modify_feature') : t('feature:new_feature')}
              </div>
              {dataStatus === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <>
                  <div className="o-modal-info__fields">
                    <Field
                      name="name"
                      label={t('feature:name')}
                      placeholder={t('feature:name_placeholder') + ' *'}
                      component={InputFormik}
                    />
                    <Field
                      name="type"
                      label={t('feature:type')}
                      placeholder={t('feature:type_placeholder') + ' *'}
                      component={Dropdown}
                      options={config.featureTypes && config.featureTypes}
                    />
                    <Field
                      name="domain"
                      label={t('feature:domain')}
                      placeholder={t('feature:domain_placeholder') + ' *'}
                      component={Dropdown}
                      options={config.featureDomains && config.featureDomains}
                    />
                  </div>
                  <div className="o-btn-group">
                    <Button type="submit" disabled={!(props.isValid && props.dirty)}>
                      {selectedID ? t('global:validate') : t('global:add')}
                    </Button>
                    <Button
                      className="cancel" btnType="outline"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      {t('global:cancel')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPackageFeature;
