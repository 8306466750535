import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PermissionsContext from '../../../../../contexts/permissionContext';

// Requêtes
import {
  clientNotesReq,
} from '../../../../../api/client/detailClient.jsx'; 
import { 
  searchPayinAlertPageReq,
} from '../../../../../api/monitoring/monitoring.jsx';

import './preview_monitoring.scss';

import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx'
import InfoItem from '../../../../../components/infoItem/index.jsx';
import PreviewAlertItem from './preview_alert_item.jsx';
import PreviewNoteItem from './preview_note_item.jsx';

function PreviewMonitoring({ detailData,  ...props }) {
  const [userNotes, setUserNotes] = useState([]);
  const [userAlerts, setUserAlerts] = useState([]);
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);
  
  useEffect(() => {
    refreshData(detailData);
  }, [detailData]); 

  const refreshData = (detailData) => {
    setLoading(true);

    if (detailData?.userId) {
      clientNotesReq(detailData?.userId)
        .then((res) => setUserNotes(res))
        .finally(() => setLoading(false));

      var data = {
        user_id: detailData?.userId,
        };
      searchPayinAlertPageReq(data, null, 3)
        .then((res) => setUserAlerts(res.content))
        .finally(() => setLoading(false));

    }
  };

  return (
    <BlocWithTitle title='Surveillance' {...props}>
      <InfoItem className="trustlevel" title='Niveau de confiance' value={detailData?.trust_level?.trust_level_id + ' - ' + detailData?.trust_level?.description + ' (Alerte > ' + detailData?.trust_level?.alert_payin_limit + ' €)'} align="right"/>
      <div className='o-preview__monitoring_subTitle'>
        <div>ALERTES</div>
      { userAlerts.length > 1 && (
        <div>
        <NavLink to={'/dashboard/client/' + detailData?.userId + '/security/monitoring'}>Voir toutes les alertes</NavLink>
        </div>
      )}
      </div>
      {userAlerts?.slice(0, 1).map((alert) => (
        <>
        <PreviewAlertItem alert={alert}/>
        </>
      ))}      

      <div className='o-preview__monitoring_subTitle'>
        <div>DERNIERS COMMENTAIRES</div>
      { userNotes.length > 3 && (
        <NavLink to={'/dashboard/client/' + detailData?.userId + '/security/monitoring'}>Voir tous les commentaires</NavLink>
      )}
      </div>
      {userNotes?.slice(0, 3).map((note) => (
        <>
          <PreviewNoteItem note={note}/>
        </>
      ))}
    </BlocWithTitle>
  );
}

export default PreviewMonitoring;
