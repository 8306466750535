import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import '../../../../components/modal/modal.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { updateClientCodePromo } from '../../../../api/client/updateClient';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg'

const ConfirmationUpdateCodePromo = ({
  updateCodePromoOpen,
  setUpdateCodePromoOpen,
  userData,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const updateHandler = async (id, formData) => {
    setLoading(true);
    let status = await updateClientCodePromo(id, formData);
    setLoading(false);
    setUpdateCodePromoOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const UpdateCodePromoInitialValues = (data) => {
    return {
      code: data ? data.code_promo : '',
    };
  };
  const UpdateCodePromoSchema = Yup.object().shape({
    code: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateCodePromoOpen} toggle={setUpdateCodePromoOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateCodePromoInitialValues(userData)}
        validationSchema={UpdateCodePromoSchema}
        onSubmit={(values) => {
          console.log('Mise à jour du code promo');
          updateHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdateCodePromoOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setUpdateCodePromoOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Modification du code promotionnel
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="code"
                label="Code promo"
                placeholder="Code promo"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button className="confirm-infos" type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateCodePromo;
