import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Request
import {
  detailClientReq,
} from '../../../../../api/client/detailClient.jsx';
import { clientBillingHistoryReq } from '../../../../../api/client/billing.jsx';

// UI
import "../detailclient.scss";
import Table from '../../../../../components/table/index.jsx';
import Button from '../../../../../components/button/index.jsx';
import { ButtonTable } from '../../../../../components/button/index.jsx';
import { ReactComponent as IconCheck } from '../../../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconCoin } from '../../../../../assets/icons/component/ic_coin.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import Head from '../../../../../components/head/index.jsx';

// Modals
import ConfirmationCollectBillingsHistory from '../../modals/billings/confirmationCollectBillingsHistory.jsx';
import ConfirmationDeleteBillingHistory from '../../modals/billings/confirmationDeleteBillingHistory.jsx';
import ConfirmationCreateFutureFees from '../../modals/billings/confirmationCreateFutureFees.jsx';
import RefundClientModal from '../../modals/billings/refundClientModal.jsx';
import ConfirmationCollectFees from '../../modals/billings/confirmationCollectFees.jsx';

import useFormattedAmount from "../../../../../hooks/useFormattedAmount";

import PermissionsContext from '../../../../../contexts/permissionContext.js';

import ClientDetailV2Header from '../client_header.jsx';

function ClientDetailV2Billing() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);

  // Table data
  const [showModal, setShowModal] = useState();
  const [loadingBillingsHistory, setLoadingBillingsHistory] = useState('loading');
  const [billingsHistoryDetailTableData, setBillingsHistoryTableData] = useState([]);
  const [allBillingsHistory, setAllBillingsHistory] = useState([]);
  const [selectedBillingsHistory, setSelectedBillingsHistory] = useState();
  const [selectedID, setSelectedID] = useState();
  
  // Modal 
  const [collectBillingsHistoryOpen, setCollectBillingsHistoryOpen] = useState(false);
  const [deleteBillingHistoryOpen, setDeleteBillingHistoryOpen] = useState(false);
  const [createFutureFeesOpen, setCreateFutureFeesOpen] = useState(false);
  const [collectFeesOpen, setCollectFeesOpen] = useState(false);
  const [clientRefundOpen, setClientRefundOpen] = useState(false);
  

  const { permContext } = useContext(PermissionsContext);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');
  const requestSuffix = 'status=' + status;

  // Table renderer
  function renderFeature(item){
    return <div><div>{ (item.feature ? item.feature.name : '-') }</div>
          <div>{ (item.transfer ? '"' + item.transfer.label + '"' : '') }</div>
          </div>;
  }

  const tableBillingsHistoryHeader = ['ID', 'Date', 'Item','Montant HT', 'TVA', 'Montant TTC', 'Facturé (O/N)', 'Action'];

  const renderBillingsHistoryTable = (res, setDeleteModal, setCurrentItem) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item.user_feature_history_id,
        user_feature_history_id: item.user_feature_history_id,
        date : item.date? moment(item.date).format('MMMM YYYY') : '-',
        feature: renderFeature(item) ,
        amount: item.amount ? item.amount.toFixed(2) + '€' : 'Gratuit',
        vat: (item.vat? Math.round(item.vat.rate*100) :'-') + '%',
        amount_ttc: (item.amount ? (item.amount*(1+item.vat.rate)).toFixed(2) + '€' : 'Gratuit'),
        billed: (item.transfer || item.amount === 0) ? 'Oui' : <div className="o-red">Non</div>,
        delete: (item.transfer || item.amount === 0) ? ('-') : (<ButtonTable
            action="delete"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModal(true);
              setCurrentItem(item);
            }}
          />),
      }),
    );
    return data;
  };

  const renderSelectedBillingsHistoryActions = (selectedBillingsHistory) => {
    return (
      <div className="o-information-detail__actions">
        {selectedBillingsHistory?.transfer ? (
          ''
        ) : (
          <Button btnType="outline" onClick={() => setCollectBillingsHistoryOpen(true)}>
            <IconCheck/>
            Collecter la facturation
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={status ? '/dashboard/clientbystatus/' + status : '/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force)
      .then((res) => {
        setDetailData(res);      
      })
      .finally(() => setLoading(false));

    setLoadingBillingsHistory('loading');
    clientBillingHistoryReq(id)
      .then((res) => {
        setAllBillingsHistory(res);
        setBillingsHistoryTableData(
          renderBillingsHistoryTable(res, setDeleteBillingHistoryOpen, setSelectedBillingsHistory),
        );
      })
      .finally(() => setLoadingBillingsHistory('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
              <b>Historique de facturation</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-10">
              <Table
                columns={tableBillingsHistoryHeader}
                tableData={billingsHistoryDetailTableData}
                status={loadingBillingsHistory}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                itemPerPage={10}
                contain
              />
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <div className="my-3">
                <b>Actions</b>
              </div>
              <div className="o-information-detail__actions">
                <Button btnType="outline" onClick={() => setClientRefundOpen(true)}>
                  <IconCheck/>Remboursement
                </Button>
              </div>
              <div className="o-information-detail__actions">
                <Button btnType="outline" onClick={() => setCreateFutureFeesOpen(true)}>
                  <IconEdit/>Element à facturer
                </Button>
              </div>
              <div className="o-information-detail__actions">
                <Button btnType="outline" onClick={() => setCollectFeesOpen(true)}>
                  <IconCoin/>Frais immédiat
                </Button>
              </div>
            </div>
            </div>
          </div>
      </div>

      <ConfirmationCollectBillingsHistory
        collectBillingsHistoryOpen={collectBillingsHistoryOpen}
        setCollectBillingsHistoryOpen={setCollectBillingsHistoryOpen}
        userId={id}
        userFeatureHistory={selectedBillingsHistory}
        refreshData={refreshData}
      />
      <ConfirmationDeleteBillingHistory
        deleteBillingHistoryOpen={deleteBillingHistoryOpen}
        setDeleteBillingHistoryOpen={setDeleteBillingHistoryOpen}
        userId={id}
        userFeatureHistory={selectedBillingsHistory}
        refreshData={refreshData}
      />
      <ConfirmationCreateFutureFees
        createFutureFeesOpen={createFutureFeesOpen}
        setCreateFutureFeesOpen={setCreateFutureFeesOpen}
        userId={id}
        refreshData={refreshData}
      />
      <RefundClientModal
        clientId={id}
        refundModal={clientRefundOpen}
        setRefundModal={setClientRefundOpen}
        refreshData={refreshData}
      />
      <ConfirmationCollectFees
        collectFeesOpen={collectFeesOpen}
        setCollectFeesOpen={setCollectFeesOpen}
        userData={detailData}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetailV2Billing;
