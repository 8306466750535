import React from 'react';
import { useTranslation } from 'react-i18next';
import './head.scss';
import Input from '../input';
import Button from '../button';

function Head({
  headTitle,
  searchInput,
  setSearchInput,
  setAddModal,
  setCurrentItem,
  setSelectedID,
  isAdd,
  disabledSearch,
  rightFragment,
}) {
  const { t } = useTranslation();
  return (
    <>
    <div className="o-head">
      <div className="o-head-content">
        <h1 className="o-head-title">{headTitle}</h1>
        {!disabledSearch && (
          <div className="o-head__search">
            <Input
              className="filter"
              label="Filtre"
              placeholder="Filtre"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              multiline={false}
            />
          </div>
        )}
      </div>
      {isAdd && (
        <Button
          className="mb-4"
          onClick={() => {
            setAddModal(true);
            setCurrentItem && setCurrentItem(null);
            setSelectedID && setSelectedID(null);
          }}
        >
          +&nbsp;{t('global:add')}
        </Button>
      )}
    </div>
    {rightFragment}
    </>
  );
}

export default Head;
