import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Requests
import {  detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { clientBridgeItemsReq, clientPaymentLinksReq } from '../../../../../api/client/bridge/bridge.jsx';
// Renderer
import { tableBridgeItemsHeader,
  renderBridgeItemsTable,
  tableBridgePaymentRequestHeader,
  renderBridgePaymentRequestTable,
} from './bridge.utils.jsx';
// UI
import '../detailclient.scss';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import IconLoading from '../../../../../components/loading/loading.jsx';
import Head from '../../../../../components/head/index.jsx';
import Logo from '../../../../../components/logo/index.jsx';
import Table from '../../../../../components/table/index.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';


function ClientDetailV2Bridge() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState();
  const [detailData, setDetailData] = useState();
  const [selectedItemID, setSelectedItemID] = useState();
  const [selectedPaymentID, setSelectedPaymentID] = useState();
  const [bridgePaymentsTableData, setBridgePaymentsTableData] = useState([]);
  const [bridgeItemsTableData, setBridgeItemsTableData] = useState([]);
  const [selectedBridgePayment, setSelectedBridgePayment] = useState();
  const [selectedBridgeItem, setSelectedBridgeItem] = useState();
  const [allBridgePayments, setAllBridgePayments] = useState([]);
  const [allBridgeItems, setAllBridgeItems] = useState([]);
  const [loadingBridgePayments, setLoadingBridgePayments] = useState(true);
  const [loadingBridgeItems, setLoadingBridgeItems] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('loading');

  const [selectedItemType, setSelectedItemType] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      refreshPaymentsData();
      refreshItemsData();
      setLoading(false);
    })
    .finally(() => setLoading(false));
  };

  useEffect(() => {
    const bridgeItemDetail = allBridgeItems.find((x) => x.itemId === selectedItemID);
    setSelectedBridgeItem(bridgeItemDetail);
    setSelectedItemType('item');
  }, [selectedItemID]);

  useEffect(() => {
    const bridgePaymentDetail = allBridgePayments.find((x) => x.id === selectedPaymentID);
    setSelectedBridgePayment(bridgePaymentDetail);
    setSelectedItemType('payment');
  }, [selectedPaymentID]);

  const refreshPaymentsData = () => {
    setLoadingBridgePayments(true);
    clientPaymentLinksReq(id)
      .then((res) => {
        console.log(res);
        setAllBridgePayments(res);
        setBridgePaymentsTableData(renderBridgePaymentRequestTable(res, t));
      })
      .finally(() => setLoadingBridgePayments(false));
  };

  const refreshItemsData = () => {
    setLoadingBridgeItems(true);
    clientBridgeItemsReq(id)
      .then((res) => {
        console.log(res);
        setAllBridgePayments(res);
        setBridgeItemsTableData(renderBridgeItemsTable(res));
      })
      .finally(() => setLoadingBridgeItems(false));
  };

  const renderSelectedBridgeItemsActions = (selectedBridgeItem) => {
    return <div className="o-information-detail__actions"></div>;
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Lien de paiement</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column">
              <div className="o-content__detailclient__row">
                <Table
                  columns={tableBridgePaymentRequestHeader}
                  tableData={bridgePaymentsTableData}
                  status={loadingBridgePayments}
                  setShowModal={setShowModal}
                  setSelectedID={setSelectedPaymentID}
                  ContainHeader={() => ''}
                  contain
                  itemPerPage={10}
                />
              </div>
              <div className="o-content__detailclient__title">
                <b>Agrégation</b>
              </div>
              <div className="o-content__detailclient__row">
                <Table
                  columns={tableBridgeItemsHeader}
                  tableData={bridgeItemsTableData}
                  status={loadingBridgeItems}
                  setShowModal={setShowModal}
                  setSelectedID={setSelectedItemID}
                  ContainHeader={() => ''}
                  contain
                  itemPerPage={10}
                />
              </div>
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <BlocWithTitle title="">
              {selectedItemID && selectedItemType === 'item' && (
                <div>
                  <div>
                    <div className="mt-2">
                      <b>Informations de l'Item</b>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div className="mt-2">
                        <div>
                          <div>
                            <b>Identification ID</b>
                          </div>
                          <div>{selectedBridgeItem?.id}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Date</b>
                          </div>
                          <div>
                            {selectedBridgeItem?.date
                              ? moment(selectedBridgeItem.date).format('MMMM YYYY')
                              : '-'}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Item</b>
                          </div>
                          <div>{selectedBridgeItem.bank ? selectedBridgeItem.bank.id + ' - ' + selectedBridgeItem.bank.name : '-'}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Statut</b>
                          </div>
                          <div>
                            {selectedBridgeItem?.ooStatus ? selectedBridgeItem.ooStatus : '-'}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Statut Bridge</b>
                          </div>
                          <div>
                            {selectedBridgeItem?.statusCodeInfo ? selectedBridgeItem.statusCodeInfo : '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <b>Actions</b>
                      <hr />
                      {renderSelectedBridgeItemsActions(selectedBridgeItem)}
                    </div>
                  </div>
                </div>
              )}
              {selectedPaymentID && selectedItemType === 'payment' && (
                <div>
                  <div>
                    <div className="mt-2">
                      <b>Informations de lien de paiement</b>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div className="mt-2">
                        <div>
                          <div>
                            <b>Identification ID</b>
                          </div>
                          <div>{selectedBridgePayment?.id}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Date</b>
                          </div>
                          <div>
                            {selectedBridgePayment?.created_at
                              ? moment(selectedBridgePayment.created_at).format('DD/MM/YYYY HH:mm')
                              : '-'}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Item</b>
                          </div>
                          <div>{selectedBridgePayment?.feature?.name}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Montant</b>
                          </div>
                          <div>
                            {selectedBridgePayment?.transactions ? selectedBridgePayment?.transactions?.amount + ' €' : '- €'}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>End to End ID</b>
                          </div>
                          <div>
                            {selectedBridgePayment?.transactions ? selectedBridgePayment?.transactions?.end_to_end_id : '- €'}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Montant TTC</b>
                          </div>
                          <div>
                            {(selectedBridgeItem?.amount
                              ? Math.round(
                                  selectedBridgeItem.amount * (1 + selectedBridgeItem.vat.rate),
                                )
                              : '-') + '€'}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Facturé (O/N)</b>
                          </div>
                          <div>{selectedBridgeItem?.transfer ? 'Oui' : 'Non'}</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <b>Actions</b>
                      <hr />
                      {renderSelectedBridgeItemsActions(selectedBridgeItem)}
                    </div>
                  </div>
                </div>
              )}
              {!selectedItemID && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2Bridge;
