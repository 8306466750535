import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

import { ReactComponent as FreelanceIcon } from "../../assets/icons/_SmallIcons/ic_company_info_freelance.svg";
import { ReactComponent as CompanyProfileIcon } from "../../assets/icons/company_info.svg";

/**
 * Renders a user profile component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display in the user profile.
 * @param {string} props.size - The size of the user profile.
 * @param {string} props.bgColor - The background color of the user profile.
 * @param {string} props.textColor - The text color of the user profile.
 * @returns {JSX.Element} The rendered user profile component.
 */
export default function UserProfile({ text, size, bgColor, textColor, isCompany=false, parent=false, freelance=false }) {
    return (
        <div className="userProfil__container__icon">
            { isCompany ? (
            <CompanyProfileIcon/>
            ) : (
            <div className={`${size} ${text ? "" : "avatar__empty"} `}
                style={{
                    backgroundColor: text
                        ? bgColor
                            ? bgColor
                            : "var(--primary)"
                        : "transparent",
                }}
            >
                <div className={`avatar__text`}
                    style={{
                        color: textColor ? textColor : "var(--onButton)",
                    }}
                >
                    {`${text ? text : ""}`}
                </div>
            </div>
            )}           
            
            { freelance && (
            <FreelanceIcon className="userProfil__container__icon__company" />
            )}
        </div>
    );
}

UserProfile.propTypes = {
    text: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
};
