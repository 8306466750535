import React, { useState } from 'react';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { LockUnlockAccount } from '../../../../api/client/lockAccount';
import IconLoading from '../../../../components/loading/loading.jsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCheck } from '../../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';


const ConfirmationLock = ({ lockModal, setLockModal, userId, blocked, toggleBlockedData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [responseText, setResponseText] = useState('');

  const LockUnlockAccountHandler = async () => {
    setLoading(true);
    let status = await LockUnlockAccount(userId, blocked ? 'UNLOCK' : 'LOCK');
    status ? setResponseText('success') : setResponseText('fail');
    setLoading(false);
    if (status) {
      toggleBlockedData();
    }
  };

  const close = () => {
    setLoading(false);
    setLockModal(false);
    setResponseText('');
  };

  const ShowResponseText = () => (
    <div style={{ textAlign: 'center', margin: '20px auto 30px auto' }}>
      {responseText} <br />
      {responseText === 'success' ? (
        <IconCheck/>
      ) : (
        <IconClose/>
      )}
    </div>
  );

  return (
    <Modal isOpen={lockModal} toggle={setLockModal}>
      <div>
        <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={close}/>
          <div className="o-modal-info__title-text o-title">
            {blocked ? 'Débloquer' : 'Bloquer'} compte client
          </div>
          {loading ? (
            <IconLoading size={40}/>
          ) : responseText.length ? (
            <ShowResponseText />
          ) : (
            ''
          )}
          <div className="o-btn-group">
            {!loading && (
            <>
              {!responseText.length ? (
                <Button className="warning" onClick={LockUnlockAccountHandler} type="submit">
                  {t('global:confirm')}
                </Button>
              ) : (
                ''
              )}
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:back')}
              </Button>
            </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationLock;
