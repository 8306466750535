import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { deleteClientCodePromo } from '../../../../api/client/updateClient';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg'

const ConfirmationDeleteCodePromo = ({
  deleteCodePromoOpen,
  setDeleteCodePromoOpen,
  userData,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const deleteHandler = async (id) => {
    setLoading(true);
    let status = await deleteClientCodePromo(id);
    setLoading(false);
    setDeleteCodePromoOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const DeleteCodePromoInitialValues = (data) => {
    return {
      code: data ? data.code_promo : '',
    };
  };
  const DeleteCodePromoSchema = Yup.object().shape({
    code: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={deleteCodePromoOpen} toggle={setDeleteCodePromoOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={DeleteCodePromoInitialValues(userData)}
        validationSchema={DeleteCodePromoSchema}
        onSubmit={(values) => {
          console.log('Mise à jour du code promo');
          deleteHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setDeleteCodePromoOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setDeleteCodePromoOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Confirmation de la suppression du code promotionnel du client
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setDeleteCodePromoOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteCodePromo;
