import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function detailClientReq(userId, forceTreezor) {
  try {
    let queryParams = { params: {
      force : forceTreezor
    }};
    const res = await instanceBackendApi.get(backendApiEndpoint.client_detail.replace('{{userId}}', userId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientNotesReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_notes.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientChildrenReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_children.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientMembersReq(userId, params) {
  try {
    let queryParams = { params: params };
    const res = await instanceBackendApi.get(backendApiEndpoint.client_members.replace('{{userId}}', userId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
