import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { DocumentSchema, DocumentInitialValues, YesNo } from '../documenttype.utils';
import { detailDocumentReq } from '../../../../api/documenttype/documenttype';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ShowDocument = ({ showModal, setShowModal, selectedID, config }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (selectedID) {
      setStatus('loading');
      detailDocumentReq(selectedID)
        .then((res) => {
          setInitialValue({
            name: res.name,
            activated: res.activated ? YesNo[0] : YesNo[1],
            rectoVerso: res.rectoVerso ? YesNo[0] : YesNo[1],
            order: res.order,
            level: { value: res.level, label: res.level },
            trzDocumentType:
              config.trzDocumentType &&
              res.trzDocumentType &&
              config.trzDocumentType.filter((trz) => trz.value === res.trzDocumentType)[0],
            vialinkDocumentType:
              config.vialinkDocumentType &&
              res.vialinkDocumentType &&
              config.vlDocumentType.filter((trz) => trz.value === res.vialinkDocumentType)[0],
          });
        })
        .finally(() => {
          setStatus('done');
        });
    }
  }, [selectedID, config]);

  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? initialValue : DocumentInitialValues}
        validationSchema={DocumentSchema}
      >
        {() => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setShowModal(false);}}/>
              <div className="o-modal-info__title-text o-title">
                {t('document:detail_document')}
              </div>
              {status === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <Fragment>
                  <div className="o-modal-info__fields">
                    <Field
                      name="name"
                      label={t('document:name')}
                      placeholder={t('document:name_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="activated"
                      label={t('document:activated')}
                      placeholder={t('document:activated') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="order"
                      label={t('document:order')}
                      placeholder={t('document:order_placeholder') + ' *'}
                      component={InputFormik}
                      type="number"
                      onlyRead
                    />
                    <Field
                      name="level"
                      label={t('document:level')}
                      placeholder={t('document:level') + ' *'}
                      component={Dropdown}
                      type="number"
                      onlyRead
                    />
                    <Field
                      name="rectoVerso"
                      label={t('document:both_side')}
                      placeholder={t('document:both_side') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="trzDocumentType"
                      label={t('document:treezor')}
                      placeholder={t('document:treezor') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="vialinkDocumentType"
                      label={t('document:vialink')}
                      placeholder={t('document:vialink') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                  </div>
                  <Button
                    className="cancel" btnType="outline"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowDocument;
