import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { deleteStatementReq } from '../../../../../api/client/statements';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

import { useTranslation } from 'react-i18next';

const ConfirmationDeleteStatement = ({
  statementId,
  deleteStatementOpen,
  setDeleteStatementOpen,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const deleteHandler = async (id) => {
    setLoading(true);
    let status = await deleteStatementReq(id);
    setLoading(false);
    setDeleteStatementOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const DeleteStatementInitialValues = {};
  const DeleteStatementSchema = Yup.object().shape({});

  return (
    <Modal isOpen={deleteStatementOpen} toggle={setDeleteStatementOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={DeleteStatementInitialValues}
        validationSchema={DeleteStatementSchema}
        onSubmit={(values) => {
          console.log('Delete statement submit');
          deleteHandler(statementId);
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={() => { setDeleteStatementOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Pour confirmer la suppression du relevé de compte sélectionné ({statementId})
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setDeleteStatementOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteStatement;
