import React, { useContext } from 'react';
import PermissionsContext from '../../../../contexts/permissionContext';
import { NavLink, useLocation } from 'react-router-dom';
import './client_filter_tabs.scss';

const ClientDetailFilterTabs = ({clientId, ...props}) => {
    const { permContext } = useContext(PermissionsContext);
    const location = useLocation();

    return (
        <>
        <div className='o-filter'>
            <div className='o-filter__tabs'>
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client/' + clientId }
                    >
                    <div className={`o-filter__tabs__tab ${
                        location.pathname === '/dashboard/client/' + clientId ? "active" : ""
                    }`}>Général</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client/' + clientId + '/informations'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client/' + clientId + '/informations' ? "active" : ""
                                }`}>Informations</div>
                    </NavLink>
                )}
                {permContext?.market === 'pro'  && permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client/' + clientId + '/members'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client/' + clientId + '/members' ? "active" : ""
                                }`}>Membres</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <>
                    <div className="dropdown">
                        <NavLink
                        to={'/dashboard/client/' + clientId + '/security/monitoring'}
                        >
                        <div className={`o-filter__tabs__tab ${
                                        location.pathname.startsWith('/dashboard/client/' + clientId + '/security') ? "active" : ""
                                    }`}>Sécurité</div>
                        </NavLink>
                        <div className="dropdown-content">
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/security/monitoring'}
                            >
                            <div>Monitoring</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/security/kyc_kyb'}
                            >
                            <div>KYC/KYB</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/security/documents'}
                            >
                            <div>Documents</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/security/history'}
                            >
                            <div>Client IP History</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/security/sca'}
                            >
                            <div >SCA</div>
                            </NavLink>
                        </div>
                    </div>        
                    </>
                )}
                {permContext?.clientRead && (
                    <>
                    <div className="dropdown">
                        <NavLink
                        to={'/dashboard/client/' + clientId + '/membership/membership'}
                        >
                        <div className={`o-filter__tabs__tab ${
                                        location.pathname.startsWith('/dashboard/client/' + clientId + '/membership') ? "active" : ""
                                    }`}>Forfait</div>
                        </NavLink>
                        <div className="dropdown-content">
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/membership/membership'}
                            >
                            <div>Détail Forfait</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/membership/billing'}
                            >
                            <div>Facturation</div>
                            </NavLink>
                        </div>
                    </div>        
                    </>
                )}
                {permContext?.clientRead && (
                    <>
                    <div className="dropdown">
                        <NavLink
                        to={'/dashboard/client/' + clientId + '/wallets/list'}
                        >
                        <div className={`o-filter__tabs__tab ${
                                        location.pathname.startsWith('/dashboard/client/' + clientId + '/wallets') ? "active" : ""
                                    }`}>Wallets</div>
                        </NavLink>
                        <div className="dropdown-content">
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/wallets/list'}
                            >
                            <div>Wallets</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/wallets/statements'}
                            >
                            <div>Relevés de compte</div>
                            </NavLink>
                        </div>
                    </div>        
                    </>
                )}
                { permContext?.market === 'pro' ? (
                    <NavLink
                    to={'/dashboard/client/' + clientId + '/cards/list'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client/' + clientId + '/cards/list' ? "active" : ""
                                }`}>Cartes</div>
                    </NavLink>
                        ) : (
                    <>
                    <div className="dropdown">
                        <NavLink
                        to={'/dashboard/client/' + clientId + '/cards/list'}
                        >
                        <div className={`o-filter__tabs__tab ${
                                        location.pathname.startsWith('/dashboard/client/' + clientId + '/cards') ? "active" : ""
                                    }`}>Cartes</div>
                        </NavLink>
                            <div className="dropdown-content">
                            <NavLink
                                to={'/dashboard/client/' + clientId + '/cards/list'}
                                >
                            <div>Cartes de paiement</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/cards/isic'}
                            >
                            <div>Cartes ISIC</div>
                            </NavLink>
                        </div>
                    </div>        
                    </>
                )}                
                {permContext?.clientRead && (
                    <>
                    <div className="dropdown">
                        <NavLink
                        to={'/dashboard/client/' + clientId + '/operation/beneficiary'}
                        >
                        <div className={`o-filter__tabs__tab ${
                                        location.pathname.startsWith('/dashboard/client/' + clientId + '/operation') ? "active" : ""
                                    }`}>Opérations</div>
                        </NavLink>
                        <div className="dropdown-content">
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/operation/beneficiary'}
                            >
                            <div>Bénéficiaires</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/operation/sddrmandat'}
                            >
                            <div>Mandats SDDR</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/operation/sddrerror'}
                            >
                            <div>SDDR Refusés</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/operation/bridge'}
                            >
                            <div>Aggrégation / Lien de paiement</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/operation/topupcards'}
                            >
                            <div>Topup Carte</div>
                            </NavLink>
                            <NavLink
                            to={'/dashboard/client/' + clientId + '/operation/scheduledtransfer'}
                            >
                            <div>Virement programmés</div>
                            </NavLink>
                        </div>
                    </div>        
                    </>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client/' + clientId + '/communication'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client/' + clientId + '/communication' ? "active" : ""
                                }`}>Communication</div>
                    </NavLink>
                )}
            </div>
        </div>
        </>
    )
}

export default ClientDetailFilterTabs;
