import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Request
import {
  detailClientReq,
} from '../../../../../api/client/detailClient.jsx';
import { clientWalletListReq } from '../../../../../api/client/wallet.jsx';

// UI
import "../detailclient.scss";
import "./wallets.scss";
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import Table from '../../../../../components/table/index.jsx';
import Button from '../../../../../components/button/index.jsx';
import { ReactComponent as IconAtd } from '../../../../../assets/icons/component/ic_atd_error.svg';
import { ReactComponent as IconTransfer } from '../../../../../assets/icons/component/ic_transfer.svg';
import { ReactComponent as IconWarning }  from '../../../../../assets/icons/_SmallIcons/ic_warning.svg';

import Head from '../../../../../components/head/index.jsx';
import ClientDetailV2Header from '../client_header.jsx';

// Modals
import ConfirmationCollectATD from '../../modals/confirmationCollectATD.jsx';
import ConfirmationCreatePayout from '../../modals/confirmationCreatePayout.jsx';
import ConfirmationCreateTransfer from '../../modals/confirmationCreateTransfer.jsx';

// Renderer
import { tableWalletHeader, renderWalletTable } from './wallet.utils.jsx';
import useFormattedAmount from '../../../../../hooks/useFormattedAmount.js';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2Wallets() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [walletTableData, setWalletTableData] = useState([]);
  const [statusWalletTable, setStatusWalletTable] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [loading, setLoading] = useState(false);

  // Modals
  const [collectATDOpen, setCollectATDOpen] = useState(false);
  const [createTransferOpen, setCreateTransferOpen] = useState(false);
  const [createPayoutOpen, setCreatePayoutOpen] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${id}/wallets/${selectedID}`;
  }, [showModal]); // manque 'id' & 'refreshID'

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    clientWalletListReq(id)
      .then((res) => setWalletTableData(renderWalletTable(res)))
      .finally(() => setStatusWalletTable('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row justify-content-between">
            <div className="o-content__detailclient__title">
                <b>Wallets</b>
            </div>
            <div className="o-content__detailclient__row">
              {/* Bouton pour crer un virement */}
              <Button btnType="outline" onClick={() => setCreatePayoutOpen(true)}>
                <IconTransfer/>Virement externe
              </Button>
              <Button btnType="outline" onClick={() => setCreateTransferOpen(true)}>
                <IconTransfer/>Transfert entre wallets
              </Button>
            </div>
          </div>
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column">              
              <Table
                columns={tableWalletHeader}
                tableData={walletTableData}
                status={statusWalletTable}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                itemPerPage={10}
                contain
              />
            </div>
          </div>
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column"> 
              <div className='atd_content'>
                <div className='atd_content__title'>
                Saisie Administrative à Tiers Détenteur (ATD)
                </div>
                <div className='atd_content__warning'>
                  <div className='atd_content__warning__icon'><IconWarning/></div><span>Assurez-vous bien que ce client fait l’objet d’une saisie administrative à tiers détenteur avant de réaliser l’opération.</span>
                </div>
                <div>
                  <Button btnType="outline" 
                    className="warning" onClick={() => setCollectATDOpen(true)}>
                    <IconAtd />Saisie ATD
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationCollectATD
        collectATDOpen={collectATDOpen}
        setCollectATDOpen={setCollectATDOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationCreateTransfer
        createTransferOpen={createTransferOpen}
        setCreateTransferOpen={setCreateTransferOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationCreatePayout
        createPayoutOpen={createPayoutOpen}
        setCreatePayoutOpen={setCreatePayoutOpen}
        userId={id}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetailV2Wallets;
