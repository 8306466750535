import React, { useState, useContext } from 'react';
import moment from 'moment';
import PermissionsContext from '../../../../../contexts/permissionContext';

import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx'
import InfoItem from '../../../../../components/infoItem/index.jsx';

function computeAddress(detailData) {
  return (
    <>
    {detailData?.address1 && (<>{detailData?.address1}<br/></>)}
    {detailData?.address2 && (<>{detailData?.address2}<br/></>)}
    {detailData?.postcode && (<>{detailData?.postcode}</>)}{detailData?.city && (<>{detailData?.city}<br/></>)}
    {detailData?.state && (<>{detailData?.state}</>)}{detailData?.country && (<>{detailData?.country}</>)}&nbsp;{detailData?.countryName && (<>{detailData?.countryName}</>)}
    </>
  );
}

function PreviewInfos({ detailData,  ...props }) {

  const { permContext } = useContext(PermissionsContext);
  
  const freelance = permContext?.market === "pro" && detailData?.userTypeId === 1 && detailData?.parent == null;
  
  // console.log("======= permContext ====== ")
  // console.log(permContext)
  // console.log("======= permContext ====== ")
  return (
    <>
      <BlocWithTitle title='Informations' {...props}>
        <div className="mb-2 d-flex">
          <div className=" flex-fill">
            {permContext?.market === 'consumer' && detailData?.userTypeId === 1 && (
              <>
              <InfoItem title='Titre' value={detailData?.title} />
              <InfoItem title='Prénom' value={detailData?.firstname} />
              <InfoItem title='Nom' value={detailData?.lastname} />
              <InfoItem title='Date de naissance' value={detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'}/>
              </>
            )}
            {/* Cas d'un SELF_EMPLOYED */}
            {permContext?.market === 'pro' && detailData?.company_type === 'SELF_EMPLOYED' && detailData?.legal_infos && (
              <>
              <InfoItem title='Titre' value={detailData?.title} />
              <InfoItem title='Prénom' value={detailData?.firstname} />
              <InfoItem title='Nom' value={detailData?.lastname} />
              <InfoItem title='Date de naissance' value={detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'}/>
              </>
            )}
            {/* Cas d'une Profession Liberale, de TPE/PME ou GE */}
            {permContext?.market === 'pro' && detailData?.userTypeId === 2 && detailData?.legal_infos && (
              <>
              <InfoItem title='Nom de société' value={detailData?.legal_infos.legal_name} />
              <InfoItem title="Type d'entreprise" value={detailData?.company_type} />
              <InfoItem title='SIREN/SIRET' value={detailData?.legal_infos.legal_registration_number} />
              <InfoItem title='Date immatriculation' value={detailData?.legal_infos?.legal_registration_date ? moment(detailData?.legal_infos?.legal_registration_date).format('DD/MM/YYYY') : '-'} />
              </>
            )}
            <InfoItem title='User Status' value={detailData?.userStatus} />
            <InfoItem title='Date de validation' value={detailData?.validation_date ? moment(detailData?.validation_date).format('DD/MM/YYYY') : '-'} />
            <InfoItem title='Dernière mise à jour' value={detailData?.updated ? moment(detailData?.updated).format('DD/MM/YYYY') : '-'} />
          </div>
          <div className=" flex-fill">
            <InfoItem title='E-mail' value={detailData?.email} />
            <InfoItem title='Téléphone mobile' value={detailData?.mobile} />
            <InfoItem title='Adresse' value={computeAddress(detailData)} />
            {/* Cas d'un SELF_EMPLOYED */}
            {permContext?.market === 'pro' && detailData?.company_type === 'SELF_EMPLOYED' && detailData?.legal_infos && (
              <>
              <InfoItem title='Nom de société' value={detailData?.legal_infos.legal_name} />
              <InfoItem title="Type d'entreprise" value={detailData?.company_type} />
              <InfoItem title='SIREN/SIRET' value={detailData?.legal_infos.legal_registration_number} />
              <InfoItem title='Date immatriculation' value={detailData?.legal_infos?.legal_registration_date ? moment(detailData?.legal_infos?.legal_registration_date).format('DD/MM/YYYY') : '-'} />
              </>
            )}
            {permContext?.market === 'pro' && detailData?.parent && (
              <>
              <InfoItem title='Nom de société' value={detailData?.parent.legal_infos?.legal_name} />
              <InfoItem title="Type d'entreprise" value={detailData?.parent.company_type} />
              <InfoItem title='Accès' value={detailData?.acl_role} />
              <InfoItem title='Poste' value={detailData?.occupation} />
              </>
            )}
          </div>
        </div>
      </BlocWithTitle>
      </>
  );
}

export default PreviewInfos;
