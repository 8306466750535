import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
// Requests
import { detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { scaWalletListReq , scaWalletDetailReq} from '../../../../../api/client/sca_wallets/sca_wallets.jsx';
import { tableScaWalletHeader, renderScaWalletTable } from './sca_wallet.utils';
// UI
import "../detailclient.scss";
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import Head from '../../../../../components/head/index.jsx';
import Table from '../../../../../components/table';
import Button from '../../../../../components/button/index.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconRefresh } from '../../../../../assets/icons/component/ic_refresh.svg';
import { ReactComponent as IconWarning } from '../../../../../assets/icons/component/ic_warning.svg';
import Logo from '../../../../../components/logo';

// Modals
import ConfirmationLockSCAWallet from '../../modals/sca/confirmationLock.jsx';
import ConfirmationUnlockSCAWallet from '../../modals/sca/confirmationUnlock';
import ConfirmationResetPinSCAWallet from '../../modals/sca/confirmationResetPin';
import ConfirmationDeleteSCAWallet from '../../modals/sca/confirmationDelete';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2SCAWallet() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);

  const [scaWalletTableData, setScaWalletTableData] = useState([]);
  const [allScaWallets, setAllScaWallets] = useState([]);
  const [allScaWalletStatus, setAllScaWalletStatus] = useState('loading');
  const [selectedScaWallet, setSelectedScaWallet] = useState();
  const [refreshScaWalletLoading, setRefreshScaWalletLoading] = useState(false);

  const [lockScaWalletModal, setLockScaWalletModal] = useState(false);
  const [unlockScaWalletModal, setUnlockScaWalletModal] = useState(false);
  const [resetPinScaWalletModal, setResetPinScaWalletModal] = useState(false);
  const [deleteScaWalletModal, setDeleteScaWalletModal] = useState(false);

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    const scaWallet = allScaWallets.find((x) => x.user_sca_wallet_id === selectedID);
    setSelectedScaWallet(scaWallet);
    setSelectedItem('scaWallet');
  }, [selectedID]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
    
    refreshScaWalletList();
  };

  const refreshScaWalletList = () => {
    setAllScaWalletStatus('loading');
    scaWalletListReq(id)
      .then((res) => {
        setAllScaWallets(res);
        setScaWalletTableData(renderScaWalletTable(res));
      })
      .finally(() => {
        setSelectedID(null);
        setSelectedScaWallet(null);
        setAllScaWalletStatus('done');
      });
  };
  
  const refreshScaWalletDetailInfos = async (scaWalletId) => {
    setRefreshScaWalletLoading(true);
    scaWalletDetailReq(id, scaWalletId, true)
      .then((res) => {
        refreshScaWalletList();
      })
      .finally(() => {
        setRefreshScaWalletLoading(false);
      });
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>SCA Wallets</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-10">
            <Table
              columns={tableScaWalletHeader}
              tableData={scaWalletTableData}
              status={allScaWalletStatus}
              setShowModal={() => false}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}              
              contain
            />
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <BlocWithTitle title="">
              {selectedID && selectedScaWallet && (
                <div>
                  <div>
                    <div className="mt-3">
                      <b>Informations SCA Wallet</b>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div className="mt-2">
                        <div>
                          <div>
                            <b>ScaWallet ID / Trz ScaWallet ID</b>
                          </div>
                          <div>{selectedScaWallet?.user_sca_wallet_id}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Date de création</b>
                          </div>
                          <div>{moment(selectedScaWallet?.created_date).format('DD/MM/YYYY HH:mm')}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Date de suppression</b>
                          </div>
                          <div>{selectedScaWallet?.deleted_date ? moment(selectedScaWallet?.deleted_date).format('DD/MM/YYYY HH:mm') : '-'}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>Statut</b>
                          </div>
                          <div>{selectedScaWallet?.status}</div>
                        </div>
                        <div className="mt-2">
                          <div>
                            <b>3 PIN Faux</b>
                          </div>
                          <div>{selectedScaWallet?.locked ? 'Oui' : 'Non'}</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <b>Actions</b>
                      <hr />
                      <div class="o-information-detail__actions mt-3">
                      <Button
                        btnType="outline"
                        onClick={() =>
                          refreshScaWalletDetailInfos(selectedScaWallet?.user_sca_wallet_id)
                        }
                      >
                        <IconRefresh/>Rafraichir Infos SCA Wallet
                      </Button>
                      <Button btnType="outline" onClick={() => setLockScaWalletModal(true)}>
                        <IconEdit/>Bloquer le SCA Wallet
                      </Button>
                      <Button btnType="outline" onClick={() => setUnlockScaWalletModal(true)}>
                        <IconEdit/>Débloquer le SCA Wallet
                      </Button>
                      <Button btnType="outline" onClick={() => setResetPinScaWalletModal(true)}>
                        <IconEdit/>Réinitialisation du PIN du SCA Wallet
                      </Button>
                      <Button btnType="outline" className="warning" onClick={() => setDeleteScaWalletModal(true)}>
                        <IconWarning />Supprimer le SCA Wallet
                      </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!selectedID && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationLockSCAWallet
        userID={id}
        scaWallet={selectedScaWallet}
        lockModal={lockScaWalletModal}
        status={selectedScaWallet?.status}
        setLockModal={setLockScaWalletModal}
        refreshData={refreshScaWalletList}
      />
      <ConfirmationUnlockSCAWallet
        userID={id}
        scaWallet={selectedScaWallet}
        unlockModal={unlockScaWalletModal}
        status={selectedScaWallet?.status}
        setUnlockModal={setUnlockScaWalletModal}
        refreshData={refreshScaWalletList}
      />
      <ConfirmationResetPinSCAWallet
        userID={id}
        scaWallet={selectedScaWallet}
        resetPinModal={resetPinScaWalletModal}
        status={selectedScaWallet?.status}
        setResetPinModal={setResetPinScaWalletModal}
        refreshData={refreshScaWalletList}
      />
      <ConfirmationDeleteSCAWallet
        userID={id}
        scaWallet={selectedScaWallet}
        deleteModal={deleteScaWalletModal}
        status={selectedScaWallet?.status}
        setDeleteModal={setDeleteScaWalletModal}
        refreshData={refreshScaWalletList}
      />
    </div>
  );
}

export default ClientDetailV2SCAWallet;
