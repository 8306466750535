import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Requests
import {  detailClientReq} from '../../../../../api/client/detailClient.jsx';
import { clientBeneficiaryReq, clientBeneficiaryRefreshReq } from '../../../../../api/client/beneficiary/beneficiary.jsx';
// Renderer
import { tableBeneficiaryHeader, renderBeneficiaryTable } from './beneficiary.utils.jsx';

// UI
import '../detailclient.scss';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconRefresh } from '../../../../../assets/icons/component/ic_refresh.svg';
import IconLoading from '../../../../../components/loading/loading.jsx';
import Head from '../../../../../components/head/index.jsx';
import Logo from '../../../../../components/logo/index.jsx';
import Table from '../../../../../components/table/index.jsx';

// Modals
import ConfirmationAddBeneficiary from '../../modals/beneficiary/confirmationAdd.jsx';
import ConfirmationDeleteBeneficiary from '../../modals/beneficiary/confirmationDelete.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';
import Button from '../../../../../components/button/index.jsx';

function ClientDetailV2Beneficiary() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [statusBeneficiariesTable, setStatusBeneficiariesTable] = useState('loading');
  const [beneficiaryTableData, setBeneficiaryTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [allBeneficiaries, setAllBeneficiaries] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [selectedItem, setSelectedItem] = useState();
  // Modals
  const [deleteBeneficiaryOpen, setDeleteBeneficiaryOpen] = useState(false);
  const [addBeneficiaryOpen, setAddBeneficiaryOpen] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  useEffect(() => {
    const beneficiary = allBeneficiaries.find((x) => x.beneficiaryId === selectedID);
    setSelectedBeneficiary(beneficiary);
    setSelectedItem('beneficiary');
  }, [selectedID]); 

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
    
    clientBeneficiaryReq(id)
      .then((res) => {
        setLoading(false);
        setAllBeneficiaries(res);
        setBeneficiaryTableData(renderBeneficiaryTable(res));
      })
      .finally(() => setStatusBeneficiariesTable('done'));
  };

  const refreshBeneficiaryData = (id, beneficiaryId) => {
    setLoading(true);
    clientBeneficiaryRefreshReq(id, beneficiaryId).then((res) => {
      setLoading(false);
      refreshData();
    });
  };

  const renderSelectedBeneficiaryActions = (beneficiary) => {
    return (
      <div className="o-information-detail__actions">
        {beneficiary?.activated &&
          beneficiary?.status === 'VALIDATED' &&
          beneficiary?.usableForSct && (
            <Button btnType="outline" onClick={() => setDeleteBeneficiaryOpen(true)}>
              <IconDelete/>
              Supprimer
            </Button>
          )}
        <Button btnType="outline" onClick={() => refreshBeneficiaryData(id, selectedID)}>
          {loading ? <IconLoading /> : <IconRefresh/>}
          Refresh
        </Button>
      </div>
    );
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Bénéficiaires</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-9">
            <Table
              columns={tableBeneficiaryHeader}
              tableData={beneficiaryTableData}
              status={statusBeneficiariesTable}
              setShowModal={setShowModal}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}
              itemPerPage={10}
              contain
            />
            </div>
            <div className="o-content__detailclient__row__column col-3">
              <BlocWithTitle title="">
                <Button btnType="outline" onClick={() => setAddBeneficiaryOpen(true)}>
                  <IconEdit/>
                  Ajouter un bénéficiaire
                </Button>
                {selectedID && selectedItem === 'beneficiary' && (
                  <div>
                    <div>
                      <div className="mt-5">
                        <b>Informations Bénéficiaire</b>
                      </div>
                      <hr />
                      <div className="d-flex">
                        <div className="mt-2">
                          <div>
                            <div>
                              <b>Beneficiary ID</b>
                            </div>
                            <div>{selectedBeneficiary?.beneficiaryId}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Date de création</b>
                            </div>
                            <div>{moment(selectedBeneficiary?.created).format('DD/MM/YYYY')}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Mise à jour</b>
                            </div>
                            <div>{moment(selectedBeneficiary?.updated).format('DD/MM/YYYY')}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Statut</b>
                            </div>
                            <div>{selectedBeneficiary?.status}</div>
                          </div>
                        </div>
                        <div className=" mt-2 ml-4">
                          <div>
                            <div>
                              <b>Trz Beneficiary ID</b>
                            </div>
                            <div>{selectedBeneficiary?.trzBeneficiaryId}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Active</b>
                            </div>
                            <div>{selectedBeneficiary?.activated ? 'Oui' : 'Non'}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Active</b>
                            </div>
                            <div>{selectedBeneficiary?.usableForSct ? 'SCT' : 'SDDR'}</div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <b>Actions</b>
                        <hr />
                        {renderSelectedBeneficiaryActions(selectedBeneficiary)}
                      </div>
                    </div>
                  </div>
                )}
                {!selectedID && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
            </div>
          </div>
        </div>
      </div>
      {selectedID && (
        <ConfirmationDeleteBeneficiary
          deleteBeneficiaryOpen={deleteBeneficiaryOpen}
          setDeleteBeneficiaryOpen={setDeleteBeneficiaryOpen}
          userId={id}
          beneficiaryId={selectedID}
          refreshData={refreshData}
        />
      )}
      <ConfirmationAddBeneficiary
        addBeneficiaryOpen={addBeneficiaryOpen}
        setAddBeneficiaryOpen={setAddBeneficiaryOpen}
        userId={id}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetailV2Beneficiary;
