import React, { useEffect, useState } from 'react';
import './creditCard.scss';
import Button from '../button';
import { ReactComponent as IconFlash } from '../../assets/icons/component/ic_flash.svg';
import { ReactComponent as IconCalc } from '../../assets/icons/component/ic_account.svg';
import IconLoading from '../../../src/components/loading/loading.jsx';
import { ReactComponent as IconRefresh } from '../../assets/icons/component/ic_refresh.svg';
import { ReactComponent as IconEdit } from '../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconDelete } from '../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconLock } from '../../assets/icons/component/ic_lock.svg';
import { ReactComponent as IconUnlock } from '../../assets/icons/component/ic_unlock.svg';

function CreditCardActions({
  cardDetailData,
  setBlockCardModal,
  setLostCardModal,
  setActivateCardModal,
  setForceCodeModal,
  setUnlockPinCardModal,
  setUpdateLimitsCardModal,
  setUpdateOptionsCardModal,
  refreshCardDetailInfos,
  refreshCardLoading,
  ...props
}) {
  if (cardDetailData?.status === 'UNLOCK') {
    return (
      <div className="o-information-detail__actions mt-3">
        <Button btnType="outline" onClick={() => setBlockCardModal(true)}>
          <IconLock />
          Bloquer la carte
        </Button>
        <Button btnType="outline" onClick={() => setLostCardModal(true)}>
          <IconDelete />
          Perte / Vol / Destruction
        </Button>
        {cardDetailData?.activated == false ? ( //attend un "===" et reçois un "=="
          <Button btnType="outline" onClick={() => setActivateCardModal(true)}>
            <IconFlash />Activer la carte
          </Button>
        ) : (
          ''
        )}
        {cardDetailData?.virtualConverted ? (
          <Button btnType="outline" onClick={() => setForceCodeModal(true)}>
            <IconCalc/>Forcer code PIN
          </Button>
        ) : (
          ''
        )}
        {cardDetailData?.pinTryExceeds && cardDetailData?.virtualConverted ? (
          <Button btnType="outline" onClick={() => setUnlockPinCardModal(true)}>
            <IconUnlock />
            Débloquer PIN 3 erreurs
          </Button>
        ) : (
          ''
        )}
        <Button btnType="outline" onClick={() => setUpdateLimitsCardModal(true)}>
          <IconEdit />
          Modifier les plafonds
        </Button>
        <Button btnType="outline" onClick={() => setUpdateOptionsCardModal(true)}>
          <IconEdit />
          Modifier les options
        </Button>
        <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
          {refreshCardLoading ? (
            <IconLoading />
          ) : (
            <IconRefresh />
          )}
          Rafraichir données carte
        </Button>
      </div>
    );
  } else if (cardDetailData?.status === 'LOCK') {
    return (
      <div className="o-information-detail__actions mt-3">
        <Button btnType="outline" onClick={() => setBlockCardModal(true)}>
          <IconUnlock />
          Débloquer la carte
        </Button>
        {cardDetailData?.activated == false ? ( //attend un "===" et reçoit "=="
          <Button btnType="outline" onClick={() => setActivateCardModal(true)}>
            <IconFlash />Activer la carte
          </Button>
        ) : (
          ''
        )}
        <Button btnType="outline" onClick={() => setLostCardModal(true)}>
          <IconDelete />
          Perte / Vol / Destruction
        </Button>
        <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
          {refreshCardLoading ? (
            <IconLoading />
          ) : (
            <IconRefresh />
          )}
          Rafraichir données carte
        </Button>
      </div>
    );
  } else if (cardDetailData?.status === 'STOLEN') {
    return (
      <div className="o-information-detail__actions mt-3">
        <Button btnType="outline" onClick={() => setLostCardModal(true)}>
          <IconDelete />
          Perte / Vol / Destruction
        </Button>
        <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
          {refreshCardLoading ? (
            <IconLoading />
          ) : (
            <IconRefresh />
          )}
          Rafraichir données carte
        </Button>
      </div>
    );
  } else if (cardDetailData?.status === 'LOST') {
    return (
      <div className="o-information-detail__actions mt-3">
        <Button btnType="outline" onClick={() => setLostCardModal(true)}>
          <IconDelete />
          Perte / Vol / Destruction
        </Button>
        <Button btnType="outline" onClick={() => refreshCardDetailInfos(cardDetailData?.cardId)}>
          {refreshCardLoading ? (
            <IconLoading />
          ) : (
            <IconRefresh />
          )}
          Rafraichir données carte
        </Button>
      </div>
    );
  } else {
    return '';
  }
}

export default CreditCardActions;
