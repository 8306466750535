import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Head from '../../../../components/head';
import Table from '../../../../components/table';
import Logo from '../../../../components/logo';

import { tableCardTransactionsHeader, renderCardTransactionsTable } from './walletline.utils';
import { walletLineDetailReq } from '../../../../api/client//walletline/walletline';

const ClientWalletLineDetail = () => {
  const { userId, walletId, walletlineId } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState();
  const [walletLineData, setWalletLineData] = useState({});
  const [walletLineEntityData, setWalletLineEntityData] = useState({});
  const [cardTransactionsData, setCardTransactionsData] = useState();
  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  // const refreshCardList = () => {
  //   clientWalletCardReq(id, walletId).then((res) => {
  //     setAllCards(res);
  //     setCardwalletLineData(renderCardTable(res));
  //   });
  // };

  useEffect(() => {
    walletLineDetailReq(walletlineId).then((res) => {
      setWalletLineData(res);
      setWalletLineEntityData(res.object);
      if (res.object?.transactions) {
        setCardTransactionsData(renderCardTransactionsTable(res.object?.transactions, t));
      } else {
        setCardTransactionsData(null);
      }
    });
  }, [walletlineId]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${userId}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${userId}/wallets/list`}>Liste Wallets&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${userId}/wallets/${walletId}`}>
            Détails wallet&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails transaction
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head headTitle={headTitle()} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          <div className="o-information-detail__block">
            <div className="o-information-column">
              <div className="o-information-flex">
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Wallet Line ID</b>
                    </div>
                    <div className="ml-2">{walletLineData?.walletLineId}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Date de l’opération</b>
                    </div>
                    <div className="ml-2">
                      {moment(walletLineData?.operation_date).format('DD/MM/YYYY HH:mm')}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Type</b>
                    </div>
                    <div className="ml-2">{walletLineData?.type}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Statut</b>
                    </div>
                    <div className="ml-2">{walletLineData?.status}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Montant</b>
                    </div>
                    <div className="ml-2">
                      {walletLineData?.type === 'DEBIT' ? '-' : ''}
                      {walletLineData?.amount}&nbsp;€
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Devise</b>
                    </div>
                    <div className="ml-2">{walletLineData?.currency}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Libellé</b>
                    </div>
                    <div className="ml-2">{walletLineData?.label}</div>
                  </div>
                </div>
                {/* Bloc Pour afficher l'entité */}
                {walletLineData?.objectType === 'CardPayment' && (
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Object Type</b>
                      </div>
                      <div className="ml-2">{walletLineData?.objectType}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>CardPaymentId | TrzCardPaymentID</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.card_payment_id +
                          '|' +
                          walletLineData?.object?.trz_payment_id}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Montant Autorisé</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.amount_authorized}&nbsp;€</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Montant Prélevé</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.amount_settled}&nbsp;€</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Montant Remboursé</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.amount_reversal}&nbsp;€</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Nom du Marchand</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.merchant?.merchant_name}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Adresse</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.merchant?.merchant_address}
                        <br />
                        {walletLineData?.object?.merchant?.merchant_city}&nbsp;-&nbsp;
                        {walletLineData?.object?.merchant?.merchant_country}
                      </div>
                    </div>
                  </div>
                )}
                {walletLineData?.objectType === 'CardPayment' && walletLineData?.object?.card && (
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Card ID / Trz Card ID</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.card?.cardId} /{' '}
                        {walletLineData?.object?.card?.trzCardId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>PAN</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.card?.maskedPan}</div>
                    </div>
                  </div>
                )}

                {walletLineData?.objectType === 'Payin' && (
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Object Type</b>
                      </div>
                      <div className="ml-2">{walletLineData?.objectType}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>PayinId | TrzPayinId</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.payinId + '|' + walletLineData?.object?.trzPayinId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Date virement</b>
                      </div>
                      <div className="ml-2">
                        {moment(walletLineData?.operation_date).format('DD/MM/YYYY HH:mm')}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>IBAN Name</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.from?.ibanFullname}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>IBAN</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.from?.iban}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>BIC</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.from?.ibanBic}</div>
                    </div>
                  </div>
                )}

                {walletLineData?.objectType === 'Payout' && (
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Object Type</b>
                      </div>
                      <div className="ml-2">{walletLineData?.objectType}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>PayoutId | TrzPayoutId</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.payoutId +
                          '|' +
                          walletLineData?.object?.trzPayoutId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Bénéficiaire Id | Trz Beneficiary Id</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.beneficiary?.beneficiaryId +
                          '|' +
                          walletLineData?.object?.beneficiary?.trzBeneficiaryId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Nom Bénéficiaire</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.beneficiary?.name}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>IBAN</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.beneficiary?.iban}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>BIC</b>
                      </div>
                      <div className="ml-2">{walletLineData?.object?.beneficiary?.bic}</div>
                    </div>
                  </div>
                )}

                {walletLineData?.objectType === 'Transfer' && (
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Object Type</b>
                      </div>
                      <div className="ml-2">{walletLineData?.objectType}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>transferId | TrzTransferId</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.transferId +
                          '|' +
                          walletLineData?.object?.trzTransferId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Wallet source</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.wallet?.name}&nbsp;(
                        {walletLineData?.object?.wallet?.walletId} -{' '}
                        {walletLineData?.object?.wallet?.trzWalletId})
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>
                        <b>Wallet bénéficiaire</b>
                      </div>
                      <div className="ml-2">
                        {walletLineData?.object?.beneficiaryWallet?.name}&nbsp;(
                        {walletLineData?.object?.beneficiaryWallet?.walletId} -{' '}
                        {walletLineData?.object?.beneficiaryWallet?.trzWalletId})
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {cardTransactionsData && (
            <div className="table-title">
              Transactions
              <Table
                columns={tableCardTransactionsHeader}
                tableData={cardTransactionsData}
                ContainHeader={() => ''}
                contain
              />
            </div>
          )}
        </div>
        <div className="o-viewer">{!selectedItem && <Logo className="o-viewer-logo" />}</div>
      </div>
    </div>
  );
};

export default ClientWalletLineDetail;
