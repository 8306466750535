import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Requêtes
import { detailClientReq } from '../../../../api/client/detailClient.jsx';
import { clientChildrenReq } from '../../../../api/client/detailClient.jsx';

// UI
import './detailclient.scss';
import TablePage from '../../../../components/tablepage/index.jsx';
import ClientDetailFilterTabs from './client_filter_tabs.jsx';
import Head from '../../../../components/head/index.jsx';
import ClientDetailV2Header from './client_header.jsx';

// Context
import PermissionsContext from '../../../../contexts/permissionContext.js';

function ClientDetailV2Member() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [userNotes, setUserNotes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageMetaData, setPageMetaData] = useState({});
  const [dataStatus, setDataStatus] = useState('loading');
  const [selectedID, setSelectedID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');
  const requestSuffix = 'status=' + status;

  useEffect(() => {
    refreshData();
  }, [id]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={status ? '/dashboard/clientbystatus/' + status : '/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    clientChildrenReq(id)
      .then((res) => setTableData(renderTableMembers(res)))
      .finally(() => setDataStatus('done'));
  };

  const columnsMembers = [ 'UserId', 'E-mail', 'Nom', 'Statut KYC', 'Bloqué'];
  const renderTableMembers = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item.userId,
        id: item.userId,
        email: item.email,
        firstname: item.firstname + ' ' + item.lastname,
        status: item.userStatus,
        blocked: item?.blocked ? 'Oui' : 'Non',
      }),
    );
    return data;
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column">
              <TablePage
                columns={columnsMembers}
                tableData={tableData}
                pageMetaData={pageMetaData}
                loadTableData={refreshData}
                status={dataStatus}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                itemPerPage={10}
                contain
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2Member;
