import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { updateCardStatusReq } from '../../../../../api/client/card/lockCard';
import { CardLostStolenTypes } from '../../../../common.utils';
import Dropdown from '../../../../../components/dropdown';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationLostStolenCard = ({
  userID,
  card,
  lostStolenCardModal,
  setLostStolenCardModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const changeLostStolenHandler = async (data) => {
    setLoading(true);
    let status = await updateCardStatusReq(userID, card.wallet_id, card.cardId, {
      status: data.status.value,
      client_name: data.client_name,
    }).finally(() => {
      setLoading(false);
    });
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setLostStolenCardModal(false);
  };

  const LostStolenCardInitialValues = {};
  const LostStolenCardSchema = Yup.object().shape({
    status: Yup.object().required('Requis'),
    client_name: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={lostStolenCardModal} toggle={setLostStolenCardModal}>
      <Formik
        enableReinitialize={true}
        initialValues={LostStolenCardInitialValues}
        validationSchema={LostStolenCardSchema}
        onSubmit={(values) => {
          console.log('Confirm Lost/stolen Card');
          changeLostStolenHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={close}/>
            <div className="o-modal-info__title-text">
              <div className="o-title">ATTENTION CETTE OPERATION EST IRREVERSIBLE</div>
              <div className="o-modal-info__title-text__text">
                Veuillez confirmer en saisissant le status (LOST / STOLEN) et le nom du client en
                majuscule.
              </div>
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="status"
                label="Status de la carte *"
                placeholder="Status de la carte *"
                component={Dropdown}
                options={CardLostStolenTypes}
              />
              <Field
                name="client_name"
                label="Nom du client en majuscule *"
                placeholder="Nom du client en majuscule *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationLostStolenCard;
