import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { CodePromoSchema, CodePromoInitialValues } from '../codepromo.utils';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ShowCodePromo = ({ showModal, setShowModal, currentItem }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentItem ? currentItem : CodePromoInitialValues}
        validationSchema={CodePromoSchema}
      >
        {() => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setShowModal(false);}}/>
              <div className="o-modal-info__title-text o-title">
                {t('permission:detail_permission')}
              </div>
              <div className="o-modal-info__fields">
                <Field
                  name="code"
                  label={t('permission:unique_code')}
                  placeholder={t('permission:unique_code_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="activated"
                  label="Activated"
                  placeholder="Activated *"
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="description"
                  label={t('permission:full_description')}
                  placeholder={t('permission:full_description_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
              </div>
              <Button
                className="cancel" btnType="outline"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('global:back')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowCodePromo;
