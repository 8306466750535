import React, { useState, useEffect, useContext, Fragment } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../components/input';
import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import Head from '../../../components/head';
import TablePage from '../../../components/tablepage';
import FilterTabs from '../../../components/filterTabs';
import { getClientByStatusDownload } from '../../../api/client/getClient';
import { columns, renderTable } from './client.utils';
import { localStorageKeys } from '../../../api/constants.js';
import { searchClientPageReq } from '../../../api/client/searchClient';
import IconLoading from '../../../components/loading/loading.jsx';
import {ReactComponent as IconShare} from '../../../assets/icons/component/ic_share.svg';
import RoadButton from '../../../components/roadButton/index.jsx';

const Client = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [pageMetaData, setPageMetaData] = useState({});
  const [userPageMetaData, setUserPageMetaData] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('onboarding'); // Par défaut
  const [receiverPayinFiltered, setReceiverPayinFiltered] = useState(null);
  const [sortParams, setSortParams] = useState({ sortBy: 'updated', sortDirection: 'DESC' });

  const updateTableDataFromLocalStorage = () => {
    let searchedClients = localStorage.getItem(localStorageKeys.clients_searched);
    var tableDataList = [];
    try {
      tableDataList = renderTable(JSON.parse(searchedClients));
    } catch (e) {
      console.log('Problème de parsing de ' + searchedClients);
    } finally {
      setTableData(tableDataList);
    }
  };

  const downloadCsv = async (choosenStatus) => {
    if (choosenStatus) {
      setCsvLoading(true);
      const res = await getClientByStatusDownload(choosenStatus).finally(() =>
        setCsvLoading(false),
      );
    }
  };

  useEffect(() => {
    if (permContext?.clientRead) {
      fetchClientData({}, 0, 100, 'updated', 'DESC', 'onboarding');
      updateTableDataFromLocalStorage();
      setLoading(false);
      setDataStatus('done');
    } else if (permContext) {
      window.location.href = '/dashboard';
    }
  }, [permContext]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal, selectedID]);

  const fetchClientData = (
    values,
    pageNo,
    pageSize,
    sortBy = sortParams.sortBy,
    sortDirection = sortParams.sortDirection,
    status = selectedStatus,
    payinReceived = receiverPayinFiltered,
  ) => {
    setLoading(true);
    setDataStatus('loading');
    setFormValues(values);
    return searchClientPageReq(
      values,
      status,
      pageNo,
      pageSize,
      sortBy,
      sortDirection,
      payinReceived,
    )
      .then((res) => {
        const renderResult = JSON.stringify(res.content);
        localStorage.setItem(localStorageKeys.clients_searched, renderResult);
        setTableData(renderTable(res.content));
        setPageMetaData(res.pageable);
        setUserPageMetaData({ count: res.totalElements });
      })
      .catch((error) => console.error('Erreur lors de la recherche des clients:', error))
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  const refreshUsersListPage = (values, pageNo, pageSize) => {
    return fetchClientData(values, pageNo, pageSize, 'updated', 'DESC', 'onboarding');
  };

  const refreshData = (pageNo, pageSize) => {
    return refreshUsersListPage(formValues, pageNo, pageSize);
  };

  const FilterByStatus = (choosenStatus) => {
    setSelectedStatus(choosenStatus);
    fetchClientData(
      formValues,
      0,
      pageMetaData.pageSize || 100,
      sortParams.sortBy,
      sortParams.sortDirection,
      choosenStatus,
    );
  };

  const SortBy = (sortBy, isDescending) => {
    const newSortParams = {
      sortBy,
      sortDirection: isDescending ? 'DESC' : 'ASC',
    };
    setSortParams(newSortParams);
    refreshData().then(() => {
      fetchClientData(
        formValues,
        pageMetaData.pageNumber,
        pageMetaData.pageSize,
        newSortParams.sortBy,
        newSortParams.sortDirection,
      );
    });
  };

  const FilterByPayinReceived = () => {
    const updatedFormValues = {
      ...formValues,
      with_payin: !receiverPayinFiltered,
      display_payin: !receiverPayinFiltered,
    };
    setReceiverPayinFiltered(!receiverPayinFiltered);
    fetchClientData(
      updatedFormValues,
      0,
      pageMetaData.pageSize || 100,
      sortParams.sortBy,
      sortParams.sortDirection,
      selectedStatus,
    );
  };

  const DownloadButton = (choosenStatus) => (
    <Fragment>
      <Button
        btnType="outline"
        onClick={() => downloadCsv(choosenStatus)}
        icon={csvLoading ? <IconLoading /> : <IconShare />}
      >
        Exporter
      </Button>
    </Fragment>
  );

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head
            headTitle={t('sidebar:client')}
            setSelectedID={setSelectedID}
            isAdd={false}
            disabledSearch={true}
          />
          <RoadButton />
        </div>
      </div>

      <div className="o-content">
        <div className="o-content__filters">
          <FilterTabs />
          {DownloadButton(selectedStatus)}
        </div>
        <TablePage
          columns={columns}
          tableData={tableData}
          pageMetaData={userPageMetaData}
          loadTableData={refreshData}
          status={dataStatus}
          setShowModal={setShowModal}
          SortBy={SortBy}
          FilterByStatus={FilterByStatus}
          FilterByPayinReceived={FilterByPayinReceived}
          setSelectedID={setSelectedID}
          ContainHeader={() => ''}
          contain
        />
      </div>
    </div>
  );
};

export default Client;
