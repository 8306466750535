import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import { YesNo } from '../../packageType.utils';
import {
  AssignedPackageFeatureInitialValues,
  AssignedPackageFeatureSchema,
} from '../../assignedPackageFeature.utils';
import { getPackageFeatureConfigReq } from '../../../../../api/packageFeature/config';
import { getPackageTypeConfigReq } from '../../../../../api/packageType/config';
import Dropdown from '../../../../../components/dropdown';
import IconLoading from '../../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';
import { addAssignedPackageFeatureReq } from '../../../../../api/assignedPackageFeature/addAssignedPackageFeature';
import { getPackageFeatureReq } from '../../../../../api/packageFeature/getPackageFeature';


const AddAssignedPackageFeature = ({ addModal, setAddModal, selectedID, features, id }) => {
  const { t } = useTranslation();
  const [dataStatus, setDataStatus] = useState('done');
  const [currentItem, setCurrentItem] = useState();
  const [featureTypeOptions, setFeatureTypeOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);

  useEffect(() => {
    setDataStatus('loading');
    getPackageFeatureConfigReq().then((res) => {
      setFeatureTypeOptions(res.featureTypes);
    });
    getPackageTypeConfigReq()
      .then((res) => {
        setTypeOptions(res.familyLevels);
      })
      .finally(() => {
        setDataStatus('done');
      });
  }, []);

  useEffect(() => {
    let options = [];
    setDataStatus('loading');
    getPackageFeatureReq()
      .then((res) => {
        res.map((item) => options.push({ label: item.name, value: item.featureId }));
        setNameOptions(options);
      })
      .finally(() => {
        setDataStatus('done');
      });
  }, []);

  useEffect(() => {
    if (selectedID) {
      const result = features.filter((item) => item.featureId === selectedID);
      const res = result[0];
      setCurrentItem({
        featureId: res.featureId,
        name: res.name,
        type: { label: res.type, value: res.type },
        value: res.value,
        included: res.included ? YesNo[0] : YesNo[1],
        order: res.order,
        activated: res.activated ? YesNo[0] : YesNo[1],
        featureType: { label: res.featureType, value: res.featureType },
        description: res.description,
      });
    }
  }, [selectedID, features]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? currentItem : AssignedPackageFeatureInitialValues}
        validationSchema={AssignedPackageFeatureSchema}
        onSubmit={(values) => {
          addAssignedPackageFeatureReq(
            selectedID
              ? {
                  featureId: values.featureId,
                  membershipTypeId: Number(id),
                  name: values.name,
                  type: values.type.value,
                  value: values.value,
                  included: values.included.value,
                  order: values.order,
                  activated: values.activated.value,
                  featureType: values.featureType.value,
                  description: values.description,
                }
              : {
                  featureId: values.name.value,
                  name: values.name.label,
                  type: values.type.value,
                  value: values.value,
                  order: values.order,
                  included: values.included.value,
                  activated: values.activated.value,
                  featureType: values.featureType.value,
                  description: values.description,
                  membershipTypeId: Number(id),
                },
          ).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.reload();
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setAddModal(false);}}/>
              <div className="o-modal-info__title-text o-title">
                {selectedID ? t('type:modify_type_assigned') : t('type:new_type_assigned')}
              </div>
              {dataStatus === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <Fragment>
                  <div className="o-modal-info__fields">
                    <Field
                      name="type"
                      label={t('type:type')}
                      placeholder={t('type:type_placeholder') + ' *'}
                      component={Dropdown}
                      options={typeOptions}
                    />
                    <Field
                      name="name"
                      label={t('type:name')}
                      placeholder={t('type:name_placeholder') + ' *'}
                      component={Dropdown}
                      options={nameOptions}
                    />
                    <Field
                      name="featureType"
                      label={t('type:featureType')}
                      placeholder={t('type:featureType') + ' *'}
                      component={Dropdown}
                      options={featureTypeOptions}
                    />
                    <Field
                      name="value"
                      label={t('type:value')}
                      placeholder={t('type:value') + ' *'}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="description"
                      label={t('type:description')}
                      placeholder={t('type:description_placeholder') + ' *'}
                      component={InputFormik}
                    />
                    <Field
                      name="activated"
                      label={t('type:activated')}
                      placeholder={t('type:activated_placeholder') + ' *'}
                      component={Dropdown}
                      options={YesNo}
                    />
                    <Field
                      name="included"
                      label={t('type:included')}
                      placeholder={t('type:included') + ' *'}
                      component={Dropdown}
                      options={YesNo}
                    />
                    <Field
                      name="order"
                      label={t('type:order')}
                      placeholder={t('type:order_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                    />
                  </div>
                  <div className="o-btn-group">
                    <Button type="submit" disabled={!(props.isValid && props.dirty)}>
                      {selectedID ? t('global:validate') : t('global:add')}
                    </Button>
                    <Button
                      className="cancel" btnType="outline"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      {t('global:cancel')}
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AddAssignedPackageFeature;
