import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
// Requests
import {
  detailClientReq,
  clientNotesReq,
} from '../../../../../api/client/detailClient.jsx';
import { clientStatementsReq, downloadStatementReq } from '../../../../../api/client/statements.jsx';

// UI
import "../detailclient.scss";
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import Table from '../../../../../components/table/index.jsx';
import Button from '../../../../../components/button/index.jsx';

import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconCheck } from '../../../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconDoc } from '../../../../../assets/icons/component/ic_doc.svg';
import Head from '../../../../../components/head/index.jsx';

// Modal
import ConfirmationDeleteStatement from '../../modals/statement/confirmationDelete.jsx';
import ConfirmationRegenerateStatement from '../../modals/statement/confirmationRegenerate.jsx';

import PermissionsContext from '../../../../../contexts/permissionContext.js';

import ClientDetailV2Header from '../client_header.jsx';

function ClientDetailV2Statement() {
  const { id } = useParams();
  const [showModal, setShowModal] = useState();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  
  const [statementsDetailTableData, setStatementsTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allStatements, setAllStatements] = useState([]);
  const [selectedStatements, setSelectedStatements] = useState();

  const [selectedItem, setSelectedItem] = useState();
  const [deleteStatementOpen, setDeleteStatementOpen] = useState(false);
  const [regenerateStatementOpen, setRegenerateStatementOpen] = useState(false);
  const [loadingStatements, setLoadingStatements] = useState('loading');

  const { permContext } = useContext(PermissionsContext);

  const tableStatementsHeader = ['ID', 'Date Début', 'Date de Fin','Wallet','Storage Path'];

  const renderStatementsTable = (res, setRegenerateModal, setDeleteModal, setCurrentItem, isEdit, isDelete) => {
  let data = [];
    res.map((item) =>
      data.push({
        key: item.statementId,
        statement_id: item.statementId,
        begin_date : item.beginDate? moment(item.beginDate).format('DD/MM/YYYY') : '-',
        end_date: item.endDate? moment(item.endDate).format('DD/MM/YYYY') : '-',
        wallet_name: item.wallet ? '(' + item.wallet.walletId +') '+ item.wallet.name : item.walletId,
        storage_path: item.downloadGAEPath ? item.downloadGAEPath :'-',
      }),
    );
    return data;
  };

  useEffect(() => {
    refreshData();

  }, []);

  useEffect(() => {
    const statementsDetail = allStatements.find((x) => x.statementId === selectedID);
    setSelectedStatements(statementsDetail);
    setSelectedItem('statement');
  }, [selectedID]);

  const getStatementPdf = async (statementId) => {
    if (statementId) {
      setLoading(true);
      const res = await downloadStatementReq(statementId);
      setLoading(false);
    }
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client/'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    setLoadingStatements('loading');
    clientStatementsReq(id)
      .then((res) => {
        setAllStatements(res);
        setStatementsTableData(renderStatementsTable(res));
        setSelectedStatements(null);
        setSelectedItem(null);
      })
      .finally(() => setLoadingStatements('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Relevés de compte</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-10">
              <Table
                columns={tableStatementsHeader}
                tableData={statementsDetailTableData}
                status={loadingStatements}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                itemPerPage={10}
                contain
              />
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <div className="o-information-detail__actions">
                {permContext?.clientUpdate && selectedStatements?.downloadGAEPath ? (
                  <Button btnType="outline" onClick={() => getStatementPdf(selectedStatements.statementId)}>
                    {loading ? (
                      <IconLoading />
                    ) : (
                      <IconCheck/>
                    )}
                    Download Relevé
                  </Button>
                ) : (
                  ''
                )}
                {permContext?.clientUpdate && selectedStatements?.downloadGAEPath ? (
                  <Button btnType="outline" onClick={() => setDeleteStatementOpen(true)}>
                    <IconDelete/>Supprimer le Relevé
                  </Button>
                ) : (
                  ''
                )}
                {permContext?.clientUpdate && selectedStatements?.downloadGAEPath ? (
                  <Button btnType="outline" onClick={() => setRegenerateStatementOpen(true)}>
                    <IconDoc/>Regénerer le Relevé
                  </Button>
                ) : (
                  ''
                )}
                <ConfirmationDeleteStatement
                  statementId={selectedStatements?.statementId}
                  deleteStatementOpen={deleteStatementOpen}
                  setDeleteStatementOpen={setDeleteStatementOpen}
                  refreshData={refreshData}
                />
                <ConfirmationRegenerateStatement
                  statementId={selectedStatements?.statementId}
                  regenerateStatementOpen={regenerateStatementOpen}
                  setRegenerateStatementOpen={setRegenerateStatementOpen}
                  refreshData={refreshData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2Statement;
