import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
// Requests
import {  detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { clientKycLivenessListReq, downloadKycLivenessReportReq, generateAndDownloadKycLivenessReportReq } from '../../../../../api/client/kycliveness.jsx';
import { clientDocumentReq } from '../../../../../api/client/document.jsx';
// Renderer
import { tableKycLivenessHeader, renderKycLivenessTable } from './kycliveness.utils.jsx';
import { columnsDocumentTable, renderDocumentTable } from '../../client.utils.jsx';

// UI
import "../detailclient.scss";
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import Head from '../../../../../components/head/index.jsx';
import Logo from '../../../../../components/logo/index.jsx';
import Table from '../../../../../components/table';
import Button from '../../../../../components/button/index.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';
import InfoItem from '../../../../../components/infoItem/index.jsx';

import { ReactComponent as IconCheck } from '../../../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconEye } from '../../../../../assets/icons/component/ic_see.svg';
import { ReactComponent as IconBan } from '../../../../../assets/icons/component/ic_ban_error.svg';
import { ReactComponent as IconMail } from '../../../../../assets/icons/component/ic_mail.svg';
import { ReactComponent as IconPhone } from '../../../../../assets/icons/component/ic_phone.svg';
import { ReactComponent as IconDoc } from '../../../../../assets/icons/component/ic_doc.svg';
import { ReactComponent as IconRefresh } from '../../../../../assets/icons/component/ic_refresh.svg';

// Modals
import ShowDocument from '../../modals/showDocument.jsx';
import ConfirmationKycAccept from '../../modals/confirmationKycAccept.jsx';
import ConfirmationKycRefuse from '../../modals/confirmationKycRefuse.jsx';
import ConfirmationAskKycLivenessURL from '../../modals/kyc/confirmationAskKycLivenessUrl.jsx';
import ConfirmationReviewKycLiveness from '../../modals/kyc/confirmationReviewKycLiveness.jsx';
import ConfirmationSubmitKYCReview from '../../modals/confirmationSubmitKycReview.jsx';
import ConfirmationAskKycLivenessWithMail from '../../modals/kyc/confirmationAskKycLivenessWithMail.jsx';
import ConfirmationAskKycLivenessWithSMS from '../../modals/kyc/confirmationAskKycLivenessWithSMS.jsx';
import ConfirmationSubmitKYCDocs from '../../modals/confirmationSubmitKycDocs.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2KycKyb() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [loadingKycLiveness, setLoadingKycLiveness] = useState('loading');
  const [allKycLiveness, setAllKycLiveness] = useState([]);
  const [kycLivenessTableData, setKycLivenessTableData] = useState([]);
  const [selectedKycLiveness, setSelectedKycLiveness] = useState();
  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const [status, setStatus] = useState('loading');
  const [documentTableData, setDocumentTableData] = useState([]);

  // Modals
  const [kycAcceptOpen, setKycAcceptOpen] = useState(false);
  const [kycRefuseOpen, setKycRefuseOpen] = useState(false);
  const [askKycLivenessURLOpen, setAskKycLivenessURLOpen] = useState(false);
  const [askKycLivenessWithMailOpen, setAskKycLivenessWithMailOpen] = useState(false);
  const [askKycLivenessWithSMSOpen, setAskKycLivenessWithSMSOpen] = useState(false);
  const [reviewKycLivenessOpen, setReviewKycLivenessOpen] = useState(false);
  const [oldKycReviewOpen, setOldKycReviewOpen] = useState(false);
  const [submitKycDocOpen, setSubmitKycDocOpen] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        refreshData();
      })
      .finally(() => {});
  }, [permContext]);

  useEffect(() => {
    const kycLiveness = allKycLiveness.find((x) => x.identificationid === selectedID);
    setSelectedKycLiveness(kycLiveness);
    setSelectedItem('kycLiveness');
  }, [selectedID]);
  
  const refreshData = () => {
    setLoadingKycLiveness('loading');
    clientKycLivenessListReq(id)
      .then((res) => {
        setAllKycLiveness(res);
        setKycLivenessTableData(renderKycLivenessTable(res));
      })
      .finally(() => setLoadingKycLiveness('done'));

    refreshDataDocuments();
  };

  const refreshDataClient = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
  };

  const refreshDataDocuments = () => {
    setStatus('loading');
    clientDocumentReq(id)
      .then((res) => {
        setDocumentTableData(renderDocumentTable(res));
      })
      .finally(() => setStatus('done'));
  };

  const downloadKycLivenessReport = async (userId, kycLiveness) => {
    setLoading(true);
    if (userId && kycLiveness?.document_id && kycLiveness?.document?.bucketFileName) {
      const res = await downloadKycLivenessReportReq(userId, kycLiveness.document_id);
    } else if (userId && kycLiveness?.document_id) {
      const res = await generateAndDownloadKycLivenessReportReq(userId, kycLiveness.document_id);
    }
    setLoading(false);
  };

  function showDocuments(){
    window.location.href = `/dashboard/client/${id}/security/documents`;
  }

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const renderSelectedKycLivenessActions = () => {
    return (
      <div className="o-information-detail__actions">
        {selectedKycLiveness?.document_id ? (
          <Button
            btnType="outline"
            onClick={() => downloadKycLivenessReport(detailData?.userId, selectedKycLiveness)}
          >
            {loading ? <IconLoading /> : <IconEye/>}
            Télécharger Ubble Report
          </Button>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__title">
              <b>KYC</b>
            </div>
            <div className="o-content__detailclient__row">
            <Button btnType="outline" onClick={() => setKycAcceptOpen(true)}>
              <IconCheck/>Accepter KYC
            </Button>
            <Button
              btnType="outline"
              className="warning"
              onClick={() => setKycRefuseOpen(true)}
            >
              <IconBan />Refuser KYC
            </Button>
            </div>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-6">
            <BlocWithTitle>
            {detailData?.userTypeId === 1 && (
              <>
              <InfoItem title='Titre' value={detailData?.title} />
              <InfoItem title='Prénom' value={detailData?.firstname} />
              <InfoItem title='Nom' value={detailData?.lastname} />
              <InfoItem title='Date de naissance' value={detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'}/>
              </>
            )}
            {detailData?.userTypeId === 2 && detailData?.legal_infos && (
              <>
              <InfoItem title='Nom de société' value={detailData?.legal_infos.legal_name} />
              <InfoItem title="Type d'entreprise" value={detailData?.company_type} />
              <InfoItem title='SIREN/SIRET' value={detailData?.legal_infos.legal_registration_number} />
              <InfoItem title='Date immatriculation' value={detailData?.legal_infos?.legal_registration_date ? moment(detailData?.legal_infos?.legal_registration_date).format('DD/MM/YYYY') : '-'} />
              </>
            )}
            <InfoItem title='User Status' value={detailData?.userStatus} />
            </BlocWithTitle>
            </div>
            <div className="o-content__detailclient__row__column col-6">
            <BlocWithTitle>
              <InfoItem title='KYC Level' value={detailData?.kycLevel} />
              <InfoItem title='KYC Review' value={detailData?.kycReview} />
              <InfoItem title='KYC Review Comment' value={detailData?.kycReviewComment} />
              <InfoItem title='Freezed' value={detailData?.isFreezed ? 'Oui' : 'Non'}/>
              <Button btnType="outline" onClick={() => refreshDataClient()}>
                {loading ? <IconLoading /> : <IconRefresh/>}
                Rafraichir les données KYC
              </Button>
              {detailData?.userTypeId === 2 && (
              <Button btnType="outline" onClick={() => setOldKycReviewOpen(true)}>
                <IconCheck/>
                Déclencher Review KYB
              </Button>
              )}
              {detailData?.userTypeId === 1 && (
              <Button btnType="outline" onClick={() => setReviewKycLivenessOpen(true)}>
                <IconCheck/>
                Déclencher Review KYC
              </Button>
              )}
            </BlocWithTitle>
            </div>
          </div>
          {/* Liste des documents */}
          {detailData?.userTypeId === 2 ? (
          <>
            <div className="o-content__detailclient__row">
              <div className="o-content__detailclient__title">
                <b>Documents</b>
              </div>
              <div className="o-content__detailclient__row">
                <Button btnType="outline" className="ml-4" onClick={() => setSubmitKycDocOpen(true)}>
                  <IconDoc/>Ajout Document
                </Button>
              </div>
            </div>
            <div className="o-content__detailclient__row">
              <div className="o-content__detailclient__row__column">
              <Table
                showModal={showDocumentModal}
                setShowModal={setShowDocumentModal}
                columns={columnsDocumentTable}
                setSelectedID={setSelectedID}
                tableData={documentTableData}
                status={status}
                itemPerPage={10}
              />
              </div>
            </div>
          </>
          ) : (
            <div className="o-content__detailclient__row">
              <div className="o-content__detailclient__row__column">
                  <div className='m-2'>
                  <Button btnType="outline" onClick={() => showDocuments()}>
                    <IconEye/>
                  Voir les Documents
                  </Button>
                  </div>
              </div>
            </div>
          )}
          {/* Liste des KYC Liveness */}
          {detailData?.userTypeId === 1 && (
          <>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__title">
              <b>KYC Liveness Identification Process</b>
            </div>
            <div className="o-content__detailclient__row">
              <Button btnType="outline" onClick={() => setAskKycLivenessURLOpen(true)}>
                <IconCheck/>
                Demander un nouveau KYC Liveness
              </Button>
              <Button btnType="outline" onClick={() => setAskKycLivenessWithMailOpen(true)}>
                <IconMail/> Nouveau KYC Liveness + Mail
              </Button>
              {detailData?.mobile ? (
                <Button
                  btnType="outline"
                  onClick={() => setAskKycLivenessWithSMSOpen(true)}
                >
                  <IconPhone/>Nouveau KYC Liveness + SMS
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-9">
            <Table
              columns={tableKycLivenessHeader}
              tableData={kycLivenessTableData}
              status={loadingKycLiveness}
              setShowModal={setShowModal}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}
              itemPerPage={10}
              contain
            />
            </div>
            <div className="o-content__detailclient__row__column col-3">
            <BlocWithTitle>
            {selectedID && selectedItem === 'kycLiveness' && (
              <div>
                <div>
                  <div className="mt-5">
                    <b>Actions</b>
                    <hr />
                    {renderSelectedKycLivenessActions()}
                  </div>
                  <div className="mt-5">
                    <b>Informations KYC Liveness</b>
                  </div>
                  <hr />
                  <div className="d-flex">
                    <div className="mt-2">
                      <div>
                        <div>
                          <b>Identification ID</b>
                        </div>
                        <div>{selectedKycLiveness?.identificationid}</div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <b>Date de début</b>
                        </div>
                        <div>{selectedKycLiveness?.start_at}</div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <b>Mise à jour</b>
                        </div>
                        <div>{selectedKycLiveness?.updated_at}</div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <b>URL KYC Liveness</b>
                        </div>
                        <div>{selectedKycLiveness?.identification_url}</div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <b>Score</b>
                        </div>
                        <div>{selectedKycLiveness?.score}</div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <b>Nom complet détecté</b>
                        </div>
                        <div>
                          {selectedKycLiveness?.first_name} | {selectedKycLiveness?.last_name}
                        </div>
                      </div>
                      <div className="mt-2">
                        <div>
                          <b>Commentaire</b>
                        </div>
                        <div>{selectedKycLiveness?.comment}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <b>Valeurs de retour en fin de tunnel KYCLiveness</b>
                    <hr />
                    <div className="mt-2">
                      <div>
                        <b>Status Retour</b>
                      </div>
                      <div>{selectedKycLiveness?.return_status}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Raison Retour</b>
                      </div>
                      <div>{selectedKycLiveness?.return_reason}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Type Erreur retour Retour</b>
                      </div>
                      <div>{selectedKycLiveness?.return_error_type}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Description Erreur</b>
                      </div>
                      <div>{selectedKycLiveness?.return_description}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!selectedID && <Logo className="o-viewer-logo" />}
            </BlocWithTitle>
            </div>
          </div>
          </>
          )}
        </div>
      </div>
      {showDocumentModal && (
        <ShowDocument
          userId={id}
          document={selectedID}
          showModal={showDocumentModal}
          setShowModal={setShowDocumentModal}
        />
      )}
      <ConfirmationKycAccept
        kycAcceptOpen={kycAcceptOpen}
        setKycAcceptOpen={setKycAcceptOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationKycRefuse
        kycRefuseOpen={kycRefuseOpen}
        setKycRefuseOpen={setKycRefuseOpen}
        userData={detailData}
        refreshData={refreshData}
      />
      <ConfirmationAskKycLivenessURL
        askKycLivenessURLOpen={askKycLivenessURLOpen}
        setAskKycLivenessURLOpen={setAskKycLivenessURLOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationReviewKycLiveness
        reviewKycLivenessOpen={reviewKycLivenessOpen}
        setReviewKycLivenessOpen={setReviewKycLivenessOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationSubmitKYCReview
        setKycReviewOpen={oldKycReviewOpen}
        setSetKycReviewOpen={setOldKycReviewOpen}
        userId={detailData?.userId}
      />
      <ConfirmationAskKycLivenessWithMail
        askKycLivenessWithMailOpen={askKycLivenessWithMailOpen}
        setAskKycLivenessWithMailOpen={setAskKycLivenessWithMailOpen}
        userId={detailData?.userId}
        refreshData={refreshData}
      />
      <ConfirmationAskKycLivenessWithSMS
        askKycLivenessWithSMSOpen={askKycLivenessWithSMSOpen}
        setAskKycLivenessWithSMSOpen={setAskKycLivenessWithSMSOpen}
        user={detailData}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetailV2KycKyb;
