import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { addPermissionReq } from '../../../../api/permission/permissions';
import { PermissionSchema, PermissionInitialValues } from '../permission.utils';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const AddPermission = ({ addModal, setAddModal, currentItem }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(PermissionInitialValues);

  useEffect(() => {
    if (currentItem) {
      setInitialValues(currentItem);
    }
  }, [currentItem]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={PermissionSchema}
        onSubmit={(values) => {
          addPermissionReq(values).then((res) => {
            setAddModal(false);
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setAddModal(false);}}/>
              <div className="o-modal-info__title-text o-title text-center">
                {currentItem ? t('permission:modify_permission') : t('permission:new_permission')}
              </div>
              <div className="o-modal-info__fields">
                <Field
                  name="code"
                  label={t('permission:unique_code')}
                  placeholder={t('permission:unique_code_placeholder') + ' *'}
                  component={InputFormik}
                  disabled={currentItem}
                />
                <Field
                  name="name"
                  label={t('permission:descriptive_name')}
                  placeholder={t('permission:descriptive_name_placeholder') + ' *'}
                  component={InputFormik}
                />
                <Field
                  name="description"
                  label={t('permission:full_description')}
                  placeholder={t('permission:full_description_placeholder') + ' *'}
                  component={InputFormik}
                />
              </div>
              <div className="o-btn-group">
                <Button type="submit" disabled={!(props.isValid && props.dirty)}>
                  {currentItem ? t('global:validate') : t('global:add')}
                </Button>
                <Button
                  className="cancel" btnType="outline"
                  onClick={() => {
                    setAddModal(false);
                  }}
                >
                  {t('global:cancel')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPermission;
