import React, { useState } from 'react';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { ReactComponent as IconCheck } from '../../../../../assets/icons/component/ic_check.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';
import { useTranslation } from 'react-i18next';

import { deleteScaWalletReq } from '../../../../../api/client/sca_wallets/sca_wallets';

const ConfirmationDeleteSCAWallet = ({
  userID,
  scaWallet,
  deleteModal,
  setDeleteModal,
  status,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');
  const { t } = useTranslation();
  const DeleteSCAWalletHandler = async () => {
    setLoading(true);
    const res = await deleteScaWalletReq(userID, scaWallet.user_sca_wallet_id);
    setResponseText(res ? 'success' : 'fail');
    refreshData();
    setLoading(false);
  };

  const close = () => {
    setDeleteModal(false);
    setResponseText('');
  };

  const ShowResponseText = () => (
    <div style={{ textAlign: 'center', margin: '20px auto 30px auto' }}>
      {responseText} <br />
      {responseText === 'success' ? (
        <IconCheck/>
      ) : (
        <IconClose/>
      )}
    </div>
  );

  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
        <div className="o-modal-info__title-text o-title">Supprimer le SCA Wallet</div>
        {loading ? (
          <div className="d-flex mx-auto my-4">
            <IconLoading size={40} />
          </div>
        ) : responseText.length ? (
          <ShowResponseText />
        ) : (
          ''
        )}
        <div className="o-btn-group">
          {!responseText.length ? (
            <Button onClick={DeleteSCAWalletHandler} type="submit">
              {t('global:confirm')}
            </Button>
          ) : (
            ''
          )}
          <Button className="cancel" btnType="outline" onClick={close}>
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDeleteSCAWallet;
