import {  backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function createFeesToCollectClient(userId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_create_fees.replace('{{userId}}', userId), data, queryParams);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function collectFeesClient(userId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_collect_fees.replace('{{userId}}', userId), data, queryParams);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function collectATDClient(userId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_collect_atd.replace('{{userId}}', userId), data, queryParams);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientBillingHistoryReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_billing_history.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function collectClientBillingHistoryReq(userId, userFeatureHistoryId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_billing_history_detail_billing.replace('{{userId}}', userId).replace('{{userFeatureHistoryId}}', userFeatureHistoryId), data, queryParams);
    if (res.status === 200) {
      toast('Facturation avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function deleteClientBillingHistoryReq(userId, userFeatureHistoryId) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.client_billing_history_detail.replace('{{userId}}', userId).replace('{{userFeatureHistoryId}}', userFeatureHistoryId));
    if (res.status === 204) {
      toast('Suppression avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientRefundReq(userId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_refund.replace('{{userId}}', userId), data, queryParams);
    if (res.status === 200) {
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
