import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as IconSearch} from '../../assets/icons/component/ic_search.svg';

import '../../../src/components/button/button.scss';

const RoadButton = () => {
  return (
    <NavLink to="/dashboard/client_search" className={'o-btn-road__text'}>
      <button className="o-btn o-btn-road">
        <IconSearch />Rechercher un client
      </button>
    </NavLink>
  );
};

export default RoadButton;
