import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import IconLoading from '../../../../components/loading/loading.jsx';
import { updatePayinAlertStatusReq } from '../../../../api/monitoring/monitoring';
import { AlertFraudStatus } from '../../../common.utils';
import Dropdown from '../../../../components/dropdown';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg'

import { useTranslation } from 'react-i18next';

const ConfirmationUpdateAlertStatus = ({
  item,
  updateAlertStatusModal,
  setUpdateAlertStatusModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const changeUpdateAlertStatusHandler = async (data) => {
    setLoading(true);
    let status = await updatePayinAlertStatusReq({
      alertfraudid: item.alertfraudid,
      status: data.status.value,
      comment: data.comment,
    }).finally(() => {
      setLoading(false);
    });
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setUpdateAlertStatusModal(false);
  };

  const UpdateAlertStatusInitialValues = () => {
    const initStatus = item?.status
      ? AlertFraudStatus.find((x) => x.value === item?.status)
      : AlertFraudStatus[0];
    return {
      status: initStatus,
    };
  };
  const UpdateAlertStatusSchema = Yup.object().shape({
    status: Yup.object().required('Requis'),
    comment: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateAlertStatusModal} toggle={setUpdateAlertStatusModal}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateAlertStatusInitialValues()}
        validationSchema={UpdateAlertStatusSchema}
        onSubmit={(values) => {
          changeUpdateAlertStatusHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
          <IconClose className='icon-cancel' onClick={close}/> 
            <div className="o-modal-info__title-text o-title">
              Modifier le status de l'Alerte Fraude
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="status"
                label="Status de l'alerte' *"
                placeholder="Status de l'alerte' *"
                component={Dropdown}
                options={AlertFraudStatus}
              />
              <Field
                name="comment"
                label="Commentaire *"
                placeholder="Commentaire *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateAlertStatus;
