import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import { ReactComponent as IconEdit } from '../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconDelete } from '../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconDisabledEdit } from '../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconDisabledDelete } from '../../assets/icons/component/ic_delete.svg';

const Button = ({ icon, className, children, btnType, ...props }) => {
  return (
    <button className={`o-btn o-btn-${btnType} ${className ? className : ''}`} type="button" {...props}>
      {icon && icon}
      {children}
    </button>
  );
};

export const ButtonWithIcon = ({ className, children, ...props }) => {
  return (
    <button className={`o-btn-icon ${className}`} {...props}>
      {children}
    </button>
  );
};

function renderButtonTableImage(action, disabled) {
  if (action === 'edit' && !disabled) {
    return (<IconEdit className={`o-table-icon ${disabled ? 'o-btn-table__disabled' : ''}`}/>);
  } else if (action === 'delete' && !disabled) {
    return (<IconDelete className={`o-table-icon ${disabled ? 'o-btn-table__disabled' : ''}`}/>);
  } else if (action === 'edit' && disabled) {
    return (<IconDisabledEdit className={`o-table-icon ${disabled ? 'o-btn-table__disabled' : ''}`}/>);
  } else if (action === 'delete' && disabled) {
    return (<IconDisabledDelete className={`o-table-icon ${disabled ? 'o-btn-table__disabled' : ''}`}/>);
  }
}
export const ButtonTable = ({ className, children, action, disabled, ...props }) => {
  return (
    <div className="o-btn-table">
      {/* <img
        title={disabled && "Vous n'avez pas les droits nécessaires pour effectuer cette action"}
        className={`o-table-icon ${disabled ? 'o-btn-table__disabled' : ''}`}
        src={renderButtonTableImage(action, disabled)}
        alt=""
        {...props}
      /> */}
      {renderButtonTableImage(action, disabled)}
    </div>
  );
};

Button.defaultProps = {
  btnType: 'default',
};
Button.propTypes = {
  className: PropTypes.string,
  btnType: PropTypes.oneOf(['default', 'outline', 'text']),
  disabled: PropTypes.bool,
};

ButtonWithIcon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
