import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Request
import {
  detailClientReq,
} from '../../../../api/client/detailClient';
import { cardListReq, cardDetailReq } from '../../../../api/client/card/card';

// UI
import "./detailclient.scss";
import ClientDetailFilterTabs from './client_filter_tabs';
import Table from '../../../../components/table';
import Head from '../../../../components/head';
import Button from '../../../../components/button/index.jsx';
import BlocWithTitle from '../../../../components/blocWithTitle/index.jsx'

import { ArrowRight } from '../../../../assets/icons/icons-arrow';
import { ReactComponent as IconCard} from '../../../../assets/icons/component/ic_card.svg';
import Logo from '../../../../components/logo/index.jsx';
import CreditCard from '../../../../components/creditCard/index.jsx';
import CreditCardActions from '../../../../components/creditCard/cardActions.jsx';

// Modals
import ConfirmationBlockCard from '../modals/card/confirmationBlock';
import ConfirmationForcePin from '../modals/card/confirmationForcePin';
import ConfirmationOrderCard from '../modals/card/confirmationOrderCard';
import ConfirmationOrderVirtualCard from '../modals/card/confirmationOrderVirtualCard';
import ConfirmationLostStolenCard from '../modals/card/confirmationLostStolen';
import ConfirmationActivateCard from '../modals/card/confirmationActivate';
import ConfirmationUnlockPinCard from '../modals/card/confirmationUnlockPin';
import ConfirmationUpdateCardLimits from '../modals/card/confirmationUpdateCardLimits';
import ConfirmationUpdateCardOptions from '../modals/card/confirmationUpdateCardOptions';

// Context
import PermissionsContext from '../../../../contexts/permissionContext';

import ClientDetailV2Header from './client_header.jsx';

function ClientDetailV2Cards() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();

  // Cards
  const [cardTableData, setCardTableData] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [allCardStatus, setAllCardStatus] = useState('loading');
  const [selectedCard, setSelectedCard] = useState();
  const [refreshCardLoading, setRefreshCardLoading] = useState(false);
  
  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState('');
  const [loading, setLoading] = useState(false);

  //Modals
  const [orderCardModal, setOrderCardModal] = useState(false);
  const [orderVirtualCardModal, setOrderVirtualCardModal] = useState(false);
  const [activateCardModal, setActivateCardModal] = useState(false);
  const [unlockPinCardModal, setUnlockPinCardModal] = useState(false);
  const [updateLimitsCardModal, setUpdateLimitsCardModal] = useState(false);
  const [updateOptionsCardModal, setUpdateOptionsCardModal] = useState(false);
  const [blockCardModal, setBlockCardModal] = useState(false);
  const [forceCodeModal, setForceCodeModal] = useState(false);
  const [lostCardModal, setLostCardModal] = useState(false);
  
  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  useEffect(() => {
    const card = allCards.find((x) => x.cardId === selectedID);
    setSelectedCard(card);
    setSelectedItem('card');
  }, [selectedID]);

  const refreshCardList = () => {
    setAllCardStatus('loading');
    cardListReq(id)
      .then((res) => {
        setAllCards(res);
        setCardTableData(renderCardTable(res));
      })
      .finally(() => {
        setSelectedID(null);
        setSelectedCard(null);
        setAllCardStatus('done');
      });
  };

  const refreshCardDetailInfos = async (cardId) => {
    setRefreshCardLoading(true);
    cardDetailReq(id, cardId, true)
      .then((res) => {
        refreshCardList();
      })
      .finally(() => {
        setRefreshCardLoading(false);
      });
  };

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    refreshCardList();
  };
  const tableCardHeader = ['Card ID', 'PAN', 'Trz Card ID', 'Type','Nom complet du porteur','Status','Active','Date d’expiration'];
  const renderCardTable = (res) => {
    let data = [];
    res.map((item) =>
      data.push({
        key: item.cardId,
        cardId: item.cardId,
        cardNumber: item.maskedPan,
        trzCardId: item.trzCardId,
        cardType : item.card_type,
        name: item.embossedName,
        status: item.status,
        active: item.activated ? 'Oui' : 'Non',
        expiryDate: moment(item.expiryDate).format('DD/MM/YYYY'),
      }),
    );
    return data;
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }
  function rightAction(){
    return (
      <Fragment>
          {permContext?.clientUpdate ? (
          <>
            <Button btnType="outline" onClick={() => setOrderVirtualCardModal(true)}>
              <IconCard />
              Commander carte Virtuelle
            </Button>
            <Button btnType="outline" onClick={() => setOrderCardModal(true)}>
              <IconCard />
              Commander carte Physique
            </Button>
          </>
          ) : (
          <div>&nbsp;</div>
          )}
          <ConfirmationOrderVirtualCard
            userId={id}
            orderVirtualCardModal={orderVirtualCardModal}
            setOrderVirtualCardModal={setOrderVirtualCardModal}
            refreshData={refreshCardList}
          />
          <ConfirmationOrderCard
            userId={id}
            card={selectedCard}
            orderCardModal={orderCardModal}
            setOrderCardModal={setOrderCardModal}
            refreshData={refreshCardList}
          />
      </Fragment>
    );
  }

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData} rightFragment={rightAction()}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-9">
              <Table
                  columns={tableCardHeader}
                  tableData={cardTableData}
                  status={allCardStatus}
                  setShowModal={() => false}
                  setSelectedID={setSelectedID}
                  ContainHeader={() => ''}
                  itemPerPage={10}
                  contain
                />
            </div>
            <div className="o-content__detailclient__row__column col-3">
              <BlocWithTitle title="Détail Carte">
                {selectedID && selectedItem === 'card' && selectedCard && (
                  <div>
                    <CreditCard
                      cardNumber={selectedCard?.maskedPan}
                      name={selectedCard?.embossedName}
                      date={selectedCard?.expiryDate}
                      cardImageUrl={selectedCard?.image_url}
                    />
                    <div>
                      <div className="mt-3">
                        <b>
                          Informations Cartes
                          <a
                            href={`/dashboard/client/${id}/cards/${selectedCard?.cardId}`}
                            className="pl-2"
                          >
                            Voir détail <ArrowRight />
                          </a>
                        </b>
                      </div>
                      <hr />
                      <div className="d-flex">
                        <div className="mt-2">
                          <div>
                            <div>
                              <b>Card ID / Trz Card ID</b>
                            </div>
                            <div>
                              {selectedCard?.cardId} / {selectedCard?.trzCardId}
                            </div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Date de création</b>
                            </div>
                            <div>{moment(selectedCard?.created).format('DD/MM/YYYY HH:mm')}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Statut</b>
                            </div>
                            <div>{selectedCard?.status}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>3 PIN Faux</b>
                            </div>
                            <div>{selectedCard?.pinTryExceeds ? 'Oui' : 'Non'}</div>
                          </div>
                        </div>
                        <div className=" mt-2 ml-4">
                          <div>
                            <div>
                              <b>Type</b>
                            </div>
                            <div>{selectedCard?.card_type}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>PublicToken</b>
                            </div>
                            <div>{selectedCard?.publicToken}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Active</b>
                            </div>
                            <div>{selectedCard?.activated ? 'Oui' : 'Non'}</div>
                          </div>
                          <div className="mt-2">
                            <div>
                              <b>Date destruction</b>
                            </div>
                            <div>
                              {selectedCard?.deleted_date
                                ? moment(selectedCard?.deleted_date).format('DD/MM/YYYY HH:mm')
                                : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedCard?.cardLimits && (
                        <div className="mt-3">
                          <b>Plafonds Paiements</b>
                          <hr />
                          <div className="mt-2 mb-2">
                            <div>
                              <i>30 derniers jours</i> : {selectedCard?.cardLimits?.limitPaymentMonth}
                              &nbsp;€ ({selectedCard?.cardStats?.totalPaymentMonth.toFixed(2)}&nbsp;€)
                            </div>
                            <div>
                              <i>7 derniers jours</i> : {selectedCard?.cardLimits?.limitPaymentWeek}
                              &nbsp;€ ({selectedCard?.cardStats?.totalPaymentWeek.toFixed(2)}&nbsp;€)
                            </div>
                            <div>
                              <i>aujourd'hui</i> : {selectedCard?.cardLimits?.limitPaymentDay}&nbsp;€ (
                              {selectedCard?.cardStats?.totalPaymentDay.toFixed(2)}&nbsp;€)
                            </div>
                          </div>
                          <b>Plafonds Retraits</b>
                          <hr />
                          <div className="mt-2">
                            <div>
                              <i>30 derniers jours</i> : {selectedCard?.cardLimits?.limitAtmMonth}&nbsp;€
                              ({selectedCard?.cardStats?.totalAtmMonth.toFixed(2)}&nbsp;€)
                            </div>
                            <div>
                              <i>7 derniers jours</i> : {selectedCard?.cardLimits?.limitAtmWeek}&nbsp;€ (
                              {selectedCard?.cardStats?.totalAtmWeek.toFixed(2)}&nbsp;€)
                            </div>
                            <div>
                              <i>aujourd'hui</i> : {selectedCard?.cardLimits?.limitAtmDay}&nbsp;€ (
                              {selectedCard?.cardStats?.totalAtmDay.toFixed(2)}&nbsp;€)
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedCard?.cardOptions && (
                        <div className="mt-3">
                          <b>Options de paiement</b>
                          <hr />
                          <div className="mt-2 mb-2">
                            <div>
                              <i>Retrait ATM</i> :{' '}
                              {selectedCard?.cardOptions?.optionAtm ? (
                                <span className="o-green">OUI</span>
                              ) : (
                                <span className="o-red">NON</span>
                              )}
                            </div>
                            <div>
                              <i>Paiement étranger</i> :{' '}
                              {selectedCard?.cardOptions?.optionForeign ? (
                                <span className="o-green">OUI</span>
                              ) : (
                                <span className="o-red">NON</span>
                              )}
                            </div>
                            <div>
                              <i>Paiement e-commerce</i> :{' '}
                              {selectedCard?.cardOptions?.optionOnline ? (
                                <span className="o-green">OUI</span>
                              ) : (
                                <span className="o-red">NON</span>
                              )}
                            </div>
                            <div>
                              <i>Paiment NFC</i> :{' '}
                              {selectedCard?.cardOptions?.optionNfc ? (
                                <span className="o-green">OUI</span>
                              ) : (
                                <span className="o-red">NON</span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-3">
                        <b>Actions</b>
                        <hr />
                        <CreditCardActions
                          cardDetailData={selectedCard}
                          setBlockCardModal={setBlockCardModal}
                          setLostCardModal={setLostCardModal}
                          setActivateCardModal={setActivateCardModal}
                          setForceCodeModal={setForceCodeModal}
                          setUnlockPinCardModal={setUnlockPinCardModal}
                          setUpdateLimitsCardModal={setUpdateLimitsCardModal}
                          setUpdateOptionsCardModal={setUpdateOptionsCardModal}
                          refreshCardDetailInfos={refreshCardDetailInfos}
                          refreshCardLoading={refreshCardLoading}
                        />
                      </div>
                      {selectedCard?.shipping_tracking_url && (
                        <div className="mt-3">
                          <b>Code suivi</b>
                          <hr />
                          <div>{selectedCard?.shipping_tracking_url}</div>
                          <div className="mt-2">
                            <div>
                              <b>Date d'envoi</b>
                            </div>
                            <div>
                              {selectedCard?.shipping_date
                                ? moment(selectedCard?.shipping_date).format('DD/MM/YYYY HH:mm')
                                : '-'}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!selectedID && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
            </div>
          </div>   
        </div>     
      </div>
      <ConfirmationBlockCard
        userID={id}
        card={selectedCard}
        blockModal={blockCardModal}
        status={selectedCard?.status}
        setBlockModal={setBlockCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationForcePin
        userID={id}
        card={selectedCard}
        forceCodeModal={forceCodeModal}
        setForceCodeModal={setForceCodeModal}
      />
      <ConfirmationLostStolenCard
        userID={id}
        card={selectedCard}
        lostStolenCardModal={lostCardModal}
        setLostStolenCardModal={setLostCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationActivateCard
        userID={id}
        card={selectedCard}
        activateCardModal={activateCardModal}
        setActivateCardModal={setActivateCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationUnlockPinCard
        userID={id}
        card={selectedCard}
        unlockPinCardModal={unlockPinCardModal}
        setUnlockPinCardModal={setUnlockPinCardModal}
      />
      <ConfirmationUpdateCardLimits
        userID={id}
        card={selectedCard}
        updateLimitsModal={updateLimitsCardModal}
        setUpdateLimitsModal={setUpdateLimitsCardModal}
        refreshData={refreshCardList}
      />
      <ConfirmationUpdateCardOptions
        userID={id}
        card={selectedCard}
        updateOptionsModal={updateOptionsCardModal}
        setUpdateOptionsModal={setUpdateOptionsCardModal}
        refreshData={refreshCardList}
      />
    </div>
  );
}

export default ClientDetailV2Cards;
