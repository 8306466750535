import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../../../components/dropdown';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { orderPhysicalCardReq } from '../../../../../api/client/card/card';
import { clientWalletListReq } from '../../../../../api/client/wallet';
import { clientMembersReq } from '../../../../../api/client/detailClient';
import { ReactComponent as IconClose } from '../../../../../assets/icons/close_modal.svg';

const ConfirmationOrderCard = ({ userId, orderCardModal, setOrderCardModal, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];
  const [userListDrp, setUserListDrp] = useState();
  let userOptions = [];

  const orderCardHandler = async (data) => {
    setLoading(true);
    let walletID = null;
    let requestBody = {
      user_id: data.user_id?.value,
      wallet_id: data.wallet_id?.value,
      pin: data.pin,
      confirm_pin: data.confirmPin,
    };
    let status = await orderPhysicalCardReq(
      data.user_id?.value,
      data.wallet_id?.value,
      requestBody,
    );
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setOrderCardModal(false);
  };

  useEffect(() => {
    if (orderCardModal) {
      // On doit charger la liste des wallets pouvant avoir une carte
      clientWalletListReq(userId).then((res) => {
        res.map((item) =>
          walletOptions.push({ label: item.walletId + ' - ' + item.name, value: item.walletId }),
        );
        setWalletListDrp(walletOptions);
      });

      // on doit charger la liste des membres pouvant être associés à une carte
      let params = {
        only_physical: true,
      };
      clientMembersReq(userId, params).then((res) => {
        res.map((item) =>
          userOptions.push({
            label: item.userId + ' - ' + item.firstname + ' ' + item.lastname,
            value: item.userId,
          }),
        );
        setUserListDrp(userOptions);
      });
    }
  }, [orderCardModal]);

  const OrderCardInitialValues = {
    user_id: [],
    wallet_id: [],
    pin: '',
  };
  const OrderCardSchema = Yup.object().shape({
    user_id: Yup.object().required('Requis'),
    wallet_id: Yup.object().required('Requis'),
    pin: Yup.string().required('Requis'),
    confirmPin: Yup.string()
      .oneOf([Yup.ref('pin'), null], 'Les code PIN doivent être identiques')
      .required('Requis'),
  });

  return (
    <Modal isOpen={orderCardModal} toggle={setOrderCardModal}>
      <Formik
        enableReinitialize={true}
        initialValues={OrderCardInitialValues}
        validationSchema={OrderCardSchema}
        onSubmit={(values) => {
          orderCardHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={close}/>
            <div className="o-modal-info__title-text o-title">
              Pour commander une nouvelle carte il faut définir le code PIN
            </div>

            <div className="o-modal-info__fields">
              <Field
                name="user_id"
                label="Porteur de la carte"
                placeholder="Porteur de la carte *"
                options={userListDrp}
                component={Dropdown}
              />
              <Field
                name="wallet_id"
                label="Wallet associé"
                placeholder="Wallet associé *"
                options={walletListDrp}
                component={Dropdown}
              />
              <Field
                name="pin"
                label="Code PIN *"
                placeholder="Code PIN *"
                component={InputFormik}
              />
              <Field
                name="confirmPin"
                label="Confirmation du code PIN *"
                placeholder="Confirmation du code PIN *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationOrderCard;
