import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import PermissionsContext from '../../../../contexts/permissionContext.js';

import UserProfile from '../../../../components/userProfile/index.jsx';

import { ReactComponent as CompanyIcon } from "../../../../assets/icons/_SmallIcons/ic_company_info.svg";

import './client_header.scss';

function ClientDetailV2Header({
  detailData,
  rightFragment,
}) {
  const { t } = useTranslation();

  const { permContext } = useContext(PermissionsContext);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');
  const requestSuffix = 'status=' + status;

  const isCompany = detailData?.userTypeId === 2;
  const parent = permContext?.market === "pro" && detailData?.parent != null;
  const freelance = permContext?.market === "pro" && detailData?.userTypeId === 1 && detailData?.parent == null;
  const isBlocked = detailData?.blocked;
  const showStatus = detailData?.userStatus !== 'VALIDATED';

  useEffect(() => {
   
  }, [detailData]); 

  function showClientParent(parentId){
    window.location.href = `/dashboard/client/${parentId}`;
  }

  return (
  <div className='clientHeader'>
    <div className="clientHeader__content">
      <div className="clientHeader__content__info">
        <div className="clientHeader__content__info__left">
          <UserProfile  text="MR"
                    size="large"
                    bgColor="var(--userSurface1)"
                    textColor="var(--userOnSurface1)"
                    isCompany={isCompany}
                    parent={parent}
                    freelance ={freelance}
                    />
          <div className="clientHeader__content__info__left__text">
            <div className="clientHeader__content__info__left__title">
              {isCompany ? (
                <div>
                {detailData?.legal_infos?.legal_name}
                </div>
              ) : (
                <div>
                  {detailData?.title ? detailData?.title : '-'}&nbsp;{detailData?.firstname ? detailData?.firstname : '-'}&nbsp;{detailData?.lastname ? detailData?.lastname : '-'}
                </div>
              )}
              {/* Affichage du Status */}
              { showStatus && (
                <div className={'status ' + (detailData?.userStatus ? detailData?.userStatus.toLowerCase(): '')}>{detailData?.userStatus}</div>
              )}
              {/* Affichage du Blocage */}
              { isBlocked && (
                <div className="blocked">Bloqué</div>
              )}
              {/* Affichage de l'entreprise */}
              { parent && detailData?.parent && (
                <div className="company"  onClick={() => showClientParent(detailData?.parent.userId)}>
                  <div><CompanyIcon className="company__icon"/></div>                  
                  <div>&nbsp;&nbsp;{detailData?.parent?.legal_infos?.legal_name}</div>
                </div>
              )}
            </div>
            <div className="clientHeader__content__info__left__text">
            UserID #{detailData?.userId ? detailData?.userId : '-'}&nbsp;&nbsp;|&nbsp;&nbsp;Treezor UserID #{detailData?.trzUserId ? detailData?.trzUserId : '-'}
            </div>
          </div>
        </div>
        <div className="clientHeader__btns">
          {rightFragment}
        </div>
      </div>
    </div>
  </div>  
  );
}

export default ClientDetailV2Header;
