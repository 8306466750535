import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import {  detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { getScheduledTransferReq } from '../../../../../api/transfers/transfers.jsx';

// Renderer
import { tableScheduledTransfersHeader, renderScheduledTransfersTable } from './scheduledtransfers.utils.jsx';

// UI
import '../detailclient.scss';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/component/ic_delete.svg';
import IconLoading from '../../../../../components/loading/loading.jsx';
import Head from '../../../../../components/head/index.jsx';
import Logo from '../../../../../components/logo/index.jsx';
import Table from '../../../../../components/table/index.jsx';
import Button from '../../../../../components/button/index.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';


function ClientDetailV2ScheduledTransfer() {
  const { id } = useParams();
  const [detailData, setDetailData] = useState();
  
  const [loading, setLoading] = useState(false);
  const [statusScheduledTransfersTable, setStatusScheduledTransfersTable] = useState('loading');
  const [scheduledTransfersTableData, setScheduledTransfersTableData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [cardTableData, setCardTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allScheduledTransfers, setAllScheduledTransfers] = useState([]);
  const [selectedScheduledTransfers, setSelectedScheduledTransfers] = useState();
  const [deleteScheduledTransfersOpen, setDeleteScheduledTransfersOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails client
        </div>
      </Fragment>
    );
  }

  useEffect(() => {
    console.log(selectedID);
    const scheduledTransfers = allScheduledTransfers.find(
      (x) => x.scheduledtransferid === selectedID,
    );
    setSelectedScheduledTransfers(scheduledTransfers);
    setSelectedItem('scheduledTransfers');
    console.log(allScheduledTransfers);
  }, [selectedID]);

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    getScheduledTransferReq(id)
      .then((res) => {
        setLoading(false);
        setAllScheduledTransfers(res);
        setScheduledTransfersTableData(renderScheduledTransfersTable(res));
      })
      .finally(() => setStatusScheduledTransfersTable('done'));
  };

  const renderSelectedScheduledTransfersActions = (scheduledTransfers) => {
    return (
      <div className="o-information-detail__actions">
        {scheduledTransfers?.status === 'PENDING' && (
            <Button btnType="outline" onClick={() => setDeleteScheduledTransfersOpen(true)}>
              <IconDelete/>
              Supprimer
            </Button>
          )}
      </div>
    );
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Virement Programmés</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-10">
              <Table
                columns={tableScheduledTransfersHeader}
                tableData={scheduledTransfersTableData}
                status={statusScheduledTransfersTable}
                setShowModal={setShowModal}
                setSelectedID={setSelectedID}
                ContainHeader={() => ''}
                itemPerPage={10}
                contain
              />
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <BlocWithTitle title="">
              {selectedID && selectedItem === 'scheduledTransfers' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Virement Programmé</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>ScheduledTransfers ID</b>
                      </div>
                      <div>{selectedScheduledTransfers?.scheduledtransferid}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de début</b>
                      </div>
                      <div>{moment(selectedScheduledTransfers?.scheduled_start_date).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Fréquence</b>
                      </div>
                      <div>{selectedScheduledTransfers?.frequency_count} | {selectedScheduledTransfers?.frequency_unit}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedScheduledTransfers?.status}</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedScheduledTransfers?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedScheduledTransfers?.usableForSct ? 'SCT' : 'SDDR'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedScheduledTransfersActions(selectedScheduledTransfers)}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
              </BlocWithTitle>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2ScheduledTransfer;
