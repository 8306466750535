import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { addClientNote } from '../../../../api/client/updateClient';
import IconLoading from '../../../../components/loading/loading.jsx';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

const ConfirmationAddNote = ({ addNoteOpen, setAddNoteOpen, userData, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const updateHandler = async (id, data) => {
    setLoading(true);
    let status = await addClientNote(id, data);
    setLoading(false);
    setAddNoteOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const AddNoteInitialValues = (data) => {
    return {
      note: data ? data.note : '',
    };
  };
  const AddNoteSchema = Yup.object().shape({
    note: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={addNoteOpen} toggle={setAddNoteOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={AddNoteInitialValues(userData)}
        validationSchema={AddNoteSchema}
        onSubmit={(values) => {
          console.log('Ajout commentaire client');
          updateHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setAddNoteOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <IconClose className='icon-cancel' onClick={() => { setAddNoteOpen(false);}}/>
            <div className="o-modal-info__title-text o-title">
              Ajout d'un commentaire sur le client
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="note"
                components="textarea"
                label="Commentaire"
                placeholder="Commentaire *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setAddNoteOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationAddNote;
