import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import {ReactComponent as IconClose } from '../../../../assets/icons/close_modal.svg';

import { deletePackageFeatureReq } from '../../../../api/packageFeature/deletePackageFeature';

const DeletePackageFeature = ({ deleteModal, setDeleteModal, selectedID }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
      <IconClose className='icon-cancel' onClick={() => { setDeleteModal(false);}}/>
        <div className="o-modal-info__title-text">
          <div className="o-title">{t('feature:delete_feature')}</div>
          <div
            className="o-modal-info__title-text__text"
            dangerouslySetInnerHTML={{ __html: t('feature:delete_feature_description') }}
          />
        </div>
        <div className="o-btn-group">
          <Button
            type="submit"
            onClick={() => {
              deletePackageFeatureReq(selectedID).then((res) => {
                if (res === 'success') {
                  setTimeout(() => {
                    window.location.href = '/dashboard/admin/package-features';
                  }, 700);
                }
              });
            }}
          >
            {t('global:confirm')}
          </Button>
          <Button
            className="cancel" btnType="outline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePackageFeature;
