import React from 'react';

import moment from 'moment';

export const columns = [
  'ID',
  'Date',
  'Client',
  'Descriptif',
  'Statut',
  'Montant',
];

export const renderTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.alertfraudid,
      alertfraudid: item.alertfraudid,
      payindate : moment(item.payin.payinDate).format('DD/MM/YYYY HH:mm'),
      name: <a href={`/dashboard/client/${item.user?.userId}/security/monitoring`}>{item.user?.firstname} {item.user?.lastname} ({item.user?.userId})</a>,
      description: item.description,
      status: item.status ? item.status : '',
      amount: item.payin.amount + " €",
    }),
  );
  return data;
};
